import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
  Fn_BillingReceipt,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}

class pageAddEdit_UserCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: { amcId: 0, amount: 0 },
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
      DueAmount: [],
      VoucherId: null,
      modal_fullscreen: false,
      Usertype: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Member Balance Report";
    this.API_URL_SAVE = "BillPayment/Billing";

    //Event Binding

    this.handleFormChange = this.handleFormChange.bind(this);

    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
     Fn_FillListData(this.obj, "Usertype", "Member/GetUserType/0");
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleUsertypeChange(id) {
    
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ["UsertypeId"]: id,
      },
    }));
  }
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    vformData.append("F_usertype", this.state.formData.UsertypeId);
    vformData.append("Name", this.state.formData.Name);
    vformData.append("username", this.state.formData.Username);
    vformData.append("password", this.state.formData.Password);
    vformData.append("phone", this.state.formData.Phone);

    Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "Member/InsertMember",
      "/dashboard",
      true,
      "Id"
    );
   
  }

  render() {
    var parameters = [
      {
        name: "Id",
        values: [this.state.VoucherId],
      },
    ];
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="1" className="mb-1">
                                      <label
                                        htmlFor="Usertype"
                                        className="col-form-label"
                                      >
                                        Usertype
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <div>
                                        {this.state.Usertype.length > 0 &&
                                          this.state.Usertype.map(
                                            (type, index) => (
                                              <div key={type.Id}>
                                                <input
                                                  type="radio"
                                                  id={type.Name}
                                                  name="Usertype"
                                                  value={type.Name}
                                                  checked={
                                                    this.state.formData
                                                      .UsertypeId === type.Id
                                                  }
                                                  onChange={() =>
                                                    this.handleUsertypeChange(
                                                      type.Id
                                                    )
                                                  }
                                                />
                                                <label htmlFor={type.Name}>
                                                  {type.Name}
                                                </label>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Name
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="Name"
                                        label=""
                                        value={this.state.formData.Name}
                                        onChange={this.handleFormChange}
                                        placeholder="Name"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="Username"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Username
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="Username"
                                        label=""
                                        value={this.state.formData.Username}
                                        onChange={this.handleFormChange}
                                        placeholder="Username"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="Password"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Password
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="Password"
                                        label=""
                                        value={this.state.formData.Password}
                                        onChange={this.handleFormChange}
                                        placeholder="Password"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="Phone"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Phone
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="Phone"
                                        label=""
                                        value={this.state.formData.Phone}
                                        onChange={this.handleFormChange}
                                        placeholder="Phone"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageAddEdit_UserCreation);
