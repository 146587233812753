import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
} from "../../store/functions";

class pageAddEdit_PersonToPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      SenderName: [
        {
          Name: "",
        },
      ],
      ReceiverName: [
        {
          Name: "",
        },
      ],
      prov: false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
    };

    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Person To Person Transfer";
    this.breadCrumbTitle = "Person To Person Transfer";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.PersonToPersonRequest + "/0/token";
    this.pushFormName = "/persontoperson";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }

  componentDidMount() {
    
    localStorage.setItem("PageName", this.breadCrumbItem);
    //Filling DropDowns
    // Fn_FillListData(this.obj, "periodicity", API_WEB_URLS.MASTER + "/0/token/Periodicity/Id/0");

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ payeeEmail: obj.name, payeeName: obj.username });
    console.log(obj);
   

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    console.log(formData.Amount)
    vformData.append("payeeGIN", this.state.payeeEmail);
    vformData.append("payeeName", this.state.payeeName);
   
    vformData.append("DepositorGIN", this.state.DepositorInfo[0].DepositorGIN);
    vformData.append("DepositorName", this.state.DepositorInfo[0].Name);
    vformData.append("DepositorEmail", this.state.DepositorInfo[0].Email);
    vformData.append("DepositorPhone", this.state.DepositorInfo[0].Phone);
    vformData.append("Amount", formData.Amount);
    vformData.append("TotalAmount", formData.TotalAmount);


    vformData.append("F_SenderMemberMaster", this.state.id);
    vformData.append("F_ReceipentMemberMaster", this.state.DepositorInfo[0].Id);

    if (!this.state.id)
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true
      );
    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName 
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  handleAmountChange = e => {
    const amount = e.target.value;
    const totalAmount = parseFloat(amount) + parseFloat(amount) * 0.03;
    this.setState({
      formData: {
        ...this.state.formData,
        Amount: amount,
        TotalAmount: totalAmount.toFixed(2), 
      },
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

 

  GetDepositorInfo = event => {
    var value = event.target.value;

    Fn_FillListData(
      this.obj,
      "DepositorInfo",
      API_WEB_URLS.MASTER +
        "/0/token/GetBalance/1/Mem/Balance?ListFor=" +
        value +
        "&F_AccountType=11"
    );
    console.log("checking ", this.state.DepositorInfo[0]);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Person To Person Fund Transfer(Paystack)
                    </h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="6">
                              <Card>
                                <CardBody>
                                  <h4 className="card-title mb-4">
                                    Information
                                  </h4>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Depositor&apos;s GIN
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="DepositorGIN"
                                        onChange={this.GetDepositorInfo}
                                        value={
                                          this.state.DepositorInfo &&
                                          this.state.DepositorInfo[0] &&
                                          this.state.DepositorInfo[0]
                                            .DepositorGIN
                                        }
                                        placeholder="Depositor's GIN"
                                        disabled
                                        errorMessage="Depositor's GIN"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Depositor&apos;s Name
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="Depositor's Name"
                                        value={
                                          this.state.DepositorInfo &&
                                          this.state.DepositorInfo[0] &&
                                          this.state.DepositorInfo[0].Name
                                        }
                                        placeholder="Depositor's Name"
                                        errorMessage="Depositor's Name"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Depositor&apos;s Email
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="recipeinttransactionacno"
                                        onChange={this.GetDepositorInfo}
                                        value={
                                          this.state.DepositorInfo &&
                                          this.state.DepositorInfo[0] &&
                                          this.state.DepositorInfo[0].Email
                                        }
                                        placeholder="Enter Email"
                                        errorMessage="Enter Email"
                                        validate={{ required: { value: true } }}
                                        type="email"
                                        className="form-control"
                                        disabled
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Depositor&apos;s Phone No
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="recipientname"
                                        value={
                                          this.state.DepositorInfo &&
                                          this.state.DepositorInfo[0] &&
                                          this.state.DepositorInfo[0].Phone
                                        }
                                        placeholder="Depositor's Phone No"
                                        errorMessage="Depositor's Phone No"
                                        type="numbery"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Payee&apos;s GIN
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="transactionamount"
                                        value={
                                          this.state.payeeEmail
                                        }
                                        placeholder="Payee's GIN"
                                        errorMessage="Payee's GIN"
                                        validate={{ required: { value: true } }}
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Payee&apos;s Name
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="reference"
                                        value={this.state.payeeName}
                                        placeholder="Payee's Name"
                                        errorMessage="Payee's Name"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Amount (Cedis)
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="Amount"
                                        value={this.state.formData.Amount}
                                        placeholder="Amount"
                                        errorMessage="Amount"
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleAmountChange}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Amount + Charges
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="TotalAmount"
                                        value={this.state.formData.TotalAmount}
                                        placeholder="Amount + Charges"
                                        errorMessage="Amount + Charges"
                                        type="text"
                                        className="form-control"
                                        disabled
                                      />
                                    </Col>
                                  </Row>

                                  <div className="d-flex flex-wrap gap-2">
                                    <Button type="submit" color="primary">
                                      Pay
                                    </Button>{" "}
                                    <Button type="reset" color="secondary">
                                      Cancel
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_PersonToPerson);
