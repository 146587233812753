import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCD33qtWe9kKpNV3R7TSHrhN6c7kFIUc5U",
    authDomain: "shinewellinhouse.firebaseapp.com",
    projectId: "shinewellinhouse",
    storageBucket: "shinewellinhouse.appspot.com",
    messagingSenderId: "182837952505",
    appId: "1:182837952505:web:182f210c1fa413e69e0728",
    measurementId: "G-HJ2CKYL6PJ"
  };

 export const app = initializeApp(firebaseConfig)

 
 export const messaging = getMessaging(app);
 

