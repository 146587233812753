import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReportModal from "./ReportModal";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getCurrentDateWithLastMonth() {
  const currentDate = new Date();

  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;

  // Subtract one month
  if (month === 1) {
    month = 12;
    year -= 1;
  } else {
    month -= 1;
  }

  const monthStr = String(month).padStart(2, "0");
  const dayStr = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${monthStr}-${dayStr}`;
}

function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class EmployeeDayReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {
        fromDate: getCurrentDateWithLastMonth(),
        toDate: getCurrentDate(),
      },

      productData: [],
      companies: [],
      products: [],
      members: [],
      newModal: false,
      dataArray: [],
      subTaskArray: [],
      countPresent: 0,
      countHoliday: 0,
      countAbsent: 0,
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Monthly Report";

    //Event Binding
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handcallSubtaskleOpen = this.callSubtask.bind(this);
    this.calculateAttendance = this.calculateAttendance.bind(this);
  }

  componentDidMount() {
    this.updateFormDataFromProps();
   
  }

  componentDidUpdate(prevProps, prevState) {
    // Update state when props change
    if (prevProps.formData !== this.props.formData) {
      this.updateFormDataFromProps();
      
    }
    if (prevState.productData !== this.state.productData) {
   
    this.calculateAttendance(this.state.productData);
    }
    if (prevState.dataArray !== this.state.dataArray) {
 
      this.callSubtask();
    }
  }

  updateFormDataFromProps() {
    // Update formData state using props
    this.setState(
      {
        formData: this.props.formData,
      },
      () => {
        let vformData = new FormData();
        vformData.append("Fromdate", this.state.formData.fromDate);
        vformData.append("todate", this.state.formData.toDate);
        Fn_GetReport(
          this.obj,
          {
            arguList: {
              id: 0,
              formData: vformData,
            },
          },
          "Report/DayWiseReport/" + this.state.formData.f_usermaster,
          "productData",
          true
        );
      }
    );
  }

  calculateAttendance(data) {
    let countPresent = 0;
    let countHoliday = 0;
    let countAbsent = 0;

    data.forEach(item => {
      if (item.GivenTime !== null) {
        countPresent++;
      } else if (this.isSunday(item.date)) {
        countHoliday++;
      } else {
        countAbsent++;
      }
    });

    this.setState({
      countPresent,
      countHoliday,
      countAbsent,
    });
  }

  isSunday(dateString) {
    const date = new Date(dateString);
    return date.getDay() === 0;
  }

  toggleNewModal = () => {
    console.log("yhaaa");
    this.setState({ newModal: !this.state.newModal });
  };
  callSubtask() {
    console.log("call me");

    const idString = this.state.dataArray.map(item => item.Id).join(",");
    let vformData = new FormData();

    vformData.append("Ids", idString);
    Fn_GetReport(
      this.obj,
      {
        arguList: {
          id: 0,
          formData: vformData,
        },
      },
      "Task/GetSubTasks",
      "subTaskArray",
      true
    );

    this.toggleNewModal();
  }
  handleOpen(row) {
    if (row.GivenTime) {
      let vformData = new FormData();

      vformData.append("date", row.Date);
      Fn_GetReport(
        this.obj,
        {
          arguList: {
            id: 0,
            formData: vformData,
          },
        },
        "Report/TaskReport/" + row.F_UserMaster,
        "dataArray",
        true
      );
    }
  }

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const columns = [
      {
        dataField: "Name",
        text: "User name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TotalTasks",
        text: "TotalTasks ",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TotalSubTask",
        text: "TotalSubTask",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "GivenTime",
        text: "Time given(min)",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TakenTime",
        text: "Time taken(min)",
        sort: true,
        footer: columnData => <div></div>,
        style: (cell, row, rowIndex, colIndex) => {
          return cell > row.time ? { color: "red" } : { color: "green" };
        },
      },
      {
        dataField: "Date",
        text: "Date",
        sort: true,
        footer: columnData => <div></div>,
        style: (cell, row, rowIndex, colIndex) => {
          return cell > row.time ? { color: "red" } : { color: "green" };
        },
      },

      {
        dataField: "Report",
        text: "Report",
        sort: true,
        formatter: (cell, row) => {
          return (
            <Button color="primary" onClick={() => this.handleOpen(row)}>
              Report
            </Button>
          );
        },
        footer: columnData => <div></div>,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <>
        <React.Fragment>
          <div>
            <Container fluid>
              <Row style={{ margin: "0px", padding: "0px" }}>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="attendance-summary">
                        <h2>Attendance Summary</h2>
                        <span style={{ marginRight: "10px", color: "green" }}>
                          Present: {this.state.countPresent}
                        </span>
                        <span style={{ marginRight: "10px", color: "blue" }}>
                          Holiday: {this.state.countHoliday}
                        </span>
                        <span style={{ marginRight: "10px", color: "red" }}>
                          Absent: {this.state.countAbsent}
                        </span>
                      </div>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: sizePerPageList,
                        })}
                        keyField="id"
                        columns={columns}
                        data={this.state.productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="Id"
                            columns={columns}
                            data={this.state.productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"Id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {this.state.productData.length > 0 ? (
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : null}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal
                size="xl"
                isOpen={this.state.newModal}
                toggle={this.toggleNewModal}
                className="modal-fullscreen"
              >
                <div className="modal-body">
                  <ReportModal
                    tasks={this.state.dataArray}
                    subTasks={this.state.subTaskArray}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={this.toggleNewModal}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(EmployeeDayReport);
