import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Fn_FillListData } from "store/functions";
import { API_WEB_URLS } from "constants/constAPI";
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      
      CourseType : [
        {id : Math.random(),
        Name : 'Computer Science Engineering' 
        },
        {id : Math.random(),
        Name : 'Artificial Inteligence' 
        },
        {id : Math.random(),
        Name : 'Machine Learning' 
        },
        {id : Math.random(),
        Name : 'Mechanical Engineering' 
        }
        
      ],
      Semester : [
        { id : 1, Name : 'Semester 1' },
        { id : 2 ,Name : 'Semester 2'},
        { id : 3 ,Name : 'Semester 3'},
        { id : 4 ,Name : 'Semester 4'},
        { id : 5 ,Name : 'Semester 5'},
      ],
      formData : {}

    };
    this.obj = this;
   
  }

  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(
      this.obj,
      "dropdata",
      API_WEB_URLS.MASTER + "/0/token/AccountType/Id/0"
    );
  }

  // handleInputChange = event => {

  // }
  handleClick = (event, formData) => {
    console.log(formData.name);
    
  };
  DOBChange = (e)=>{
    var birth = new Date(e.target.value);
    var birthYear = birth.getFullYear();
    var tod = new Date();
    var todYear = tod.getFullYear();
    var age = todYear - birthYear;
    this.setState({age})
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <AvForm className="needs-validation" onValidSubmit={this.handleClick}>
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Student Information</h4>

          {/* name field  */}
                    <Row>
                      <Col sm="4">
                        <label
                          htmlFor="membershipType"
                          className="col-form-label"
                        >
                          Name 
                        </label>
                      </Col>

                      <Col lg="6">
                        <AvField
                          name="name"
                          value={this.state.name}
                          placeholder="name"
                          errorMessage="Enter Your Name "
                          validate={{ required: { value: true } }}
                          type="text"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                    {/* Roll number Field  */}
                     <Row>
                      <Col sm="4">
                        <label
                          htmlFor="membershipType"
                          className="col-form-label"
                        >
                          Roll Number 
                        </label>
                      </Col>

                      <Col lg="6">
                        <AvField
                          name="rollNO"
                          value={this.state.rollNo}
                          placeholder="Roll Number"
                          errorMessage="Enter Your Roll Number "
                          validate={{ required: { value: true } }}
                          type="text"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                    {/* Course Type DropDown  */}
                    <Row>
                      <Col sm="4">
                        <label
                          htmlFor="membershipType"
                          className="col-form-label"
                        >
                          Course Type
                        </label>
                      </Col>
                      <Col sm="6">
                        <AvField
                          name="CourseType"
                          label=""
                          value={this.state.CourseType}
                          errorMessage="Select  CourseType"
                          validate={{ required: { value: true } }}
                          type="select"
                          className="form-select"
                        >
                          <option value={-1} defaultValue label={"Select"} />
                          {this.state.CourseType
                            ? this.state.CourseType.map((option, key) => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                  label={option.Name}
                                />
                              ))
                            : null}
                        </AvField>
                      </Col>
                    </Row>
                    {/* Semester Selection DropDown  */}
                    <Row>
                      <Col sm="4">
                        <label
                          htmlFor="membershipType"
                          className="col-form-label"
                        >
                          Semester
                        </label>
                      </Col>
                      <Col sm="6">
                        <AvField
                          name="semester"
                          label=""
                          value={this.state.Semester}
                          errorMessage="Select  Semester"
                          validate={{ required: { value: true } }}
                          type="select"
                          className="form-select"
                        >
                          <option value={-1} defaultValue label={"Select"} />
                          {this.state.Semester
                            ? this.state.Semester.map((option, key) => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                  label={option.Name}
                                />
                              ))
                            : null}
                        </AvField>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="4" className="mb-3">
                        <label htmlFor="DateofBirth" className="col-form-label">
                          Date of Birth
                        </label>
                        <label style={{ color: "red" }}>*</label>
                      </Col>
                      <Col sm="4">
                        <AvField
                          name="DateOfBirth"
                          onChange={this.DOBChange}
                          label=""
                          value={
                            this.state.formData.DateOfBirth === null
                              ? ""
                              : this.state.formData.DateOfBirth
                          }
                          placeholder="Enter Date of Birth"
                          errorMessage="Enter Date of Birth"
                          validate={{ required: { value: true } }}
                          type="date"
                          className="form-control"
                        />
                      </Col>

                      <Col sm="1" className="mb-3">
                        <label htmlFor="age" className="col-form-label">
                          Age
                        </label>
                      </Col>
                      <Col sm="2">
                        <AvField
                          name="Age"
                          label=""
                          value={
                            this.state.age === undefined
                              ? this.state.formData.Age
                              : this.state.age
                          }
                          placeholder=""
                          errorMessage="Enter Age"
                          type="text"
                          disabled
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4"></h4>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Submit Fee
                      </Button>{" "}
                      <Button type="reset" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(Test);
