import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import EmployeeDayReport from "./EmployeeDayReport";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getCurrentDateWithLastMonth() {
  const currentDate = new Date();

  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;

  // Subtract one month
  if (month === 1) {
    month = 12;
    year -= 1;
  } else {
    month -= 1;
  }

  const monthStr = String(month).padStart(2, "0");
  const dayStr = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${monthStr}-${dayStr}`;
}

function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}

class EmployeeMonthlyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {
        fromDate: getCurrentDateWithLastMonth(),
        toDate: getCurrentDate(),
      },

      productData: [],
      companies: [],
      products: [],
      members: [],
      FullscreenModal: false,
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Monthly Report";

    //Event Binding

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handlePeriodicityChange = this.handlePeriodicityChange.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
     this.toggleFullscreenModal = this.toggleFullscreenModal.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    console.log(obj.Usertype);

    this.setState({
      uid: obj.uid,
      UserType: obj.Usertype,
    });

   obj.Usertype === "admin" &&
     Fn_FillListData(this.obj, "members", "Member/GetMembers/0");
    console.log(obj.Usertype == "admin" ? 0 : obj.uid);
    let vformData = new FormData();
    vformData.append("Fromdate", this.state.formData.fromDate);
    vformData.append("todate", this.state.formData.toDate);
    let id = obj.Usertype === "admin" ? 0 : obj.uid;

    console.log("id", id);
    Fn_GetReport(
      this.obj,
      {
        arguList: {
          id: id,
          formData: vformData,
        },
      },
      "Report/MonthlyReport/" + id,
      "productData",
      true
    );
  }
  toggleFullscreenModal = () => {
    this.setState({ FullscreenModal: !this.state.FullscreenModal });
  };
  handleReport(row) {

    if (row.F_UserMaster) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          ["f_usermaster"]: row.F_UserMaster,
        },
      }));
       this.toggleFullscreenModal();
    }
   
  }
  //handle change function
  handlePeriodicityChange(e) {
    let obj = this.state.formData;
    if (e != null) {
      obj["periodicity"] = e.target.value;
    }
    this.setState({
      formData: obj,
    });
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleMemberChange(event) {
    const memberId = event.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        f_usermaster: memberId !== "" ? parseInt(memberId) : null,
      },
    }));
  }
  btnSave_onClick(event, formData) {
    console.log(this.state.productData);
    let vformData = new FormData();
    vformData.append("Fromdate", this.state.formData.fromDate);
    vformData.append("todate", this.state.formData.toDate);
    if (this.state.UserType === "admin") {
      Fn_GetReport(
        this.obj,
        {
          arguList: {
            id: 0,
            formData: vformData,
          },
        },
        "Report/MonthlyReport/" + this.state.formData.f_usermaster,
        "productData",
        true
      );
    } else {
      Fn_GetReport(
        this.obj,
        {
          arguList: {
            id: 0,
            formData: vformData,
          },
        },
        "Report/MonthlyReport/" + this.state.uid,
        "productData",
        true
      );
    }
  }

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const columns = [
      {
        dataField: "Name",
        text: "User name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TotalTasks",
        text: "TotalTasks ",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TotalSubTask",
        text: "TotalSubTask",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "GivenTime",
        text: "Time given(min)",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "TakenTime",
        text: "Time taken(min)",
        sort: true,
        footer: columnData => <div></div>,
        style: (cell, row, rowIndex, colIndex) => {
          return cell > row.time ? { color: "red" } : { color: "green" };
        },
      },

      {
        dataField: "Report",
        text: "Report",
        sort: true,
        formatter: (cell, row) => {
          return (
            <Button color="primary" onClick={() => this.handleReport(row)}>
              Report
            </Button>
          );
        },
        footer: columnData => <div></div>,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  {this.state.UserType === "admin" && (
                                    <Row>
                                      <Col sm="1" className="mb-3">
                                        <label
                                          htmlFor="branch"
                                          className="col-form-label"
                                        >
                                          Member
                                        </label>
                                      </Col>
                                      <Col sm="4">
                                        <select
                                          style={{ marginBottom: "8px" }}
                                          onChange={this.handleMemberChange}
                                          className="form-control"
                                        >
                                          <option value="-1">
                                            select Member
                                          </option>
                                          {this.state.members.map(item => (
                                            <option
                                              key={item.Id}
                                              value={item.Id}
                                              selected={
                                                item.Id ===
                                                this.state.formData.f_usermaster
                                              }
                                            >
                                              {item.Name}
                                            </option>
                                          ))}
                                        </select>
                                      </Col>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col lg="1"></Col>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="fromDate"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        From Date
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="fromDate"
                                        label=""
                                        value={this.state.formData.fromDate}
                                        onChange={this.handleFormChange}
                                        placeholder="From Date"
                                        type="date"
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="toDate"
                                        className="col-form-label"
                                      >
                                        To Date
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="toDate"
                                        label=""
                                        value={this.state.formData.toDate}
                                        placeholder="To Date"
                                        onChange={this.handleFormChange}
                                        type="date"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: sizePerPageList,
                        })}
                        keyField="Id"
                        columns={columns}
                        data={this.state.productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="Id"
                            columns={columns}
                            data={this.state.productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {this.state.productData.length > 0 ? (
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : null}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal
                size="xl"
                isOpen={this.state.FullscreenModal}
                toggle={this.toggleFullscreenModal}
                className="modal-fullscreen"
              >
                
                <div className="modal-body">
                  <EmployeeDayReport formData={this.state.formData} />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={this.toggleFullscreenModal}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(EmployeeMonthlyReport);
