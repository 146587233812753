import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"

import "./store/global"
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
 import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import { Provider } from "react-redux"

import store from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
//   .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);

//   }).catch((error) => {
//       console.error('Service Worker registration failed:', error);
//   });
// }

// serviceWorker.unregister()

// navigator.serviceWorker.addEventListener('message', event => {
//   if (event.data && event.data.type === 'CLEAR_LOCAL_STORAGE') {
//     console.log('Received message to clear localStorage');
//     localStorage.clear();
//   }
// });
