import { AvForm, input } from "availity-reactstrap-validation";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { compose } from "recompose";
import { container } from "store/Containers/cntCommon";

function RenderBranch({
  dataArray,
  handleChange,
  handleAddRow,
  handleDeleteRow,
  handleClick,
  availableForms,
  forms,
}) {
  const Name = useRef([]);
  const Amount = useRef([]);
  const AMC = useRef([]);
 
  const add = useRef([]);

  const calculateTotal = column => {
    return dataArray.reduce(
      (total, row) => total + parseFloat(row[column] || 0),
      0
    );
  };
  const totalAmount = calculateTotal("dues");

  const totalAMC = calculateTotal("fine");
  useEffect(()=>{
    console.log(dataArray)
  },[dataArray])


  const handler = (name, event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();

      setTimeout(() => {
        switch (name) {
          case "Amount":
            if (Amount.current[index]) {
              Amount.current[index].focus();
            }
            break;
          case "Name":
            if (Name.current[index]) {
              Name.current[index].focus();
            }
            break;
          case "AMC":
            if (AMC.current[index]) {
              AMC.current[index].focus();
            }
            break;
       
          case "add":
            if (add.current[index]) {
              add.current[index].focus();
              handleAddRow();
            }
            break;

          default:
            break;
        }
      }, 0);
    }
  };

  return (
    <table style={{ marginTop: "20px" }}>
      <thead>
        <tr>
          <th>Branch name</th>
          <th>Branch amount</th>
          <th>AMC</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {dataArray.length>0 && dataArray.map((row, rowIndex) => (
          <tr key={row.id}>
            <td>
              <input
                style={{ width: "100px" }}
                type="text"
                name={"Name"}
                value={row.Name}
          
                onChange={e => handleChange(e, rowIndex)}
                ref={e => (Name.current[rowIndex] = e)}
                onKeyPress={evnt => handler("Amount", evnt, rowIndex)}
                className="form-control"
              />
            </td>
            <td>
              <input
                style={{ width: "100px" }}
                type="number"
                name={"Amount"}
                value={row.Amount}
         
                onChange={e => handleChange(e, rowIndex)}
                ref={e => (Amount.current[rowIndex] = e)}
                onKeyPress={evnt => handler("AMC", evnt, rowIndex)}
                className="form-control"
              />
            </td>
            <td>
              <input
                style={{ width: "100px" }}
                type="number"
                name={"AMC"}
                value={row.AMC}
             
                onChange={e => handleChange(e, rowIndex)}
                ref={e => (AMC.current[rowIndex] = e)}
                onKeyPress={evnt => handler("add", evnt, rowIndex)}
                className="form-control"
              />
            </td>

            <td style={{ display: "flex" }}>
              <Button
                className="btn btn-danger "
                onClick={() => handleDeleteRow(row.Id)}
              >
                {"-"}
              </Button>{" "}
              <button
                className="btn btn-success ml-2"
                onClick={(e) => handleAddRow(e)}
                ref={e => (add.current[rowIndex] = e)}
                onKeyPress={evnt => handler("Name", evnt, rowIndex + 1)}
              >
                {"+"}
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td style={{ fontWeight: "bold" }}>&nbsp; &nbsp; &nbsp; Totals</td>
          <td>&nbsp; &nbsp; &nbsp;{totalAmount}</td>
          <td>&nbsp; &nbsp; &nbsp;{totalAMC}</td>
          
      
       
        </tr>
      </tbody>
    </table>
  );
}

export default RenderBranch;
