import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";




// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";


export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');


return d2;


}

export const todaydate =  ()=>{
  let today =  new Date();
  let yyyy   =  today.getFullYear();
  let mm =  today.getMonth() + 1;
  let dd  =  today.getDate();
   
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    let formattedToday = dd + '/' + mm + '/' + yyyy;
  return formattedToday;
}


class pageAddEdit_EmployeeMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1 : '',
      BrowseImage2 : '',
      UserImage : '',
      Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      IsOvertime : false,
      EmpBasic : [],
      DepartmentData : [],
      MobileNo_SMS : '',
      StateId_Perm : '',
      CityId_Perm : '',
      Address_Permanent : '',
      pin_Perm : ''
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Employee";
    this.breadCrumbTitle = "Employee";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/EmployeeEdit";
    this.API_URL_SAVE = API_WEB_URLS.EmployeeMaster + "/0/token";
    this.pushFormName = "/masters-employeemaster";
    this.rtPage_Print = "/#";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.AddBasic =  this.AddBasic.bind(this);
    this.AddDepart = this.AddDepart.bind(this);
  }




  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });

    var F_CompanyMaster  =  localStorage.getItem("F_CompanyMaster");
    // Filling DropDowns
    Fn_FillListData(this.obj, "maritalStatus", API_WEB_URLS.MASTER + "/0/token/MaritalStatus/Id/0");
    Fn_FillListData(this.obj, "gender", API_WEB_URLS.MASTER + "/0/token/Gender/Id/0");
    Fn_FillListData(this.obj, "nationality", API_WEB_URLS.MASTER + "/0/token/Country/Id/0");
    Fn_FillListData(this.obj, "department", API_WEB_URLS.MASTER + "/0/token/DepartmentMaster/Id/"+F_CompanyMaster);
    Fn_FillListData(this.obj, "designation", API_WEB_URLS.MASTER + "/0/token/Designation/Id/0");
    
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
    
  }







DOBChange = (event)=> {

   var birth  =  new Date(event.target.value);
   birth = birth.getFullYear();

    var tod =  new Date();

    tod =  tod.getFullYear();

    var age = tod - birth;

    this.setState({ fo: age });

}











  btnSave_onClick(event, formData) {

   
   
    


 

    var birth  =  new Date(formData.DateOfBirth);
    birth = birth.getFullYear();
 
     var tod =  new Date();
 
     tod =  tod.getFullYear();
 
     var age = tod - birth;




   
 
    if (!this.state.id)    {




      

      Fn_AddEditData(
        this.obj,
        { arguList: { 
          
          id: 0, 
          dateOfJoining: DateString(formData.DateOfJoining) , 
          name : formData.Name ,
          fatherName : formData.FatherName , 
          religion : formData.Religion  ,
          nationality : formData.Nationality,
          dateOfBirth : DateString(formData.DateOfBirth),
          f_MaritalStatus : formData.MaritalStatusId ,
          f_GenderMaster : formData.GenderId ,
          phone : formData.MobileNo_SMS ,
          curStatus : formData.StatusId ,
          aadharNo : formData.AadharNo ,
          aadharName : formData.AadharName ,
          f_ShiftMaster : formData.ShiftId , 
          age : age,
          f_StateMaster_Pres : formData.StateId_Pres,
          f_CityMaster_Pres : formData.CityId_Pres,
          presentAddress : formData.Address_Residential,
          pin_Pres : formData.pin_Pres,
          mobileNo : formData.MobileNo1_Residential
        } },
        this.API_URL_SAVE,
        this.pushFormName
      );


    
    
     
      this.setState({
        success_msg : true
      });

    }
    else
    Fn_AddEditData(
      this.obj,
      { arguList: { 
        
        id: this.state.id, 
        dateOfJoining: DateString(formData.DateOfJoining) , 
        name : formData.Name ,
        fatherName : formData.FatherName , 
        religion : formData.Religion  ,
        nationality : formData.Nationality,
        dateOfBirth : DateString(formData.DateOfBirth),
        f_MaritalStatus : formData.MaritalStatusId ,
        f_GenderMaster : formData.GenderId ,
        phone : formData.MobileNo_SMS,
        curStatus : true ,
        aadharNo : formData.AadharNo ,
        aadharName : formData.AadharName ,
        f_ShiftMaster : 0 , 
        age:age ,
        f_StateMaster_Pres : formData.StateId_Pres,
        f_CityMaster_Pres : formData.CityId_Pres,
        presentAddress : formData.Address_Residential,
        pin_Pres : formData.pin_Pres,
        mobileNo : formData.MobileNo1_Residential,
        isSame : formData.isSame,
        f_StateMaster_Perm : formData.StateId_Perm,
        f_CityMaster_Perm : formData.CityId_Perm,
        pin_Perm : formData.pin_Perm,
        permanentAddress : formData.Address_Permanent
      } },
      this.API_URL_SAVE,
      this.pushFormName
    );
   

  }


 



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps
        });
      }
    }
  }



  onChange =()=> {

    if (this.state.IsOvertime == true){
      this.setState({ IsOvertime: false });
    }

    else {
      this.setState({ IsOvertime: true });
    }

    

  }


  setvalue =(name , event) => {

   if(name == 'Phone')
   this.setState({MobileNo_SMS : event.target.value});
   else if (name ==  'StateId_Pres')
   this.setState({StateId_Pres : event.target.value});
   else if (name ==  'CityId_Pres')
   this.setState({CityId_Pres : event.target.value});
   else if (name ==  'Address_Residential')
   this.setState({Address_Residential : event.target.value});
   else if (name ==  'pin_Pres')
   this.setState({pin_Pres : event.target.value});
   else if (name ==  'Department') {
    const selectElement = event.target; // Get the select element
    const selectedOption = selectElement.options[selectElement.selectedIndex]; // Get the selected option
    const selectedOptionLabel = selectedOption.label; // Get the label of the selected option
   this.setState({DepartmentId : event.target.value});
   this.setState({DepartmentName : selectedOptionLabel});
   }
   else if (name ==  'Designation') {
    const selectElement = event.target; // Get the select element
    const selectedOption = selectElement.options[selectElement.selectedIndex]; // Get the selected option
    const selectedOptionLabel = selectedOption.label; // Get the label of the selected option
    
    this.setState({DesignationName : selectedOptionLabel});
    this.setState({DesignationId : event.target.value});

   }

  } 

  AddDepart () {

    this.setState({ DepartmentData: [...this.state.DepartmentData , {
      

      id : this.state.DepartmentData.length+1 , Department : this.state.DepartmentName   ,Designation : this.state.DesignationName , EffectDate : todaydate()  , Action : <Link to="#" onClick={() =>
        this.setState({ EmpBasic: [] })
      } className="text-danger"  >
      <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
      <UncontrolledTooltip placement="top" target="deletetooltip">
          Delete
      </UncontrolledTooltip >
  </Link>
    }] });


  }



  AddBasic () {


    var Type = this.state.switch9;

    if (Type  ==  true){
      Type  = "Daily"
    }

    else {
      Type  = "Monthly";
    }

    var Basic  = this.state.BasicSalary;

    this.setState({ EmpBasic: [...this.state.EmpBasic , {
      

      id : 1 , BasicSalary : Basic   , EffectDate : todaydate()  , Change : todaydate() , BasicType : Type , Action : <Link to="#" onClick={() =>
        this.setState({ EmpBasic: [] })
      } className="text-danger"  >
      <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
      <UncontrolledTooltip placement="top" target="deletetooltip">
          Delete
      </UncontrolledTooltip >
  </Link>
    }] });

  }

  isSame =(event) => {

    
    
    if (event.target.value  == 'false') {

      
        if (this.state.formData.StateId_Pres!=null) {
          

          this.setState({
            StateId_Perm : this.state.formData.StateId_Pres,
            CityId_Perm : this.state.formData.CityId_Pres,
            Address_Permanent : this.state.formData.Address_Residential,
            pin_Perm : this.state.formData.pin_Pres
        })
        }

        else {

        this.setState({
            StateId_Perm : StateId_Pres,
            CityId_Perm : CityId_Pres,
            Address_Permanent : Address_Residential,
            pin_Perm : pin_Pres
        })

      }
    }

    else {
      this.setState({
        StateId_Perm : '',
        CityId_Perm : '',
        Address_Permanent : '',
        pin_Perm : ''
    })
    }
  }
  

  render() {

    const JoinProduct = [
      { id: 1, JoinDate: "01/09/2022", JoinType: "Join", LeftDate: "__/__/____" },
    ]
    
    const JoinColumns = [
     
      {
        dataField: "JoinDate",
        text: "JoinDate",
      },
      {
        dataField: "JoinType",
        text: "JoinType",
      },
      {
        dataField: "LeftDate",
        text: "Left Date",
      },

      {
        dataField: "ReasonOfLeft",
        text: "Reason Of Left",
      },
    ]



    const BasicColumns = [
     
      {
        dataField: "BasicSalary",
        text: "Basic Salary",
      },
      {
        dataField: "EffectDate",
        text: "Effect. Date",
      },
      {
        dataField: "Change",
        text: "Change",
      },
      {
        dataField: "BasicType",
        text: "Basic Type",
      },

      {
       dataField: "Action",
        text: "Action",
      },
    ]

    const DepartmentColumns = [
     
      {
        dataField: "Department",
        text: "Department",
      },
      {
        dataField: "Designation",
        text: "Designation",
      },
      {
        dataField: "EffectDate",
        text: "Effect Date",
      },
      {
        dataField: "Action",
         text: "Action",
       }
    
    ]


    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          No
        </div>
      )
    }


    const Offsymbolb = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          ML
        </div>
      )
    }


    const OnSymbolb = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          DL
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Yes
        </div>
      )
    }




   

    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="number">1.</span> Personal
                              Information
                            </NavLink>
                          </NavItem>
                          <NavItem

className={classnames({
  current: this.state.activeTab === 2,
})}
>
<NavLink
  className={classnames({
    active: this.state.activeTab === 2,
  })}
  onClick={() => {
    this.toggleTab(2);  
  }}
>
  <span className="number">2.</span> 
  Other Details
</NavLink>


                        
                          </NavItem>



                          <NavItem
className={classnames({
  current: this.state.activeTab === 3,
})}
>


<NavLink
  className={classnames({
    active: this.state.activeTab === 3,
  })}
  onClick={() => {
    this.toggleTab(3);
  }}
>
  <span className="number">3.</span> 
  Identity Documentation
</NavLink>
                            
                          </NavItem>
                          <NavItem

                          
className={classnames({
  current: this.state.activeTab === 4,
})}
>

                          
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                                      <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Machine Enrollment No.</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="EmployeeCode" label="" value={this.state.formData.EmployeeCode === null ? ''   : this.state.formData.EmployeeCode} disabled   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="dateOfJoining" className="col-form-label">Date of Joining</label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining"  type="date"  className="form-control" />
                                        </Col>  
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Name" className="col-form-label">Name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Name" label="" value={this.state.formData.Name === null ? ''   : this.state.formData.Name} placeholder="Enter  Name"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="FatherName" className="col-form-label">Father Name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="FatherName" label="" value={this.state.formData.FatherName === null ? ''   : this.state.formData.FatherName} placeholder="Enter FatherName Name"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Religion" className="col-form-label">Religion</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Religion" label="" value={this.state.formData.Religion === null ? ''   : this.state.formData.Religion} placeholder="Enter Religion"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Nationality</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Nationality" label="" value={this.state.formData.Nationality === null ? ''   : this.state.formData.Nationality} placeholder="Enter Nationality"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Date of Birth</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="DateOfBirth" onChange={this.DOBChange} label="" value={this.state.formData.DateOfBirth === null ? ''   : this.state.formData.DateOfBirth} placeholder="Enter Date of Birth"  type="date"  className="form-control" />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Age</label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField name="Age" label="" value={this.state.fo === undefined ? this.state.formData.Age   : this.state.fo} placeholder="" errorMessage="Enter Age"  type="text" disabled className="form-control"  />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maritalStatus" className="col-form-label">Marital Status</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaritalStatusId" onChange={this.checkMarital} label="" value={this.state.formData.MaritalStatusId === null ? '-1'   : this.state.formData.MaritalStatusId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.maritalStatus
                                              ? this.state.maritalStatus.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col> 
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="gender" className="col-form-label">Gender</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField onChange={this.checkfemale} name="GenderId" label="" value={this.state.formData.GenderId === null ? '-1'   : this.state.formData.GenderId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.gender
                                              ? this.state.gender.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField> 
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Phone" className="col-form-label">Phone</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo_SMS" onChange={(event) => this.setvalue('Phone' , event)} label="" value={this.state.formData.MobileNo_SMS === null ? ''   : this.state.formData.MobileNo_SMS} placeholder="Enter Phone"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                  </Col>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Address</h4>
                                      <Row>
                                        <Col lg="12">
                                            <Label for="basicpill-address-input1">                       
                                              Residential Address
                                            </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Country</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="CountryId" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId}  type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"INDIA"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">State</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="StateId_Pres" onChange={(event) => this.setvalue('StateId_Pres' , event)} label="" value={this.state.formData.StateId_Pres === null ? '-1'   : this.state.formData.StateId_Pres}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                        
                                      </Row>
                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">City</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="CityId_Pres" label="" onChange={(event) => this.setvalue('CityId_Pres' , event)} value={this.state.formData.CityId_Pres === null ? '-1'   : this.state.formData.CityId_Pres}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="basicpill-address-input1" className="col-form-label">Full Address</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Address_Residential" onChange={(event) => this.setvalue('Address_Residential' , event)} label="" value={this.state.formData.Address_Residential === null ? ''   : this.state.formData.Address_Residential} placeholder="Enter Full Address"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="postCode" className="col-form-label">Post Code</label>
                                        </Col>
                                        <Col sm="3">
                                          <AvField name="pin_Pres" onChange={(event) => this.setvalue('pin_Pres' , event)} label="" value={this.state.formData.pin_Pres === null ? ''   : this.state.formData.pin_Pres} placeholder="Enter Post Code"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="mobilePhone1" className="col-form-label">Mobile Phone</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo1_Residential" label="" value={this.state.formData.MobileNo1_Residential == null ? this.state.MobileNo_SMS   : this.state.formData.MobileNo1_Residential} placeholder="Enter Mobile Phone 1"  type="text" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="mobilePhone1" className="col-form-label">Same as Present</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="isSame" label="" onChange={this.isSame}   type="checkbox" className="form-control" checked={this.state.formData.isSame == null ? false : this.state.formData.isSame} />
                                        </Col>
                                      </Row>
                                  
                                    
                                  
                                      <Row>
                                        <Col lg="6">
                                            <Label for="basicpill-lastname-input2">
                                              Permanent Address
                                            </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Country</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="CountryId" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId}  type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"INDIA"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">State</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="StateId_Perm" label="" value={this.state.formData.StateId_Perm === null ?  this.state.StateId_Perm : this.state.formData.StateId_Perm}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">City</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="CityId_Perm" label="" value={this.state.formData.CityId_Perm === null ?  this.state.CityId_Perm : this.state.formData.CityId_Perm}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="basicpill-address-input1" className="col-form-label">Full Address</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Address_Permanent" label="" value={this.state.formData.Address_Permanent === null ? this.state.Address_Permanent : this.state.formData.Address_Permanent} placeholder="Enter Full Address"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="postCode" className="col-form-label">Post Code</label>
                                        </Col>
                                        <Col sm="3">
                                          <AvField name="pin_Perm" label="" value={this.state.formData.pin_Perm === null ?  this.state.pin_Perm : this.state.formData.pin_Perm} placeholder="Enter Post Code"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                  </Col>
                                </Row>
                                <Row>
                                </Row>





                                <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Id Proof</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="Member_Introducer" className="col-form-label">Aadhar No.</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="AadharNo" label="" value={this.state.formData.AadharNo === null ? ''   : this.state.formData.AadharNo} placeholder="Enter AadharNo"  type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Name on Aadhar</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="AadharName" label="" value={this.state.formData.AadharName === null ? ''   : this.state.formData.AadharName} placeholder="Enter Aadhar Name"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                      
                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Shift</h4>
                                        <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">working Status</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="StatusId" label="" value={this.state.formData.StatusId === null ? '-1'   : this.state.formData.StatusId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                                    <option key="1" value="1" label="Working" />
                                                    <option key="2" value="2" label="Left" />
                                          </AvField>
                                        </Col>
                                      </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Select Shift</label>
                                          </Col>
                                          <Col sm="6">
                                          <AvField name="ShiftId" label="" value={this.state.formData.ShiftId === null ? '-1'   : this.state.formData.ShiftId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.ShiftId 
                                              ? this.state.ShiftId .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                  <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <h4 className="card-title mb-4">Joinig Detail</h4>
                                          
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={JoinProduct}
                        columns={JoinColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>


                    <h4 className="card-title mb-4">Departments</h4>

                    <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Department</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="DepartmentId" label="" onChange={(event) => this.setvalue('Department' , event)}   type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.department 
                                              ? this.state.department.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                                 </Col>
                      </Row>   


                        <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Designation</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="DesignationId" label="" onChange={(event) => this.setvalue('Designation' , event)}   type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.designation 
                                              ? this.state.designation.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                                 </Col>
                                                 <div className="button-items">

                                        <Button
                        color="success"
                        className="btn btn-success"
                        type="button"
                        onClick={this.AddDepart}
                      >
                        Add
                      </Button>
                                        
                      </div>
                      </Row>                     
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={this.state.DepartmentData}
                        columns={DepartmentColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>
                                        </CardBody>
                                      </Card>
                                    </Col>




                                    <Col lg="6">
                                      <Card>
                                        <CardBody>


                                          <h4 className="card-title mb-4">Emp Basic</h4>
                                          

                                          <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Basic Salary</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="BasicSalary" onChange={event => {
                          this.setState({
                            BasicSalary: event.target.value,
                          });
                        }} label="" value={this.state.BasicSalary === null ? ''   : this.state.BasicSalary} placeholder="Enter BasicSalary"  type="text" className="form-control" />
                                        </Col>


                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Basic Type</label>
                                        </Col>
                                        <Col sm="6">
                                        
                                        <Switch
                            uncheckedIcon={<Offsymbolb />}
                            checkedIcon={<OnSymbolb />}
                            className="me-3 mb-lg-8 mb-2"
                            onColor="#ec4561"
                            onChange={() =>
                              this.setState({ switch9: !this.state.switch9 })
                            }
                            checked={this.state.switch9}
                          />
                                        </Col>
                                        <div className="button-items">

                                        <Button
                        color="success"
                        className="btn btn-success"
                        type="button"
                        onClick={this.AddBasic}
                      >
                        Add
                      </Button>
                                        
                      </div>
                                      </Row>
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={this.state.EmpBasic}
                        columns={BasicColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>
                                         
                                         
                                          
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                                  </Row>


                                  <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Other Details</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="Member_Introducer" className="col-form-label">In Time (24 hrs Format)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="ActualInTime" label="" value={this.state.formData.ActualInTime === null ? ''   : this.state.formData.ActualInTime} placeholder="In Time (24 hrs Format)"  type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="introducerNo" className="col-form-label">Out Time (24 hrs Format)</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="ActualOutTime" label="" value={this.state.formData.ActualOutTime === null ? ''   : this.state.formData.ActualOutTime} placeholder="Out Time (24 hrs Format)"   type="text" className="form-control" />
                                            </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max. Working Hours (Full Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MaxWorkingHours4FF" label="" value={this.state.formData.WorkingHours4FF === null ? ''   : this.state.formData.WorkingHours4FF} placeholder="Max. Working Hours (Full Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Min. Working Hours (Full Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MinWorkingHours4FF" label="" value={this.state.formData.MinWorkingHours4FF === null ? ''   : this.state.formData.MinWorkingHours4FF} placeholder="Min. Working Hours (Full Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max. Working Hours (Half Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MaxWorkingHours4HF" label="" value={this.state.formData.MaxWorkingHours4HF === null ? ''   : this.state.formData.MaxWorkingHours4HF} placeholder="Max. Working Hours (Half Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>


                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Min. Working Hours (Half Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MinWorkingHours4HF" label="" value={this.state.formData.MinWorkingHours4HF === null ? ''   : this.state.formData.MinWorkingHours4HF} placeholder="Min. Working Hours (Half Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                       
                                        <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Overt Time Applicable </label>
                                        </Col>
                                        <Col sm="6">

                                        <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#ec4561"
                            onChange={() =>
                              this.setState({ switch8: !this.state.switch8 })
                            }
                            checked={this.state.switch8}
                          />
                                        {/* <AvField name="IsOvertime" onChange={this.onChange} value={this.state.formData.IsOvertime}   type="checkbox" className="form-control" />                                 */}
                                      
                                        </Col>
                                      </Row>

                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Grace Period Mins(Over Time) </label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="GracePeriod" label="" value={this.state.formData.GracePeriod === null ? ''   : this.state.formData.GracePeriod} placeholder="Grace Period Mins(Over Time)"   type="text" className="form-control" />
                                        </Col>
                                      </Row>

                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Weekly Holiday</label>
                                          </Col>
                                          <Col sm="6">
                                          <AvField name="ShiftId" label="" value={this.state.formData.ShiftId === null ? '-1'   : this.state.formData.ShiftId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.ShiftId 
                                              ? this.state.ShiftId .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max Allowed Leaves/Month</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="AllowedLeaves" label="" value={this.state.formData.AllowedLeaves === null ? ''   : this.state.formData.AllowedLeaves} placeholder="Max Allowed Leaves/Month"   type="text" className="form-control" />
                                          </Col>
                                        </Row>

                                       


                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                  <Row>
                                    <Col lg="6">
                                        <Label for="basicpill-lastname-input2">
                                          Identity Confirmation
                                        </Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_1" label="Type of Document" value={this.state.formData.IDDocumentTypeId_1 === null ? '-1'   : this.state.formData.IDDocumentTypeId_1}  type="select" className="form-select" >
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_1" label="Document No." value={this.state.formData.ID_DocNo_1 === undefined ? ''   : this.state.formData.ID_DocNo_1} placeholder="Enter Document No." type="text" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_1_Thumbnail} />
                                    <AvField name="BrowseImage1" label="DocumentId 1" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage1', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_2" label="Type of Document" value={this.state.formData.IDDocumentTypeId_2 === null ? '-1'   : this.state.formData.IDDocumentTypeId_2}    type="select" className="form-select" >
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_2" label="Document No." value={this.state.formData.ID_DocNo_2 === undefined ? ''   : this.state.formData.ID_DocNo_2} placeholder="Enter Document No."  type="text" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_2_Thumbnail} />

                                    <AvField name="BrowseImage2" label="DocumentId 2" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage2', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />

                                    
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Member_Thumbnail} />
                                    <AvField name="UserImage" label="Employee Photo" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'UserImage', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Signature_Thumbnail} />
                                    <AvField name="Signature" label="Employee Signature" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'Signature', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                  </Row>
                              </div>
                            </TabPane>
                          </TabContent>
                          <div>
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>

                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Member Saved Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul>

                      
                          <li
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                         
                          <li
                            className={
                              this.state.activeTab === 3
                                ? "next disabled"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              Next
                            </Link>
                          </li>

                          <li
                            className={
                              this.state.activeTab === 1
                                ? "save disabled"
                                : "save"
                            }
                          >
                        
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_EmployeeMaster);
