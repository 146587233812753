import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row } from "reactstrap";
import CardShop from "./CardShop";

import { API_WEB_URLS } from "../../constants/constAPI";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_FillListData } from "../../store/functions";

class selectJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Company: [
        
    ],
      colors: "white",
    };

    this.obj = this;
    this.API_URL_COMPANY = API_WEB_URLS.MASTER + "/0/token/GetJobList/Id/0";
    this.selectcompany = this.selectcompany.bind(this);
  }
  
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
   

    Fn_FillListData(
      this.obj,
      "Company",
      this.API_URL_COMPANY 
    );
  }

  selectcompany(shop) {
    var LastId = localStorage.getItem("F_CompanyMaster");

    if (LastId != null) {
      var element2 = document.getElementById("div" + LastId);
      element2.style.backgroundColor = "white";
    }

    var element = document.getElementById("div" + shop.Id);
    element.style.backgroundColor = "rgb(204, 214, 245)";
    localStorage.setItem("F_CompanyMaster", shop.Id);
    localStorage.setItem("FirmName", shop.Name);
    alert("You Selected - " + shop.Name);
  }

  render() {
    const shop = {
      name: "lk",
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            <h4>Dashboard</h4>

            <Row>
              {this.state.Company
                ? this.state.Company.map((option, key) => (
                    <CardShop
                      color="black"
                      shop={option}
                      key={"_shop_" + key}
                      selectcompany={this.selectcompany}
                      colors={this.state.colors}
                    />
                  ))
                : null}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(selectJob);
