import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS,IMG_URL } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";

class pageList_jobList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Job List";
    this.modalTitle = "Job List";
    this.rtPage_Add = "addjob";
    this.rtPage_Edit = "addjob";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/getjoblist";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    console.log("this is obj ", this.obj);
    
    //Fn_FillListData(this.obj, "gridData", this.API_URL + "/F_CustomerMaster/"+obj.uid);
    Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
    
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>JobTitle</th>
        <th>Grade</th>
        <th>JobDescription</th>
        <th>Date of Creation</th>
        <th>ClosingDate</th>
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td>{formData.JobTitle}</td>
        <td>{formData.Grade}</td>
        {formData.IsFileUpload ? (
          <a href={API_WEB_URLS.IMG_URL + formData.JobDescriptionFile}>
            Job Description File
          </a>
        ) : (
          <td>{formData.JobDescription}</td>
        )}

        <td>{formData.DateOfCreation}</td>
        <td>{formData.ClosingDateView}</td>
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
          Name : <span className="text-primary">{selectedFormData.Name}</span>
        </p>
        <p className="mb-4">
          Code : <span className="text-primary">{selectedFormData.Code}</span>
        </p>
      </>
    );
  }
  render() {
    return (
      <div className="page-content">
        <RCDisplayPage
          //page header paramaters
          Isbreadcrumb={true}
          breadCrumbTitle={this.breadCrumbTitle}
          breadcrumbItem={this.breadCrumbItem}
          obj={this.obj}
          //column paramaters
          isSearchBox={false}
          isSNo={true}
          isCheckBox={false}
          isViewDetails={false}
          //grid paramaters
          gridData={this.state.gridData}
          gridHeader={this.renderGridHeader}
          gridBody={this.renderGridBody}
          btnAdd_onClick={this.btnAdd_onClick}
          btnEdit_onClick={this.btnEdit_onClick}
          //delete link parameters
          confirm_alert={this.state.confirm_alert}
          success_dlg={this.state.success_dlg}
          dynamic_title={this.state.dynamic_title}
          dynamic_description={this.state.dynamic_description}
          toggleDeleteConfirm={toggleDeleteConfirm}
          toggleDeleteSuccess={toggleDeleteSuccess}
          btnDelete_onClick={this.btnDelete_onClick}
          //modal paramaters
          isOpenModal={this.state.modal}
          modalTitle={this.modalTitle}
          selectedFormData={this.state.selectedFormData}
          modalBody={this.renderModalBody}
          togglemodal={togglemodal}
          //user rights
          isAdd={true}
          isEdit2={true}
          isDelete={true}
        ></RCDisplayPage>
      </div>
    );
  }
}
export default compose(container)(pageList_jobList);
