import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr, { success, warning } from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch";
import * as serviceWorker from "../../serviceWorker";
import {
  Badge,
  Container,
  UncontrolledTooltip,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./dashboard.scss";
import { compose } from "recompose";
import { container } from "store/Containers/cntCommon";
import Select from "react-select";
import {
  Fn_AddEditData,
  Fn_DisplayData,
  Fn_EmployeeManage,
  Fn_FillListData,
  Fn_GetReport,
} from "store/functions";
import { data } from "jquery";
import { callGet_Data } from "store/common-actions";
import { API_WEB_URLS } from "constants/constAPI";
import FileTable from "./FileTable";
import { onMessage } from "firebase/messaging";
import { messaging } from "Firebase";
function formatDateTime(date) {
  let localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  let year = localDate.getFullYear();
  let month = String(localDate.getMonth() + 1).padStart(2, "0");
  let day = String(localDate.getDate()).padStart(2, "0");
  let hours = String(localDate.getUTCHours()).padStart(2, "0");
  let minutes = String(localDate.getUTCMinutes()).padStart(2, "0");
  let seconds = String(localDate.getUTCSeconds()).padStart(2, "0");
  let milliseconds = String(localDate.getUTCMilliseconds()).padStart(3, "0");

  let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedDateTime;
}
function truncateDescription(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "..."; // Truncate the description
  }
  return str;
}

function wordWrap(str, width) {
  let result = "";
  while (str.length > width) {
    result += str.substring(0, width) + "\n"; // Adding newline after every 10 characters
    str = str.substring(width);
  }
  return result + str; // Add remaining characters
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      rejectReason: "",
      modalOpen: false,
      FullscreenModal: false,
      Taskname: "",
      formData: {},
      Time: "",
      Description: "",
      Company_Name: "",
      Product_name: "",
      products: [],
      companies: [],
      priority: [
        { Id: 1, Name: "Low" },
        { Id: 2, Name: "Medium" },
        { Id: 3, Name: "High" },
      ],
      maxTime: null, // Convert minutes to seconds
      timerTime: 0,
      isRunning: false,
      isPaused: false,
      runningTaskId: null,
      openSubtask: null,
      isOpen: false, // Track the ID of the currently running task
      dataArray: [],
      taskArray: [],
      providerArray: [],
      subtasks: [],
      members: [],
      F_product: null,
      f_usermaster: null,
      showAlert: false,
      Reason: "",
      switch1: true,
      switch2: true,
      runningTasks: [],
      notrunningTasks: [],
      subTaskOpen: false,
      showRejectAlert: false,
      membersOld: [],
      selectedFiles: [],
      fileArray: [{ File1: "", Description1: "" }],
    };
    this.obj = this;
    this.startTimer = this.startTimer.bind(this);
    this.handleAddTask = this.handleAddTask.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleSubTask = this.handleSubTask.bind(this);
    this.handleMainToSub = this.handleMainToSub.bind(this);
    this.toggleOpener = this.toggleOpener.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.filldataArray = this.filldataArray.bind(this);
    this.updateDataArray = this.updateDataArray.bind(this);
    this.fillSubTaskArray = this.fillSubTaskArray.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.On_continue = this.On_continue.bind(this);
    this.On_cancel = this.On_cancel.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.isValidImageType = this.isValidImageType.bind(this);
  }
  updateProviderArray(arr) {
    let updatedArr = arr.filter(
      (item, index) => item.F_provider === this.state.uid
    );
    this.setState({
      providerArray: updatedArr,
    });
  }

  updateDataArray(arr) {
    let updatedArr = arr.filter(
      (item, index) =>
        item.F_provider !== this.state.uid &&
        item.f_usermaster === this.state.uid
    );
    this.setState({
      taskArray: updatedArr,
    });
  }

  componentDidMount() {
    onMessage(messaging, payload => {
      if (payload.from != null) {
        alert("Got a task");

        this.filldataArray();
        this.fillSubTaskArray();
      }
    });
    serviceWorker.register();
    this.handleClearLocalStorage = async () => {
      try {
        alert("Got a task");
        //idhar api call
        this.filldataArray();
        this.fillSubTaskArray();
      } catch (error) {
        console.error("Error calling API or clearing local storage:", error);
      }
    };
    console.log("in dashboard : before");
    window.addEventListener("clearLocalStorage", this.handleClearLocalStorage);
    console.log("in dashboard : After");
    const obj = JSON.parse(localStorage.getItem("authUser"));

    this.setState({
      uid: obj.uid,
      UserType: obj.Usertype,
    });
    Fn_FillListData(
      this.obj,
      "dataArray",
      "Task/GetTasks/" + (obj.Usertype == "admin" ? 0 : obj.uid)
    );

    if (obj.UserType === "admin") {
      this.setState({
        switch1: false,
      });
    }
    Fn_FillListData(this.obj, "companies", "Company/GetAll/0");

    Fn_FillListData(this.obj, "membersOld", "Member/GetMembers/0");

    const currentTime = new Date();

    const targetTime1PM = new Date(currentTime);
    targetTime1PM.setHours(13, 0, 0, 0);

    const targetTime7PM = new Date(currentTime);
    targetTime7PM.setHours(19, 0, 0, 12);

    const timeDifference1PM = targetTime1PM - currentTime;
    const timeDifference7PM = targetTime7PM - currentTime;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dataArray !== this.state.dataArray) {
      const idString = this.state.dataArray.map(item => item.Id).join(",");

      this.setState(
        {
          idString,
        },
        () => {
          this.fillSubTaskArray();
        }
      );
      this.updateProviderArray(this.state.dataArray);
      this.updateDataArray(this.state.dataArray);

      const runTask = this.state.dataArray.filter(
        item => item.Start_Time !== null
      );
      if (runTask) {
        this.setState({
          runningTasks: [...runTask],
        });
      }

      const notRunningTask = this.state.dataArray.filter(
        item => item.Start_Time === null
      );

      if (notRunningTask) {
        console.log("not running task", notRunningTask);
        this.setState({ notrunningTasks: [...notRunningTask] });
      }
      const runningTask = this.state.dataArray.find(
        item => item.Start_Time !== null && item.f_usermaster === this.state.uid
      );

      if (
        runningTask &&
        runningTask.Pause_Time &&
        !runningTask.Resume_Time &&
        this.state.UserType !== "admin" &&
        this.state.uid === runningTask.f_usermaster
      ) {
        const currentTime = new Date();

        const Time330pm = new Date(currentTime);
        Time330pm.setHours(15, 0, 0, 0);

        if (currentTime.getTime() > Time330pm.getTime()) {
          const vformData = new FormData();
          vformData.append("F_product", runningTask.F_product);
          vformData.append("F_company", runningTask.F_company);
          vformData.append("f_usermaster", runningTask.f_usermaster);
          vformData.append("f_provider", runningTask.F_provider);
          vformData.append("priority", runningTask.priority);
          vformData.append("Name", runningTask.Name);
          vformData.append("Description", runningTask.Description);
          vformData.append("time", runningTask.time);
          vformData.append("isApproved", runningTask.isApproved);
          vformData.append("Start_Time", runningTask.Start_Time);
          vformData.append("Pause_Time", runningTask.Pause_Time);
          vformData.append("Resume_Time", formatDateTime(Time330pm));

          vformData.append(
            "Reason",
            this.state.Reason.length > 0 ? this.state.Reason : null
          );

          Fn_EmployeeManage(
            this.obj,
            { arguList: { id: runningTask.Id, formData: vformData } },
            "Task/UpdateTask",
            "#",
            true,
            "Id"
          );

          this.setState({
            isPaused: false,
          });
        } else {
          this.setState({
            isPaused: true,
          });
        }
      }

      if (
        runningTask &&
        !runningTask.Resume_Time &&
        this.state.UserType !== "admin" &&
        this.state.uid === runningTask.f_usermaster
      ) {
        let timestamp2;
        const timestamp1 = new Date(runningTask.Start_Time).getTime();
        if (runningTask.Pause_Time) {
          timestamp2 = new Date(runningTask.Pause_Time).getTime();
        } else {
          timestamp2 = new Date().getTime();
        }
        const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);

        const differenceInSeconds = Math.abs(
          differenceInMilliseconds / 1000 - runningTask.Timegap * 60
        );

        this.setState(
          {
            runningTaskId: runningTask.Id,
            isRunning: true,
            timerTime: differenceInSeconds,
            maxTime: runningTask.time * 60,
          },
          () => {
            if (runningTask && !runningTask.Pause_Time) {
              const startTime = new Date(runningTask.Start_Time).getTime();

              // Clear previous timer if exists
              if (this.timer) {
                clearInterval(this.timer);
              }

              // Start new timer
              this.timer = setInterval(() => {
                const currentTime = new Date().getTime();
                const elapsedTimeInSeconds = Math.floor(
                  (currentTime - startTime) / 1000 - runningTask.Timegap * 60
                );

                this.setState({
                  timerTime: elapsedTimeInSeconds,
                });
              }, 1000);
            } else {
              this.setState({
                isPaused: true,
              });
            }
          }
        );
      }
    }
    if (prevState.membersOld !== this.state.membersOld) {
      let arr = this.state.membersOld.filter(item => item.F_usertype !== 1);
      this.setState({
        members: arr,
      });
    }
  }
  isValidImageType = fileName => {
    const validExtensions = ["jpeg", "png", "jpg"];
    const extension = fileName.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  toggleModal = id => {
    if (id === 0) {
      this.setState({
        subTaskOpen: true,
        modalOpen: !this.state.modalOpen,
      });
    } else {
      this.setState({
        subTaskOpen: false,
        modalOpen: !this.state.modalOpen,
      });
    }
  };
  toggleFullscreenModal = () => {
    this.setState({ FullscreenModal: !this.state.FullscreenModal });
  };
  toggleOpener = Id => {
    this.setState(prevState => ({
      isOpen: prevState.openSubtask !== Id || !prevState.isOpen,
      openSubtask: prevState.openSubtask !== Id ? Id : null,
    }));
  };
  handleShowDescription(data, check) {
    this.setState({
      Company_Name: data.Company_Name,
      Product_name: data.Product_name,
      Description: data.Description,
      TimeDes: data.time,
    });
    let vformData = new FormData();
    if (check) {
      vformData.append("IsSubtask", true);
      Fn_GetReport(
        this.obj,
        {
          arguList: {
            id: 0,
            formData: vformData,
          },
        },
        "Task/GetImages/" + data.Id,
        "ImagesArr",
        true
      );
    } else {
      vformData.append("IsSubtask", false);
      Fn_GetReport(
        this.obj,
        {
          arguList: {
            id: 0,
            formData: vformData,
          },
        },
        "Task/GetImages/" + data.Id,
        "ImagesArr",
        true
      );
    }
    this.toggleFullscreenModal();
  }
  handleApprove(item, value) {
    this.setState({
      item,
    });
    let vformData = new FormData();
    vformData.append("F_product", item.F_product);
    vformData.append("F_company", item.F_company);
    vformData.append("f_usermaster", item.f_usermaster);
    vformData.append("priority", item.priority);
    vformData.append("f_provider", item.F_provider);
    vformData.append("Name", item.Name);
    vformData.append("Description", item.Description);
    vformData.append("time", item.time);
    vformData.append(
      "Reason",
      this.state.Reason.length > 0 ? this.state.Reason : null
    );
    vformData.append("isApproved", value);
    if (value === 2 && this.state.rejectReason.length < 4) {
      this.setState({
        showRejectAlert: true,
      });
      return;
    }
    vformData.append(
      "rejectReason",
      this.state.rejectReason.length > 0 ? this.state.rejectReason : null
    );
    Fn_EmployeeManage(
      this.obj,
      { arguList: { id: item.Id, formData: vformData } },
      "Task/UpdateTask",
      "#",
      true,
      "Id"
    );

    this.filldataArray();
  }
  handleSubTask(Id) {
    this.setState({
      subTaskOpen: true,
      F_taskmaster: Id,
    });
    this.toggleModal(0);
  }
  handleMainToSub(data) {
    let vformData = new FormData();
    vformData.append("F_product", data.F_product);
    vformData.append("F_company", data.F_company);
    vformData.append("F_taskmaster", this.state.runningTaskId);
    vformData.append("Name", data.Name);
    vformData.append("Description", data.Description);
    vformData.append("time", data.time);

    Fn_EmployeeManage(
      this.obj,
      { arguList: { id: data.Id, formData: vformData } },
      "Task/InsertMaintoSubTask",
      "#",
      true,
      "Id"
    );
  }
  filldataArray() {
    this.setState(
      {
        id: 0,
        formData: {},
        fileArray: [{ File1: "", Description1: "" }],
      },
      () => {
        Fn_FillListData(
          this.obj,
          "dataArray",
          "Task/GetTasks/" + this.state.uid
        );
      }
    );
  }
  fillSubTaskArray() {
    this.setState(
      {
        subTaskOpen: false,
        F_taskmaster: null,
        fileArray: [{ File1: "", Description1: "" }],
      },
      () => {
        if (this.state.idString) {
          console.log(this.state.idString);
          let vformData = new FormData();
          vformData.append("Ids", this.state.idString);
          Fn_GetReport(
            this.obj,
            {
              arguList: {
                id: 0,
                formData: vformData,
              },
            },
            "Task/GetSubTasks",
            "subtasks",
            true
          );
        }
      }
    );
  }
  handleAddTask = e => {
    e.preventDefault();
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (!this.state.subTaskOpen) {
      let vformData = new FormData();
      vformData.append("F_product", this.state.formData.F_product);
      vformData.append("F_company", this.state.formData.F_company);
      vformData.append("f_usermaster", this.state.formData.f_usermaster);
      vformData.append("priority", this.state.formData.priority);
      vformData.append(
        "f_provider",
        this.state.formData.f_usermaster === obj.uid ? 0 : obj.uid
      );
      vformData.append("Name", this.state.formData.Name);
      vformData.append("Description", this.state.formData.Description);
      vformData.append("time", this.state.formData.time);
      vformData.append("Reason", null);
      vformData.append(
        "rejectReason",
        this.state.rejectReason.length > 0 ? this.state.rejectReason : null
      );

      this.state.fileArray.forEach((item, index) => {
        const fileKey = `File${index + 1}`;
        const descKey = `Description${index + 1}`;
        vformData.append(fileKey, item[fileKey]);
        vformData.append(descKey, item[descKey]);
      });

      if (this.state.id > 0) {
        vformData.append("isApproved", 0);
        Fn_EmployeeManage(
          this.obj,
          { arguList: { id: this.state.id, formData: vformData } },
          "Task/UpdateTask",
          "#",
          true,
          "Id"
        );
      } else {
        vformData.append(
          "isApproved",
          obj.Usertype == "admin"
            ? 1
            : this.state.formData.f_usermaster === obj.uid
            ? 1
            : 0
        );

        Fn_EmployeeManage(
          this.obj,
          { arguList: { id: 0, formData: vformData } },
          "Task/InsertTask",
          "#",
          true,
          "Id"
        );
      }
    } else {
      let vformData = new FormData();
      vformData.append("F_product", this.state.formData.F_product);
      vformData.append("F_company", this.state.formData.F_company);
      vformData.append("F_taskmaster", this.state.F_taskmaster);
      vformData.append("Name", this.state.formData.Name);
      vformData.append("Description", this.state.formData.Description);
      vformData.append("time", this.state.formData.time);

      Fn_EmployeeManage(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        "Task/InsertSubTask",
        "#",
        true,
        "Id"
      );
    }
    this.toggleModal(1);
  };
  handleFileChange = (event, index) => {
    const file = event.target.files[0];

    const { name } = event.target;

    console.log(name, file);
    const rowsInput = [...this.state.fileArray];

    rowsInput[index][name] = file;

    this.setState({ fileArray: rowsInput });
  };
  handleDescriptionChange = (event, index) => {
    const { name, value } = event.target;

    const rowsInput = [...this.state.fileArray];

    rowsInput[index][name] = value;

    this.setState({ fileArray: rowsInput }, () => {
      console.log(this.state.fileArray);
    });
  };

  handleAddRow(rowIndex) {
    if (this.state.fileArray.length === 10) {
      alert("you can't add more");
      return;
    }

    const nextId = this.state.fileArray.length + 1;
    const newRow = {
      [`File${nextId}`]: "",
      [`Description${nextId}`]: "", // Use template literals for dynamic property names
    };

    this.setState(prevState => ({
      fileArray: [...prevState.fileArray, newRow],
    }));
  }

  handleDeleteRow = () => {
    if (this.state.fileArray.length === 1) {
      return;
    }

    const updatedData = this.state.fileArray.slice(0, -1);

    this.setState(prevState => ({
      fileArray: updatedData,
    }));
  };

  showToastWithCloseButton(toastType, message) {
    toastr.options = {
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      progressBar: true,
      timeOut: 3000,
    };
    if (toastType == "success") toastr.success(message);
    else if (toastType == "warning") toastr.warning(message);
    else if (toastType == "error") toastr.error(message);
  }
  handleFormChange(event) {
    const { name, value } = event.target;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleCompanyChange(event) {
    let companyId = event.target.value;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        F_company: companyId !== "" ? parseInt(companyId) : null,
      },
    }));
    Fn_FillListData(
      this.obj,
      "products",
      "Product/GetProductByCompany/" + companyId
    );
  }
  handleProductChange(event) {
    const productId = event.target.value;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        F_product: productId !== "" ? parseInt(productId) : null,
      },
    }));
  }
  handleMemberChange(event) {
    const memberId = event.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        f_usermaster: memberId !== "" ? parseInt(memberId) : null,
      },
    }));
  }

  startTimer = data => {
    if (!this.state.isRunning) {
      let currentDate = new Date();
      let formattedDate = formatDateTime(currentDate);
      let vformData = new FormData();
      vformData.append("F_product", data.F_product);
      vformData.append("F_company", data.F_company);
      vformData.append("f_usermaster", data.f_usermaster);
      vformData.append("priority", data.priority);
      vformData.append("f_provider", data.F_provider);
      vformData.append("Name", data.Name);
      vformData.append("Description", data.Description);
      vformData.append(
        "rejectReason",
        this.state.rejectReason.length > 0 ? this.state.rejectReason : null
      );
      vformData.append("time", data.time);
      vformData.append(
        "Reason",
        this.state.Reason.length > 0 ? this.state.Reason : null
      );
      vformData.append("Start_Time", formattedDate);
      vformData.append("isApproved", 1);

      Fn_EmployeeManage(
        this.obj,
        { arguList: { id: data.Id, formData: vformData } },
        "Task/UpdateTask",
        "#",
        true,
        "Id"
      );

      if (data.Start_Time == null) {
        this.setState({
          isRunning: true,
          runningTaskId: data.Id,
          maxTime: data.time * 60,
        });
      }

      this.timer = setInterval(() => {
        this.setState(prevState => ({
          timerTime: prevState.timerTime + 1,
        }));
      }, 1000);
    } else {
      this.showToastWithCloseButton("warning", "Stop the previous task first");
    }
  };

  stopTimer = item => {
    if (item) {
      this.setState({
        data: item,
      });
    }
    const currentTime = new Date();
    currentTime.setHours(15, 0, 0, 0);
    let formatThreeThirty = formatDateTime(currentTime);

    let data = this.state.data ? this.state.data : item;
    clearInterval(this.timer);
    const { maxTime, timerTime, Reason } = this.state;
    const threshold = maxTime * 1.15;

    if (threshold < timerTime && this.state.Reason.length === 0) {
      this.setState({ showAlert: true });
    }

    const currentDate = new Date();
    const formattedDate = formatDateTime(currentDate);
    const vformData = new FormData();
    vformData.append("F_product", data.F_product);
    vformData.append("F_company", data.F_company);
    vformData.append("f_usermaster", data.f_usermaster);
    vformData.append("f_provider", data.F_provider);
    vformData.append("priority", data.priority);
    vformData.append("Name", data.Name);
    vformData.append("Description", data.Description);
    vformData.append("time", data.time);
    vformData.append("Start_Time", data.Start_Time);
    vformData.append("End_Time", formattedDate);
    vformData.append(
      "rejectReason",
      this.state.rejectReason.length > 0 ? this.state.rejectReason : null
    );
    if (data.Pause_Time) {
      vformData.append("Pause_Time", data.Pause_Time);

      if (data.Resume_Time) {
        vformData.append("Resume_Time", data.Resume_Time);
      }
      // Get the current time
      const currentTime = new Date();

      const targetTime330PM = new Date();
      targetTime330PM.setHours(15, 0, 0, 0);

      if (currentTime.getTime() > targetTime330PM.getTime()) {
        vformData.append("Resume_Time", formatThreeThirty);
      } else {
        this.showToastWithCloseButton(
          "error",
          "Please resume task then end it"
        );
        return;
      }
    }

    vformData.append("isApproved", data.isApproved);
    vformData.append(
      "Reason",
      this.state.Reason.length > 0 ? this.state.Reason : null
    );

    if (maxTime > timerTime || Reason.length > 3) {
      Fn_EmployeeManage(
        this.obj,
        { arguList: { id: data.Id, formData: vformData } },
        "Task/UpdateTask",
        "#",
        true,
        "Id"
      );
    }

    this.setState({
      timerTime: 0,
      isRunning: false,
      runningTaskId: null,
    });
  };
  resumeTimer(data) {
    const currentDate = new Date();
    const formattedDate = formatDateTime(currentDate);

    const targetTime330PM = new Date();
    targetTime330PM.setHours(15, 0, 0, 0);

    const vformData = new FormData();
    if (currentDate.getTime() > targetTime330PM.getTime()) {
      const currentDate = new Date();
      currentDate.setHours(15, 0, 0, 0);
      let formatThreeThirty = formatDateTime(currentDate);
      vformData.append("Resume_Time", formatThreeThirty);
    } else {
      vformData.append("Resume_Time", formattedDate);
    }
    vformData.append("F_product", data.F_product);
    vformData.append("F_company", data.F_company);
    vformData.append("f_usermaster", data.f_usermaster);
    vformData.append("f_provider", data.F_provider);
    vformData.append("priority", data.priority);
    vformData.append("Name", data.Name);
    vformData.append("Description", data.Description);
    vformData.append("time", data.time);
    vformData.append("isApproved", data.isApproved);
    vformData.append("Start_Time", data.Start_Time);
    vformData.append("Pause_Time", data.Pause_Time);
    vformData.append(
      "rejectReason",
      this.state.rejectReason.length > 0 ? this.state.rejectReason : null
    );
    vformData.append(
      "Reason",
      this.state.Reason.length > 0 ? this.state.Reason : null
    );
    Fn_EmployeeManage(
      this.obj,
      { arguList: { id: data.Id, formData: vformData } },
      "Task/UpdateTask",
      "#",
      true,
      "Id"
    );
    this.setState({
      isPaused: !this.state.isPaused,
    });
  }
  pauseTimer(data) {
    const currentTime = new Date();
    const lessThan1PM = new Date();
    lessThan1PM.setHours(13, 0, 0, 0);
    const greaterThan330PM = new Date();
    greaterThan330PM.setHours(15, 0, 0, 0);
    const lessThan7PM = new Date();
    lessThan7PM.setHours(19, 0, 0, 0);

    if (currentTime < lessThan1PM) {
      this.showToastWithCloseButton("warning", "You can't pause now");
      return;
    } else if (currentTime > greaterThan330PM && currentTime < lessThan7PM) {
      this.showToastWithCloseButton("warning", "You can't pause now");
      return;
    }

    clearInterval(this.timer);
    const currentDate = new Date();

    const formattedDate = formatDateTime(currentDate);

    const vformData = new FormData();
    vformData.append("F_product", data.F_product);
    vformData.append("F_company", data.F_company);
    vformData.append("f_usermaster", data.f_usermaster);
    vformData.append("f_provider", data.F_provider);
    vformData.append("priority", data.priority);
    vformData.append("Name", data.Name);
    vformData.append("Description", data.Description);
    vformData.append("time", data.time);
    vformData.append("isApproved", data.isApproved);
    vformData.append("Start_Time", data.Start_Time);
    vformData.append("Pause_Time", formattedDate);
    vformData.append(
      "rejectReason",
      this.state.rejectReason.length > 0 ? this.state.rejectReason : null
    );
    vformData.append(
      "Reason",
      this.state.Reason.length > 0 ? this.state.Reason : null
    );
    Fn_EmployeeManage(
      this.obj,
      { arguList: { id: data.Id, formData: vformData } },
      "Task/UpdateTask",
      "#",
      true,
      "Id"
    );
    this.setState({
      isPaused: !this.state.isPaused,
    });
  }
  handleEdit(Id) {
    this.setState({
      id: Id,
    });
    Fn_DisplayData(this.obj, Id, "Task/GetTasks");
    Fn_FillListData(this.obj, "ImgArray", "Chat/Master/fileArray/Id/" + Id);
    this.toggleModal(1);
  }
  handleContinue() {
    this.stopTimer();
    this.setState({ showAlert: false });
  }
  handleCancel() {
    this.setState({ showAlert: false });
  }
  On_continue() {
    this.setState({
      showRejectAlert: false,
    });
    this.handleApprove(this.state.item, 2);
  }
  On_cancel() {
    this.setState({
      showRejectAlert: false,
    });
  }
  formatTime = timeInSeconds => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.round(timeInSeconds % 60); // Round seconds
    return `${hours > 0 ? hours + "h " : ""}${minutes}m ${seconds}s`;
  };

  render() {
    const RedCharge = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
        >
          <path fill="#f20707" d="M10 3h8l-4 6h4L6 21l4-9H6z" />
        </svg>
      );
    };
    const YellowCharge = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
        >
          <path fill="#d4c308" d="M10 3h8l-4 6h4L6 21l4-9H6z" />
        </svg>
      );
    };
    const GreenCharge = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
        >
          <path fill="#199a28" d="M10 3h8l-4 6h4L6 21l4-9H6z" />
        </svg>
      );
    };
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 0,
            marginRight: 30,
          }}
        >
          Assigned
        </div>
      );
    };

    const OnSymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 0,
            marginLeft: 30,
          }}
        >
          My&nbsp;Task
        </div>
      );
    };
    const OffTasks = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 0,
            marginRight: 30,
          }}
        >
          Not&nbsp;running
        </div>
      );
    };

    const OnTasks = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 0,
            marginLeft: 30,
          }}
        >
          running
        </div>
      );
    };

    const {
      Reason,

      modalOpen,
      timerTime,
      isRunning,
      maxTime,
      runningTaskId,
      isPaused,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Shinewell Innovations</title>
          </MetaTags>
          <Container fluid>
            <Button
              onClick={() => this.toggleModal(1)}
              style={{
                marginRight: "20px",
                border: "none",
                backgroundColor: "transparent", // Changed "none" to "transparent"
                padding: "0", // Added padding to avoid potential click issues
                cursor: "pointer", // Added cursor style to indicate it's clickable
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#199a28"
                  d="M13.986 4a2.25 2.25 0 0 0-2.236-2h-3.5a2.25 2.25 0 0 0-2.236 2H4.25A2.25 2.25 0 0 0 2 6.25v13.5A2.25 2.25 0 0 0 4.25 22h8.56a6.5 6.5 0 0 1-1.078-1.5H4.25a.75.75 0 0 1-.75-.75V6.25a.75.75 0 0 1 .75-.75h2.129c.404.603 1.091 1 1.871 1h3.5c.78 0 1.467-.397 1.871-1h2.129a.75.75 0 0 1 .75.75v4.826a6.6 6.6 0 0 1 1.5-.057V6.25A2.25 2.25 0 0 0 15.75 4zm.009.096L14 4.25q0-.078-.005-.154M8.25 3.5h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5m3.707 10.604a6.5 6.5 0 0 1 2.147-2.147l.926-.927a.75.75 0 1 0-1.06-1.06L9 14.94l-1.97-1.97a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0zM23 17.5a5.5 5.5 0 1 0-11 0a5.5 5.5 0 0 0 11 0m-5 .5l.001 2.503a.5.5 0 1 1-1 0V18h-2.505a.5.5 0 0 1 0-1H17v-2.5a.5.5 0 1 1 1 0V17h2.497a.5.5 0 0 1 0 1z"
                />
              </svg>
            </Button>

            {this.state.UserType === "admin" ? (
              <Switch
                uncheckedIcon={<OffTasks />}
                checkedIcon={<OnTasks />}
                className="me-1 mb-sm-8 mb-2"
                onColor="#626ed4"
                width={100}
                onChange={() => {
                  this.setState({ switch2: !this.state.switch2 });
                }}
                checked={this.state.switch2}
              />
            ) : (
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2"
                onColor="#626ed4"
                width={100}
                onChange={() => {
                  this.setState({ switch1: !this.state.switch1 });
                }}
                checked={this.state.switch1}
              />
            )}

            {this.state.UserType != "admin" && (
              <div style={{ float: "right" }}>
                <h4 style={{ color: timerTime > maxTime ? "red" : "" }}>
                  Timer: {this.formatTime(timerTime)}
                </h4>
              </div>
            )}
            {this.state.UserType != "admin" && this.state.switch1 ? (
              <Card>
                <CardBody>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Task Names</th>
                          <th>Description</th>
                          <th>time</th>
                          <th>Subtask&apos;s</th>
                          <th>New subtask</th>
                          <th>Action</th>
                          <th>isApproved</th>
                          <th>Priority</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.taskArray.length > 0 &&
                          this.state.taskArray.map((data, index) => (
                            <tr key={data.Id}>
                              <td>{data.Name}</td>
                              <td
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-wrap", // This will enable word-wrap
                                }}
                                onClick={() => this.handleShowDescription(data)}
                              >
                                {wordWrap(
                                  truncateDescription(data.Description, 50),
                                  10
                                )}
                              </td>

                              <td>{data.time}</td>
                              {this.state.isOpen &&
                              data.Id === this.state.openSubtask ? (
                                <td onClick={() => this.toggleOpener(data.Id)}>
                                  {this.state.subtasks.length > 0 &&
                                    this.state.subtasks
                                      .filter(
                                        subtask =>
                                          subtask.F_taskmaster === data.Id
                                      )
                                      .map(subtask => (
                                        <ul key={subtask.Id}>
                                          {" "}
                                          <li
                                            onClick={() =>
                                              this.handleShowDescription(
                                                subtask,
                                                1
                                              )
                                            }
                                          >
                                            {subtask.Name}
                                          </li>{" "}
                                        </ul>
                                      ))}
                                </td>
                              ) : (
                                <td onClick={() => this.toggleOpener(data.Id)}>
                                  See Task
                                </td>
                              )}
                              <td>
                                {runningTaskId === data.Id && isRunning && (
                                  <Button
                                    color="primary"
                                    onClick={() => this.handleSubTask(data.Id)}
                                  >
                                    Add Subtask
                                  </Button>
                                )}{" "}
                                {isRunning && runningTaskId !== data.Id && (
                                  <Button
                                    color="primary"
                                    onClick={() => this.handleMainToSub(data)}
                                  >
                                    Add to subtask
                                  </Button>
                                )}
                              </td>
                              <td>
                                {" "}
                                {runningTaskId === data.Id && isRunning ? (
                                  <>
                                    {isPaused ? (
                                      <Button
                                        color="primary"
                                        olor="danger"
                                        onClick={() => this.resumeTimer(data)}
                                      >
                                        Resume
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        olor="danger"
                                        onClick={() => this.pauseTimer(data)}
                                      >
                                        Pause
                                      </Button>
                                    )}
                                    <Button
                                      color="danger"
                                      onClick={() => this.stopTimer(data)}
                                    >
                                      Stop
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    color="success"
                                    onClick={() => this.startTimer(data)}
                                  >
                                    Start
                                  </Button>
                                )}
                              </td>
                              <td>
                                {data.isApproved === 0 ? (
                                  <>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        this.handleApprove(data, 1)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-hand-thumbs-up"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8.864.046C7.908-.193 7.02.53 8.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                      </svg>
                                    </Button>
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        this.handleApprove(data, 2)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-hand-thumbs-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1" />
                                      </svg>
                                    </Button>
                                  </>
                                ) : data.isApproved === 1 ? (
                                  <Badge
                                    className={"font-size-12 badge-soft-white"}
                                    color={"success"}
                                    pill
                                  >
                                    approved
                                  </Badge>
                                ) : (
                                  <Badge
                                    className={"font-size-12 badge-soft-white"}
                                    color={"danger"}
                                    pill
                                  >
                                    Rejected
                                  </Badge>
                                )}
                              </td>
                              <td>
                                {data.priority === 1 ? (
                                  <>
                                    <GreenCharge />
                                  </>
                                ) : data.priority === 2 ? (
                                  <>
                                    <YellowCharge />
                                    <YellowCharge />
                                  </>
                                ) : (
                                  <>
                                    <RedCharge />
                                    <RedCharge />
                                    <RedCharge />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            ) : (
              this.state.UserType != "admin" && (
                <Card>
                  <CardBody>
                    <div style={{ overflowX: "auto" }}>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Task Name</th>
                            <th>Given to</th>
                            <th>Assigned by</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>Action</th>
                            <th>isApproved</th>
                            <th>Reason</th>
                            <th>Date Assigned</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.providerArray.length > 0 &&
                            this.state.providerArray.map((data, index) => (
                              <tr key={data.Id}>
                                <td>{data.Name}</td>
                                <td>{data.Username}</td>
                                <td>
                                  {data.Provider
                                    ? data.Provider
                                    : data.Username}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre-wrap", // This will enable word-wrap
                                  }}
                                  onClick={() =>
                                    this.handleShowDescription(data)
                                  }
                                >
                                  {wordWrap(
                                    truncateDescription(data.Description, 50),
                                    10
                                  )}
                                </td>
                                <td>{data.time}</td>
                                <td>
                                  <Button
                                    color="danger"
                                    onClick={() => this.handleEdit(data.Id)}
                                    style={{ padding: "0px 8px" }}
                                  >
                                    <i
                                      className="mdi mdi-pencil font-size-12 mr-3"
                                      id="edittooltip"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="edittooltip"
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Button>
                                </td>
                                <td>
                                  {data.isApproved === 1 ? (
                                    <Badge
                                      className={
                                        "font-size-12 badge-soft-white"
                                      }
                                      color={"success"}
                                      pill
                                    >
                                      approved
                                    </Badge>
                                  ) : data.isApproved === 0 ? (
                                    <Badge
                                      className={
                                        "font-size-12 badge-soft-white"
                                      }
                                      color={"warning"}
                                      pill
                                    >
                                      Pending
                                    </Badge>
                                  ) : (
                                    data.isApproved === 2 && (
                                      <Badge
                                        className={
                                          "font-size-12 badge-soft-white"
                                        }
                                        color={"danger"}
                                        pill
                                      >
                                        Rejected
                                      </Badge>
                                    )
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {data.rejectReason}
                                </td>
                                <td>{data.Date}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )
            )}
            {this.state.UserType === "admin" && this.state.switch2 ? (
              <Card>
                <CardBody>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Task Name</th>
                          <th>Given to</th>
                          <th>Assigned by</th>
                          <th>Description</th>
                          <th>Time</th>
                          <th>Action</th>
                          <th>isApproved</th>
                          <th>Date Assigned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.runningTasks.length > 0 &&
                          this.state.runningTasks.map((data, index) => (
                            <tr key={data.Id}>
                              <td>{data.Name}</td>
                              <td>{data.Username}</td>
                              <td>
                                {data.Provider ? data.Provider : data.Username}
                              </td>
                              <td
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-wrap", // This will enable word-wrap
                                }}
                                onClick={() => this.handleShowDescription(data)}
                              >
                                {wordWrap(
                                  truncateDescription(data.Description, 50),
                                  10
                                )}
                              </td>
                              <td>{data.time}</td>
                              <td>
                                <Button
                                  color="danger"
                                  onClick={() => this.handleEdit(data.Id)}
                                  style={{ padding: "0px 8px" }}
                                >
                                  <i
                                    className="mdi mdi-pencil font-size-12 mr-3"
                                    id="edittooltip"
                                  ></i>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="edittooltip"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                </Button>
                              </td>
                              <td>
                                {data.isApproved === 1 ? (
                                  <Badge
                                    className={"font-size-12 badge-soft-white"}
                                    color={"success"}
                                    pill
                                  >
                                    approved
                                  </Badge>
                                ) : data.isApproved === 0 ? (
                                  <Badge
                                    className={"font-size-12 badge-soft-white"}
                                    color={"warning"}
                                    pill
                                  >
                                    Pending
                                  </Badge>
                                ) : (
                                  data.isApproved === 2 && (
                                    <Badge
                                      className={
                                        "font-size-12 badge-soft-white"
                                      }
                                      color={"danger"}
                                      pill
                                    >
                                      Rejected
                                    </Badge>
                                  )
                                )}
                              </td>
                              <td>{data.Date}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            ) : (
              this.state.UserType === "admin" && (
                <Card>
                  <CardBody>
                    <div style={{ overflowX: "auto" }}>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Task Name</th>
                            <th>Given to</th>
                            <th>Assigned by</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>Action</th>
                            <th>isApproved</th>
                            <th>Date Assigned</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.notrunningTasks.length > 0 &&
                            this.state.notrunningTasks.map((data, index) => (
                              <tr key={data.Id}>
                                <td>{data.Name}</td>
                                <td>{data.Username}</td>
                                <td>
                                  {data.Provider
                                    ? data.Provider
                                    : data.Username}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre-wrap", // Enables word-wrap
                                  }}
                                  onClick={() =>
                                    this.handleShowDescription(data)
                                  }
                                >
                                  {data.Description}
                                </td>
                                <td>{data.time}</td>
                                <td>
                                  <Button
                                    color="danger"
                                    onClick={() => this.handleEdit(data.Id)}
                                    style={{ padding: "0px 8px" }}
                                  >
                                    <i
                                      className="mdi mdi-pencil font-size-12 mr-3"
                                      id={`edittooltip-${data.Id}`}
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`edittooltip-${data.Id}`}
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Button>
                                </td>
                                <td>
                                  {data.isApproved === 1 ? (
                                    <Badge
                                      className="font-size-12 badge-soft-white"
                                      color="success"
                                      pill
                                    >
                                      Approved
                                    </Badge>
                                  ) : data.isApproved === 0 ? (
                                    <Badge
                                      className="font-size-12 badge-soft-white"
                                      color="warning"
                                      pill
                                    >
                                      Pending
                                    </Badge>
                                  ) : (
                                    data.isApproved === 2 && (
                                      <Badge
                                        className="font-size-12 badge-soft-white"
                                        color="danger"
                                        pill
                                      >
                                        Rejected
                                      </Badge>
                                    )
                                  )}
                                </td>
                                <td>{data.Date}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )
            )}

            <Modal isOpen={modalOpen} toggle={() => this.toggleModal(0)}>
              <ModalHeader
                toggle={() => {
                  this.setState(
                    {
                      id: 0,
                      formData: {},
                      fileArray: [{ File1: "", Description1: "" }],
                    },
                    () => {
                      this.toggleModal(0);
                    }
                  );
                }}
              >
                Add {this.state.subTaskOpen ? "Subtask" : "Task"}
              </ModalHeader>
              <form onSubmit={this.handleAddTask}>
                <ModalBody>
                  {!this.state.subTaskOpen && (
                    <Row style={{ marginBottom: "10px" }}>
                      <Col sm="3">
                        <label className="col-form-label">Member :</label>
                      </Col>
                      <Col lg="8">
                        <select
                          style={{ marginBottom: "8px" }}
                          onChange={this.handleMemberChange}
                          className="form-control"
                        >
                          <option value="-1">select Member</option>
                          {this.state.members.map(item => (
                            <option
                              key={item.Id}
                              value={item.Id}
                              selected={
                                item.Id === this.state.formData.f_usermaster
                              }
                            >
                              {item.Name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm="3" className="mb-3">
                      <label htmlFor="branch" className="col-form-label">
                        Company
                      </label>
                    </Col>
                    <Col sm="8">
                      <select
                        className="form-control"
                        style={{ marginBottom: "8px" }}
                        onChange={this.handleCompanyChange}
                      >
                        <option value="-1">select Company</option>
                        {this.state.companies.length > 0 &&
                          this.state.companies.map(item => (
                            <option
                              key={item.Id}
                              value={item.Id}
                              selected={
                                item.Id === this.state.formData.F_company
                              }
                            >
                              {item.Name}
                            </option>
                          ))}
                      </select>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col sm="3">
                      <label className="col-form-label">Product :</label>
                    </Col>
                    <Col lg="8">
                      <select
                        className="form-control"
                        style={{ marginBottom: "8px" }}
                        onChange={this.handleProductChange}
                      >
                        <option value="-1">select Product</option>
                        {this.state.products.map(item => (
                          <option
                            key={item.Id}
                            value={item.Id}
                            selected={item.Id === this.state.formData.F_product}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "10px" }}>
                    <Col sm="3">
                      <label className="col-form-label">Task Name :</label>
                    </Col>
                    <Col lg="8">
                      <input
                        type="text"
                        name="Name"
                        className="form-control"
                        value={
                          this.state.formData.Name
                            ? this.state.formData.Name
                            : " "
                        }
                        onChange={this.handleFormChange}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col sm="3">
                      <label className="col-form-label">Time{"(min) :"}</label>
                    </Col>

                    <Col lg="8">
                      <input
                        type="text"
                        name="time"
                        className="form-control"
                        value={
                          this.state.formData.time
                            ? this.state.formData.time
                            : " "
                        }
                        onChange={this.handleFormChange}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col sm="3">
                      <label className="col-form-label">Description :</label>
                    </Col>

                    <Col lg="8">
                      <textarea
                        name="Description"
                        className="form-control"
                        value={
                          this.state.formData.Description
                            ? this.state.formData.Description
                            : ""
                        }
                        onChange={this.handleFormChange}
                        rows={4}
                        cols={50}
                      />
                    </Col>
                  </Row>
                  {!this.state.subTaskOpen && (
                    <>
                      <Row>
                        <Col sm="3" className="mb-3">
                          <label htmlFor="branch" className="col-form-label">
                            Priority
                          </label>
                        </Col>
                        <Col sm="8">
                          <select
                            name="priority"
                            className="form-control"
                            style={{ marginBottom: "8px" }}
                            required
                            onChange={this.handleFormChange}
                          >
                            <option value="-1">select Priority</option>
                            {this.state.priority.length > 0 &&
                              this.state.priority.map(item => (
                                <option
                                  key={item.Id}
                                  value={item.Id}
                                  selected={
                                    item.Id === this.state.formData.priority
                                  }
                                >
                                  {item.Name}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col lg="12">
                          <FileTable
                            dataArray={this.state.fileArray}
                            handleFileChange={this.handleFileChange}
                            handleDescriptionChange={
                              this.handleDescriptionChange
                            }
                            handleAddRow={this.handleAddRow}
                            handleDeleteRow={this.handleDeleteRow}
                          ></FileTable>
                        </Col>
                      </Row>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit">
                    Add
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={() => {
                      this.setState(
                        {
                          id: 0,
                          formData: {},
                          fileArray: [{ File1: "", Description1: "" }],
                        },
                        () => {
                          this.toggleModal(0);
                        }
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            <Modal
              size="xl"
              isOpen={this.state.FullscreenModal}
              toggle={this.toggleFullscreenModal}
              className="modal-fullscreen"
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  Company : {this.state.Company_Name} <br /> Product :{" "}
                  {this.state.Product_name} <br /> Time : {this.state.TimeDes}
                </h5>
                <button
                  onClick={() => this.setState({ FullscreenModal: false })}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5>Description</h5>
                <p>{this.state.Description}</p>
                {this.state.ImagesArr &&
                  this.state.ImagesArr.map((image, index) => (
                    <div key={image.Id} style={{ marginBottom: "10px" }}>
                      <a
                        href={`https://apitaskmanager.shinewellsofttech.co.in/SaveImageF/${image.Name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        File {index + 1} :{" "}
                        
                          <img
                            style={{ width: "40vw" }}
                            src={`https://apitaskmanager.shinewellsofttech.co.in/SaveImageF/${image.Name}`}
                            alt="File"
                          />
                       
                      </a>
                      <span style={{ marginLeft: "10px" }}>
                        Description {index + 1}: {image.Description}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.toggleFullscreenModal}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Modal>
            <SweetAlert
              show={this.state.showAlert}
              title=""
              confirmBtnText="Continue"
              onConfirm={this.handleContinue}
              onCancel={this.handleCancel}
              showCancel
              customClass="blue-background"
            >
              <div>
                <Row>
                  <Col sm="4">
                    <label>Give Reason :</label>
                  </Col>
                  <Col lg="6">
                    <textarea
                      name="Reason"
                      className="form-control"
                      value={this.state.Reason ? this.state.Reason : ""}
                      onChange={this.handleInputChange}
                      rows={8}
                      cols={50}
                    />
                  </Col>
                </Row>
              </div>
            </SweetAlert>
            <SweetAlert
              show={this.state.showRejectAlert}
              title=""
              confirmBtnText="Continue"
              onConfirm={this.On_continue}
              onCancel={this.On_cancel}
              showCancel
              customClass="blue-background"
            >
              <div>
                <Row>
                  <Col sm="4">
                    <label>Give Reason :</label>
                  </Col>
                  <Col lg="6">
                    <textarea
                      name="rejectReason"
                      className="form-control"
                      value={
                        this.state.rejectReason ? this.state.rejectReason : ""
                      }
                      onChange={this.handleInputChange}
                      rows={8}
                      cols={50}
                    />
                  </Col>
                </Row>
              </div>
            </SweetAlert>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(Dashboard);
