import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";

class pageList_AMCMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    };
    this.obj = this;
    this.breadCrumbTitle = "AMC List";
    this.breadCrumbItem = "AMC List";
    this.modalTitle = "Country Details";
    this.rtPage_Add = "amcmaster";
    this.rtPage_Edit = "amcmaster";
    this.API_URL = "Billing/GetBill/0";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
  }
  componentDidMount() {
    console.log("this is obj ", this.obj);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    // Fn_FillListData(this.obj, "gridData", this.API_URL + "/F_CustomerMaster/"+obj.uid);
    Fn_FillListData(this.obj, "gridData", this.API_URL);
  }
  btnAdd_onClick(event, values) {
    // console.log(this.state.gridData);
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>Company Name</th>
        <th>Product Name</th>
        <th>Amount</th>
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td>{formData.CompanyName}</td>
        <td>{formData.ProductName}</td>
        <td>{formData.amount}</td>
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
          Company Name :{" "}
          <span className="text-primary">{selectedFormData.CompanyName}</span>
        </p>
        <p className="mb-4">
          Product Name :{" "}
          <span className="text-primary">{selectedFormData.ProductName}</span>
        </p>
        <p className="mb-4">
          Amount :{" "}
          <span className="text-primary">{selectedFormData.amount}</span>
        </p>
        <p className="mb-4">
          Branch :{" "}
          <span className="text-primary">{selectedFormData.branch}</span>
        </p>
        <p className="mb-4">
          Periodicity :{" "}
          <span className="text-primary">{selectedFormData.periodicity}</span>
        </p>
      </>
    );
  }
  render() {
    return (
      <div className="page-content">
        <RCDisplayPage
          //page header paramaters
          Isbreadcrumb={true}
          breadCrumbTitle={this.breadCrumbTitle}
          breadcrumbItem={this.breadCrumbItem}
          obj={this.obj}
          //column paramaters
          isSearchBox={true}
          isSNo={true}
          isCheckBox={true}
          isViewDetails={true}
          //grid paramaters
          gridData={this.state.gridData}
          gridHeader={this.renderGridHeader}
          gridBody={this.renderGridBody}
          btnAdd_onClick={this.btnAdd_onClick}
          btnEdit_onClick={this.btnEdit_onClick}
          //delete link parameters
          confirm_alert={this.state.confirm_alert}
          success_dlg={this.state.success_dlg}
          dynamic_title={this.state.dynamic_title}
          dynamic_description={this.state.dynamic_description}
          toggleDeleteConfirm={toggleDeleteConfirm}
          toggleDeleteSuccess={toggleDeleteSuccess}
          btnDelete_onClick={this.btnDelete_onClick}
          //modal paramaters
          isOpenModal={this.state.modal}
          modalTitle={this.modalTitle}
          selectedFormData={this.state.selectedFormData}
          modalBody={this.renderModalBody}
          togglemodal={togglemodal}
          //user rights
          isAdd={true}
          isEdit2={true}
          isDelete={true}
        ></RCDisplayPage>
      </div>
    );
  }
}
export default compose(container)(pageList_AMCMaster);
