import { API_WEB_URLS } from "constants/constAPI";
import React, { Component } from "react";
import { compose } from "recompose";
import { container } from "store/Containers/cntCommon";
import { Fn_AddEditData, Fn_ChangeStateValue, Fn_FillListData } from "store/functions";
// import PDFViewer from "pdf-viewer-reactjs";

class ShowJobList extends Component {
  //CREATE STATE constructor
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      selectedDes: "",
      selectedOrg: "",
      designations: [], // Initialize designations state
      orgs: [], // Initialize organizations state
      updatedJobs: [], // Initialize updatedJobs state
    };
    this.obj = this
  }
  getData = async ()=>{

     let vformData = new FormData();
     //Information

     vformData.append("F_DesignationMaster", this.state.selectedDes);
     vformData.append(
       "F_OrganisationMaster",
       this.state.selectedOrg == "" ? 0 : this.state.selectedOrg
     );
     try {
       const response = await fetch(
         "http://testapi.shinewellsofttech.co.in/api/V1/GetJobList/0/token",
         {
           method: "POST",
           
           body: vformData
         }
       );
       if (!response.ok) {
         throw new Error("Failed to add/update country");
       }

       const responseData = await response.json();
       console.log(responseData.data.response);
       this.setState(
         {
           jobs: responseData.data.response,
           
         },
       )

          
     } catch (error) {
       console.error("Error:", error);
     }
  }
  componentDidMount() {
    
     Fn_FillListData(
       this.obj,
       "designations",
       API_WEB_URLS.MASTER + "/0/token/GetDesignation/Id/2"
     );
     Fn_FillListData(
       this.obj,
       "orgs",
       API_WEB_URLS.MASTER + "/0/token/Organisation/Id/0"
     );

  }



  render() {
    return (
      <div className="page-content">
        <div>
          <label htmlFor="selectOption">Select Designation :</label>
          <select
            id="selectOption"
            value={this.state.selectedDes}
            onChange={e => {
              Fn_ChangeStateValue(
                this.obj,
                "selectedDes",
                e.target.value,
                this.getData
              );
            }}
            className="form-select"
          >
            <option value={0}>Select...</option>
            {this.state.designations
              ? this.state.designations.map((option, key) => (
                  <option
                    key={option.Id}
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </div>

        <div>
          <label htmlFor="selectOrg">Select Organization :</label>
          <select
            id="selectOrg"
            value={this.state.selectedOrg}
            onChange={e => {
              Fn_ChangeStateValue(
                this.obj,
                "selectedOrg",
                e.target.value,
                this.getData
              );
            }}
            className="form-select"
          >
            <option value={0}>Select...</option>
            {this.state.orgs
              ? this.state.orgs.map((option, key) => (
                  <option
                    key={option.Id}
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </div>
        <hr style={{ height: "10px", border: "none" }} />
        {this.state.jobs.map(job => (
          <div key={job.Id}>
            <p>Name: {job.FullName}</p>
            <p>Designation: {job.Designation}</p>
            <p>Organization: {job.Organisaton}</p>
            <hr className="test-hr" />
            {/* <PDFViewer
              document={{
                url: `${job.CV}`,
              }}
            /> */}
          </div>
        ))}
      </div>
    );
  }
}
export default compose(container)(ShowJobList);