import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
  Fn_BillingReceipt,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageAddEdit_BillPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: { },
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
      DueAmount : [],
      VoucherId: null,
      modal_fullscreen: false,
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Member Balance Report";
    this.API_URL_SAVE = "BillPayment/Billing";

    //Event Binding
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.printtest = this.printtest.bind(this);
    this.prin = this.prin.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
    Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  printtest() {
    var prevState = this.state.modal_fullscreen;
    this.state.VoucherId;
    this.setState(
      {
        modal_fullscreen: true,
      },
      () => {
        this.prin();
      }
    );
    this.removeBodyCss();
  }

  prin() {
    this.timer = setTimeout(() => {
      var reportviewerObj = $("#reportviewer-container").data(
        "boldReportViewer"
      );
      reportviewerObj.print();
    }, 1000);
  }
  handleCompanyChange(option) {
    console.log(option.value);
    console.log(option.label);

    let companyId = option.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ["F_company"]: option.value,
      },
    }));
    Fn_FillListData(
      this.obj,
      "products",
      "Product/GetProductByCompany/" + companyId
    );
  }
  handleProductChange(item) {
    console.log(item.value);

    let obj = this.state.formData;
  this.setState(prevState => ({
    formData: {
      ...prevState.formData,
      ["F_product"]: item.value,
    },
  }))
    let vformData = new FormData();

    vformData.append("F_company", this.state.formData.F_company);
    vformData.append("F_product", item.value);
    Fn_GetReport(
      this.obj,
      {
        arguList: {
          id: 0,
          formData: vformData,
        },
      },
      "BillPayment/GetDueByAMC",
      "DueAmount",
      true
    );
   
  }
  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    vformData.append("F_AMCId", this.state.formData.amcId);
    vformData.append("amount", this.state.formData.amount);

   Fn_BillingReceipt(
     this.obj,
     { arguList: { id: 0, formData: vformData } },
     this.API_URL_SAVE,
     "#",
     true,
     "VoucherId",
     this.printtest
   );
   console.log(this.state.VoucherId);
  }

  render() {
    var parameters = [
      {
        name: "Id",
        values: [this.state.VoucherId],
      },
    ];
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.companies.length > 0 &&
                                          this.state.companies.map(item => ({
                                            value: item.Id,
                                            label: item.Name,
                                          }))
                                        }
                                        onChange={this.handleCompanyChange}
                                        placeholder="Search or select Company"
                                        isSearchable
                                      />
                                    </Col>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Products
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.products.length > 0 &&
                                          this.state.products.map(item => ({
                                            value: item.Id,
                                            label: item.name,
                                          }))
                                        }
                                        onChange={this.handleProductChange}
                                        placeholder="Search or select Company"
                                        isSearchable
                                      />
                                    </Col>
                                  </Row>
                                  {this.state.DueAmount.length > 0 && (
                                    <Row>
                                      <h5 style={{ color: "red" }}>
                                        Due Amount :{" "}
                                        {this.state.DueAmount[0].DueAmount}
                                      </h5>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="amount"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Amount
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="amount"
                                        label=""
                                        value={this.state.formData.amount}
                                        onChange={this.handleFormChange}
                                        placeholder="Amount"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                          <Modal
                            size="xl"
                            isOpen={this.state.modal_fullscreen}
                            toggle={this.tog_fullscreen}
                            className="modal-fullscreen"
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title mt-0"
                                id="exampleModalFullscreenLabel"
                              >
                                Print
                              </h5>
                              <button
                                onClick={() =>
                                  this.setState({ modal_fullscreen: false })
                                }
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body" id="reportviewer">
                              {/* <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "http://shinewellsofttech.co.in:50693/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "http://shinewellsofttech.co.in:50693/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Invoixe/WithdrawalReciept"}
                                parameters={parameters}
                              ></BoldReportViewerComponent> */}
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                onClick={this.tog_fullscreen}
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageAddEdit_BillPayment);
