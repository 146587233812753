import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";

export const DateString = (dd) =>{
let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');
return d2;
}





class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {
        IsActive : true
      },
      dis : true,
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1 : '',
      BrowseImage2 : '',
      UserImage : '',
      Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      MembershipType  : 0,
      currentDate: this.getCurrentDate(),
      duplicate : false,
      edit : false,
      DateOfJoining : new Date().getFullYear() + '-0'+ (new Date().getMonth()+1).toString() + '-' + new Date().getDate(),
      MemNom : ''
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Member Form";
    this.breadCrumbTitle = "Member Form";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/MemberEdit";
    this.API_URL_SAVE = API_WEB_URLS.MEMBER + "/0/token";
    this.pushFormName = "/memberships";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.printInvoice =  this.printInvoice.bind(this);
    this.syno =  this.syno.bind(this);
    this.reset  =  this.reset.bind(this);
    this.logout  =  this.logout.bind(this);

  }



  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  componentDidMount() {

    
    
    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", this.breadCrumbItem)

    
// if (obj.roleid == 1) {
//   Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/2");
// }
// else {
  Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/8");
//}

    //Filling DropDowns
  
    Fn_FillListData(this.obj, "maritalStatus", API_WEB_URLS.MASTER + "/0/token/MaritalStatus/Id/0");
    Fn_FillListData(this.obj, "bloodGroup", API_WEB_URLS.MASTER + "/0/token/BloodGroup/Id/0");
    Fn_FillListData(this.obj, "gender", API_WEB_URLS.MASTER + "/0/token/Gender/Id/0");
    Fn_FillListData(this.obj, "education", API_WEB_URLS.MASTER + "/0/token/Education/Id/0");
    Fn_FillListData(this.obj, "profession", API_WEB_URLS.MASTER + "/0/token/Profession/Id/0");
    Fn_FillListData(this.obj, "relationShip", API_WEB_URLS.MASTER + "/0/token/Relationship/Id/0");
    Fn_FillListData(this.obj, "nationality", API_WEB_URLS.MASTER + "/0/token/Country/Id/0");
    Fn_FillListData(this.obj, "typeofRegCert", API_WEB_URLS.MASTER + "/0/token/IDDocumentType/Id/0");
    Fn_FillListData(this.obj, "MISScheme", API_WEB_URLS.MASTER + "/0/token/MISScheme/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
      Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
      this.setState({ dis: false , edit:true});

    } else {
      this.setState({ id: 0 ,   formData: {
        IsActive : 'True'
      }, });
    }
  }




DOBChange = (event)=> {

  var birth  =  new Date(event.target.value);
   birth = birth.getFullYear();

    var tod =  new Date();

    tod =  tod.getFullYear();

    var age = tod - birth;

    

    this.setState({ fo: age });




    if ((age <17 && this.state.MembershipType == 3)  || (age>85 && this.state.MembershipType == 3 )) {
      alert("Excempt Member can't be " + age + " It should be under 17 or older than 85.");
    }


if (age > 0 && age<=15){
  this.setState({ F_MISScheme: 5 });
}

else if (age>15 && age<= 20){
  this.setState({ F_MISScheme: 4 });
}


else if (age>20 && age<= 35){
  this.setState({ F_MISScheme: 3 });
}


else if (age>35 && age<= 50){
  this.setState({ F_MISScheme: 2 });
}


else if (age>50 ){
  this.setState({ F_MISScheme: 1 });
}



  
}


checkfemale =  (event) =>{

  var Gender =  event.target.value;

  if(Gender == 2) {
  this.setState({ Gender: Gender });

  var MaritalStatus  =  this.state.MaritalStatus;

  if (MaritalStatus == 1 || MaritalStatus == 3 || MaritalStatus  ==  4 || MaritalStatus == 5 ){
    this.setState({ fo3: true });
  }


  else {
    this.setState({ fo3: false });
  }

  }


  else {
    this.setState({ fo3: false });
  }


}


checkMarital  =  (event) => {
  var MaritalStatus =  event.target.value;
  this.setState({ MaritalStatus: MaritalStatus });

  if (this.state.Gender == 2) {

  if (MaritalStatus == 1 || MaritalStatus == 3 || MaritalStatus  ==  4 || MaritalStatus == 5 ){
    this.setState({ fo3: true });
  }

  else {
    this.setState({ fo3: false });
  }


  }

  else {
    this.setState({ fo3: false });
  }


}



NomineeDOBChange = (event)=> {

  var birth  =  new Date(event.target.value);
   birth = birth.getFullYear();

    var tod =  new Date();

    tod =  tod.getFullYear();

    var age = tod - birth;
    this.setState({ fo2: age });
  
}


membershiptype  =  (event)=> {

var MembershipType  =  event.target.value;

this.setState({ MembershipType: MembershipType });

}

FN_AGEGET =  (event) =>{
  
}


  btnSave_onClick(event, formData) {

    
   
 



    var F_MISScheme
   if(formData.F_MISScheme > 0){
     F_MISScheme =  formData.F_MISScheme;
   }

   else{
F_MISScheme  =  this.state.F_MISScheme;
   }
   
    


 

    var birth  =  new Date(formData.DateOfBirth);
    birth = birth.getFullYear();
 
     var tod =  new Date();
 
     tod =  tod.getFullYear();
 
     var age = tod - birth;




     var Nbirth  =  new Date(formData.DateOfBirth_Nominee);
     Nbirth = Nbirth.getFullYear();
  
      var Ntod =  new Date();
  
      Ntod =  Ntod.getFullYear();
  
      var Nage = Ntod - Nbirth;
 
     
 var Mobile =  formData.MobileNo_SMS;

 var Mobile  =  Mobile.substring(Mobile.indexOf("-") + 1);





    let vformData = new FormData();
    //Information
    vformData.append("MembershipTypeId", 8);
    vformData.append("DateOfJoining",DateString(this.state.DateOfJoining));
    vformData.append("FirstName", formData.FirstName);
    vformData.append("MiddleName", formData.MiddleName);
    vformData.append("SurName", formData.SurName);
    vformData.append("MaidenName", formData.MaidenName);
    vformData.append("DateOfBirth", DateString(formData.DateOfBirth));
    vformData.append("Age", age);
    vformData.append("MaritalStatusId", formData.MaritalStatusId);
    vformData.append("BloodGroupId", formData.BloodGroupId);
    vformData.append("GenderId", formData.GenderId);
    vformData.append("CountryId", formData.CountryId);
    vformData.append("HomeTown", formData.HomeTown);
    vformData.append("EducationId", formData.EducationId);
    vformData.append("ProfessionId", formData.ProfessionId);
    vformData.append("Email", formData.Email);
    vformData.append("MobileNo_SMS", Mobile);
    vformData.append("SpouseName", formData.SpouseName);
    vformData.append("MemberId_Spouse", formData.MemberId_Spouse);


    //Address
    vformData.append("Address_Residential", formData.Address_Residential);
    vformData.append("Pincode_Residential", formData.Pincode_Residential);
    vformData.append("MobileNo1_Residential", formData.MobileNo1_Residential);
    vformData.append("MobileNo2_Residential", formData.MobileNo2_Residential);
    vformData.append("PhoneNo1_Residential", formData.PhoneNo1_Residential);
    vformData.append("PhoneNo2_Residential", formData.PhoneNo2_Residential);
    vformData.append("Address_Business", formData.Address_Business);
    vformData.append("Pincode_Business", formData.Pincode_Business);
    vformData.append("MobileNo1_Business", formData.MobileNo1_Business);
    vformData.append("MobileNo2_Business", formData.MobileNo2_Business);
    vformData.append("PhoneNo1_Business", formData.PhoneNo1_Business);
    vformData.append("PhoneNo2_Business", formData.PhoneNo2_Business);

    //Introducer Information
    vformData.append("MemberId_Introducer", 0);
    vformData.append("RelationshipId_Introducer", 0);
    vformData.append("MemberId_Agent", 0);


    //Father
    vformData.append("FirstName_Father", formData.FirstName_Father);
    vformData.append("MiddleName_Father", formData.MiddleName_Father);
    vformData.append("SurName_Father", formData.SurName_Father);
    vformData.append("HomeTown_Father", formData.HomeTown_Father);
    vformData.append("CountryId_Father", formData.CountryId_Father);
    vformData.append("IsLive_Father", formData.IsLive_Father);


    //Mother
    vformData.append("FirstName_Mother", formData.FirstName_Mother);
    vformData.append("MiddleName_Mother", formData.MiddleName_Mother);
    vformData.append("SurName_Mother", formData.SurName_Mother);
    vformData.append("CountryId_Mother", formData.CountryId_Mother);
    vformData.append("IsLive_Mother", formData.IsLive_Mother);
    vformData.append("HomeTown_Mother", formData.HomeTown_Mother);


    //Nominee
    vformData.append("FirstName_Nominee", formData.FirstName_Nominee);
    vformData.append("MiddleName_Nominee", formData.MiddleName_Nominee);
    vformData.append("SurName_Nominee", formData.SurName_Nominee);
    vformData.append("DateOfBirth_Nominee", DateString(formData.DateOfBirth_Nominee));
    vformData.append("Age_Nominee", Nage);
    vformData.append("GenderId_Nominee", formData.GenderId_Nominee);
    vformData.append("RelationshipId_Nominee", formData.RelationshipId_Nominee);

    //Document Upload 
    vformData.append("IDDocumentTypeId_1", formData.IDDocumentTypeId_1);
    vformData.append("IDDocumentTypeId_2", formData.IDDocumentTypeId_2);
    vformData.append("ID_DocNo_1", formData.ID_DocNo_1);
    vformData.append("ID_ImageURL_1.ImageFileName", "dd");
    vformData.append("ID_ImageURL_1.ImageFile", this.state.BrowseImage1);
    vformData.append("ID_DocNo_2", formData.ID_DocNo_2);
    vformData.append("ID_ImageURL_2.ImageFileName", "dd");
    vformData.append("ID_ImageURL_2.ImageFile", this.state.BrowseImage2);
    vformData.append("ImageURL_Member.ImageFileName", "dd");
    vformData.append("ImageURL_Member.ImageFile", this.state.UserImage);
    vformData.append("ImageURL_Signature.ImageFileName", "dd");
    vformData.append("ImageURL_Signature.ImageFile", this.state.Signature);

    //MIS
    vformData.append("F_MISScheme", 0);
    vformData.append("MISAmount", 0);

    

    vformData.append("MembershipFees", 0);
    
    vformData.append("ShareValue", 0);
    
    vformData.append("NoOfShares", 0);
    vformData.append("IsActive", 1);
    vformData.append("F_AgentMaster", 0);
    vformData.append("Phone1_Nominee", formData.PhoneNo1_Nominee);
    vformData.append("Phone2_Nominee", formData.PhoneNo2_Nominee);
    vformData.append("PostalCode_Nominee", formData.PostalCode_Nominee);
    vformData.append("Address_Nominee", formData.Address_Nominee);


    if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true , "memberid");
    }
    // else {
    // if (this.state.formData.IsApproved == false || this.state.formData.IsApproved == 'False'){
    //   this.pushFormName  = "/unapproved"
    // }
    
    //   Fn_AddEditData(this.obj, { arguList: { id: this.state.id, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "kuku");
   
   
    // }


      
  }




  















  logout() {
    localStorage.removeItem("authUser");


    this.props.history.push('/login', {});

  }


  syno () {

    this.setState({
      success_msg : false, duplicate : false
    });
        //event.preventDefault();
        this.props.history.push('login', {});

      };


      reset () {


        this.setState({
          formData : {
            MembershipTypeId: 0,
            DateOfJoining : new Date().getFullYear() + '-0'+ (new Date().getMonth()+1).toString() + '-' + new Date().getDate(),
            FirstName : '',
            MiddleName : '',
            SurName : '',
            MaidenName : '',
            DateOfBirth : '',
            Age : '',
            MaritalStatusId : -1,
            BloodGroupId : -1,
            GenderId : -1,
            CountryId :  -1,
            HomeTown : '',
            EducationId : -1,
            ProfessionId : -1,
            Email : '',
            MobileNo_SMS : '',
            SpouseName : '',
            MemberId_Spouse : '',
            Address_Residential : '',
            Pincode_Residential : '',
            MobileNo1_Residential : '',
            MobileNo2_Residential : '',
            PhoneNo1_Residential : '',
            PhoneNo2_Residential : '',
            Address_Business : '',
            Pincode_Business : '',
            MobileNo1_Business : '',
            MobileNo2_Business : '',
            PhoneNo1_Business : '',
            PhoneNo2_Business : '',
            Member_Introducer : '',
            MemberId_Introducer : '',
            RelationshipId_Introducer : '',
            FirstName_Father : '',
            MiddleName_Father : '',
            SurName_Father : '',
            HomeTown_Father : '',
            CountryId_Father : -1,
            IsLive_Father : -1,
            FirstName_Nominee : '',
            MiddleName_Nominee : '',
            SurName_Nominee : '',
            Phone1_Nominee : '',
            Phone2_Nominee : '',
            PostalCode_Nominee : '',
            Address_Nominee : '',
            DateOfBirth_Nominee : '',
            Age_Nominee : '',
            GenderId_Nominee : -1,
            RelationshipId_Nominee : -1,
            FirstName_Mother : '',
            MiddleName_Mother : '',
            SurName_Mother : '',
            HomeTown_Mother : '',
            CountryId_Mother : -1,
            IsLive_Mother : -1,
            Remarks : ''
          }
        });

       const obj = JSON.parse(localStorage.getItem("authUser"));
       this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
      }


  
  printInvoice ()  {
  
    this.props.history.push(`/membersynopsisprint/${this.state.id}`, {});
  };
  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }
  

  render() {



   

    const options = [
      { value: "AK", label: "Alaska" },
      { value: "HI", label: "Hawaii" },
      { value: "CA", label: "California" },
      { value: "NV", label: "Nevada" },
      { value: "OR", label: "Oregon" },
      { value: "WA", label: "Washington" },
    ];
    return (
      <React.Fragment>
        <div className="page-content" style={{ marginTop: "0px" }}>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul style={{ backgroundColor: "#e7e1e100" }}>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            ></NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                            ></NavLink>
                          </NavItem>

                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 3,
                              })}
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                            ></NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 4,
                              })}
                              onClick={() => {
                                this.toggleTab(4);
                              }}
                            ></NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                          ref={c => (this.form = c)}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">
                                        Information
                                      </h4>

                                      <Row>
                                        <Col sm="4">
                                          <label
                                            htmlFor="dateOfJoining"
                                            className="col-form-label"
                                          >
                                            Date of Joining
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField
                                            disabled
                                            name="DateOfJoining"
                                            label=""
                                            value={this.state.currentDate}
                                            placeholder="Enter Date of Joining"
                                            errorMessage="Enter Date of Joining"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="date"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label
                                            htmlFor="isactive"
                                            className="col-form-label"
                                          >
                                            Is Active{" "}
                                          </label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField
                                            name="IsActive"
                                            label=""
                                            checked={
                                              this.state.formData.IsActive ===
                                              "True"
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            className="form-control"
                                            disabled={this.state.dis}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="firstName"
                                            className="col-form-label"
                                          >
                                            First Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="FirstName"
                                            label=""
                                            value={
                                              this.state.formData.FirstName ===
                                              null
                                                ? ""
                                                : this.state.formData.FirstName
                                            }
                                            placeholder="Enter First Name"
                                            errorMessage="Enter First Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="middleName"
                                            className="col-form-label"
                                          >
                                            Middle Name
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MiddleName"
                                            label=""
                                            value={
                                              this.state.formData.MiddleName ===
                                              null
                                                ? ""
                                                : this.state.formData.MiddleName
                                            }
                                            placeholder="Enter Middle Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="lastName"
                                            className="col-form-label"
                                          >
                                            Last Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="SurName"
                                            label=""
                                            value={
                                              this.state.formData.SurName ===
                                              null
                                                ? ""
                                                : this.state.formData.SurName
                                            }
                                            placeholder="Enter Last Name"
                                            errorMessage="Enter Last Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="maidenName"
                                            className="col-form-label"
                                          >
                                            Maiden name
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MaidenName"
                                            label=""
                                            value={
                                              this.state.formData.MaidenName ===
                                              null
                                                ? ""
                                                : this.state.formData.MaidenName
                                            }
                                            placeholder="Enter Maiden Name"
                                            errorMessage="Enter Maiden Name"
                                            validate={{
                                              required: {
                                                value: this.state.fo3,
                                              },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="DateofBirth"
                                            className="col-form-label"
                                          >
                                            Date of Birth
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField
                                            name="DateOfBirth"
                                            onChange={this.DOBChange}
                                            label=""
                                            value={
                                              this.state.formData
                                                .DateOfBirth === null
                                                ? ""
                                                : this.state.formData
                                                    .DateOfBirth
                                            }
                                            placeholder="Enter Date of Birth"
                                            errorMessage="Enter Date of Birth"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="date"
                                            className="form-control"
                                          />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label
                                            htmlFor="age"
                                            className="col-form-label"
                                          >
                                            Age
                                          </label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField
                                            name="Age"
                                            label=""
                                            value={
                                              this.state.fo === undefined
                                                ? this.state.formData.Age
                                                : this.state.fo
                                            }
                                            placeholder=""
                                            errorMessage="Enter Age"
                                            type="text"
                                            disabled
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="maritalStatus"
                                            className="col-form-label"
                                          >
                                            Marital Status
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MaritalStatusId"
                                            onChange={this.checkMarital}
                                            label=""
                                            value={
                                              this.state.formData
                                                .MaritalStatusId === null
                                                ? "-1"
                                                : this.state.formData
                                                    .MaritalStatusId
                                            }
                                            errorMessage="Select Marital Status Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.maritalStatus
                                              ? this.state.maritalStatus.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="bloodGroup"
                                            className="col-form-label"
                                          >
                                            Blood Group
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="BloodGroupId"
                                            label=""
                                            value={
                                              this.state.formData
                                                .BloodGroupId === null
                                                ? "-1"
                                                : this.state.formData
                                                    .BloodGroupId
                                            }
                                            errorMessage="Select Blood group"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.bloodGroup
                                              ? this.state.bloodGroup.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="gender"
                                            className="col-form-label"
                                          >
                                            Gender
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            onChange={this.checkfemale}
                                            name="GenderId"
                                            label=""
                                            value={
                                              this.state.formData.GenderId ===
                                              null
                                                ? "-1"
                                                : this.state.formData.GenderId
                                            }
                                            errorMessage="Select Gender Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.gender
                                              ? this.state.gender.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="country"
                                            className="col-form-label"
                                          >
                                            Country
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="CountryId"
                                            label=""
                                            value={
                                              this.state.formData.CountryId ===
                                              null
                                                ? "-1"
                                                : this.state.formData.CountryId
                                            }
                                            errorMessage="Select Country"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.nationality
                                              ? this.state.nationality.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="homeTown"
                                            className="col-form-label"
                                          >
                                            Hometown
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="HomeTown"
                                            label=""
                                            value={
                                              this.state.formData.HomeTown ===
                                              null
                                                ? ""
                                                : this.state.formData.HomeTown
                                            }
                                            placeholder="Enter Hometown"
                                            errorMessage="Enter Hometown"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="education"
                                            className="col-form-label"
                                          >
                                            Education
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="EducationId"
                                            label=""
                                            value={
                                              this.state.formData
                                                .EducationId === null
                                                ? "-1"
                                                : this.state.formData
                                                    .EducationId
                                            }
                                            errorMessage="Select Education Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.education
                                              ? this.state.education.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="profession"
                                            className="col-form-label"
                                          >
                                            Profession
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="ProfessionId"
                                            label=""
                                            value={
                                              this.state.formData
                                                .ProfessionId === null
                                                ? "-1"
                                                : this.state.formData
                                                    .ProfessionId
                                            }
                                            errorMessage="Select Profession Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.profession
                                              ? this.state.profession.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Email"
                                            className="col-form-label"
                                          >
                                            Email
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Email"
                                            label=""
                                            value={
                                              this.state.formData.Email === null
                                                ? ""
                                                : this.state.formData.Email
                                            }
                                            placeholder="Enter Email"
                                            type="email"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Phone"
                                            className="col-form-label"
                                          >
                                            Phone
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            validate={{
                                              required: { value: true },
                                            }}
                                            name="MobileNo_SMS"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MobileNo_SMS === null
                                                ? ""
                                                : this.state.formData
                                                    .MobileNo_SMS
                                            }
                                            placeholder="Enter Phone"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="nameOfSpouse"
                                            className="col-form-label"
                                          >
                                            Name of Spouse
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="SpouseName"
                                            label=""
                                            value={
                                              this.state.formData.SpouseName ===
                                              null
                                                ? ""
                                                : this.state.formData.SpouseName
                                            }
                                            placeholder="Enter Name of Spouse"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="spouseMemNo"
                                            className="col-form-label"
                                          >
                                            Spouse Mem. No.
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MemberId_Spouse"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MemberId_Spouse === null
                                                ? ""
                                                : this.state.formData
                                                    .MemberId_Spouse
                                            }
                                            placeholder="Enter Spouse Mem. No."
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">
                                        Address
                                      </h4>
                                      <Row>
                                        <Col lg="12">
                                          <Label for="basicpill-address-input1">
                                            Residential Address
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="basicpill-address-input1"
                                            className="col-form-label"
                                          >
                                            Full Address
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          {/* <textarea
                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="2"
                                            placeholder="Enter Your Address"
                                          /> */}

                                          <AvField
                                            name="Address_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .Address_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .Address_Residential
                                            }
                                            placeholder="Enter Full Address"
                                            errorMessage="Enter Full Address"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="postCode"
                                            className="col-form-label"
                                          >
                                            Post Code
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Pincode_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .Pincode_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .Pincode_Residential
                                            }
                                            placeholder="Enter Post Code"
                                            errorMessage="Enter Post Code"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="mobilePhone1"
                                            className="col-form-label"
                                          >
                                            Mobile Phone 1
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MobileNo1_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MobileNo1_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .MobileNo1_Residential
                                            }
                                            placeholder="Enter Mobile Phone 1"
                                            errorMessage="Enter Mobile Phone1"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="mobilePhone2"
                                            className="col-form-label"
                                          >
                                            Mobile Phone 2
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MobileNo2_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MobileNo2_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .MobileNo2_Residential
                                            }
                                            placeholder="Enter Mobile Phone 2"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="telephone1"
                                            className="col-form-label"
                                          >
                                            Telephone 1
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PhoneNo1_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo1_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo1_Residential
                                            }
                                            placeholder="Enter Telephone 1"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="telephone2"
                                            className="col-form-label"
                                          >
                                            Telephone 2
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PhoneNo2_Residential"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo2_Residential === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo2_Residential
                                            }
                                            placeholder="Enter Telephone 2"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="6">
                                          <Label for="basicpill-lastname-input2">
                                            Business Address
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="basicpill-address-input1"
                                            className="col-form-label"
                                          >
                                            Full Address
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          {/* <textarea 
                                              id="basicpill-address-input1"
                                              className="form-control"
                                              rows="2"
                                              placeholder="Enter Your Address"
                                            /> */}

                                          <AvField
                                            name="Address_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .Address_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .Address_Business
                                            }
                                            placeholder="Enter Full Address"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Pincode_Business"
                                            className="col-form-label"
                                          >
                                            Post Code
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Pincode_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .Pincode_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .Pincode_Business
                                            }
                                            placeholder="Enter Post Code"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="MobileNo1_Business"
                                            className="col-form-label"
                                          >
                                            Mobile Phone 1
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MobileNo1_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MobileNo1_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .MobileNo1_Business
                                            }
                                            placeholder="Enter Mobile Phone 1"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="MobileNo2_Business"
                                            className="col-form-label"
                                          >
                                            Mobile Phone 2
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MobileNo2_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MobileNo2_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .MobileNo2_Business
                                            }
                                            placeholder="Enter Mobile Phone 2"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="PhoneNo1_Business"
                                            className="col-form-label"
                                          >
                                            Telephone 1
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PhoneNo1_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo1_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo1_Business
                                            }
                                            placeholder="Enter Telephone 1"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="PhoneNo2_Business"
                                            className="col-form-label"
                                          >
                                            Telephone 2
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PhoneNo2_Business"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo2_Business === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo2_Business
                                            }
                                            placeholder="Enter Telephone 2"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row></Row>
                              {/* <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Introducer Information</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="Member_Introducer" className="col-form-label">Introducer Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="Member_Introducer" label="" value={this.state.formData.Member_Introducer === null ? ''   : this.state.formData.Member_Introducer} placeholder="Enter Introducer Name"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Introducer No.</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MemberId_Introducer" label="" value={this.state.formData.MemberId_Introducer === null ? ''   : this.state.formData.MemberId_Introducer} placeholder="Enter Introducer No."   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="relationship" className="col-form-label">RelationShip</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="RelationshipId_Introducer" label="" value={this.state.formData.RelationshipId_Introducer === null ? '-1'   : this.state.formData.RelationshipId_Introducer}  type="select" className="form-select" >
                                              <option value={-1} defaultValue label={"Select"} />
                                              {this.state.relationShip
                                                ? this.state.relationShip.map(
                                                    (option, key) => (
                                                      <option key={option.Id} value={option.Id} label={option.Name} />
                                                    )
                                                  )
                                                : null}
                                            </AvField>
                                          </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  
                                </Row> */}
                            </TabPane>
                            <TabPane tabId={2}>
                              <Row>
                                <Col lg="4">
                                  <Card>
                                    <CardBody style={{ padding: 0 }}>
                                      <h4 className="card-title mb-4">
                                        Father&apos;s Information
                                      </h4>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="FirstName_Father"
                                            className="col-form-label"
                                          >
                                            First Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="FirstName_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .FirstName_Father === null
                                                ? ""
                                                : this.state.formData
                                                    .FirstName_Father
                                            }
                                            placeholder="Enter First Name"
                                            errorMessage="Enter First Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="MiddleName_Father"
                                            className="col-form-label"
                                          >
                                            Middle Name
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="MiddleName_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MiddleName_Father === null
                                                ? ""
                                                : this.state.formData
                                                    .MiddleName_Father
                                            }
                                            placeholder="Enter Middle Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Father"
                                            className="col-form-label"
                                          >
                                            Last Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="SurName_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .SurName_Father === null
                                                ? ""
                                                : this.state.formData
                                                    .SurName_Father
                                            }
                                            placeholder="Enter Last Name"
                                            errorMessage="Enter Last Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="HomeTown_Father"
                                            className="col-form-label"
                                          >
                                            Hometown
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="HomeTown_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .HomeTown_Father === null
                                                ? ""
                                                : this.state.formData
                                                    .HomeTown_Father
                                            }
                                            placeholder="Enter Hometown"
                                            errorMessage="Enter Hometown"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="CountryId_Father"
                                            className="col-form-label"
                                          >
                                            Nationality
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="CountryId_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .CountryId_Father === null
                                                ? "-1"
                                                : this.state.formData
                                                    .CountryId_Father
                                            }
                                            errorMessage="Select Nationality"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.nationality
                                              ? this.state.nationality.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="IsLive_Father"
                                            className="col-form-label"
                                          >
                                            Father&apos;s Status
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="IsLive_Father"
                                            label=""
                                            value={
                                              this.state.formData
                                                .IsLive_Father === null
                                                ? "-1"
                                                : this.state.formData
                                                    .IsLive_Father
                                            }
                                            errorMessage="Select Status"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            <option value={0} label={"Alive"} />
                                            <option value={1} label={"Dead"} />
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="4">
                                  <Card>
                                    <CardBody style={{ padding: 0 }}>
                                      <h4 className="card-title mb-4">
                                        Mother&apos;s Information
                                      </h4>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="FirstName_Mother"
                                            className="col-form-label"
                                          >
                                            First Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="FirstName_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .FirstName_Mother === null
                                                ? ""
                                                : this.state.formData
                                                    .FirstName_Mother
                                            }
                                            placeholder="Enter First Name"
                                            errorMessage="Enter First Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="MiddleName_Mother"
                                            className="col-form-label"
                                          >
                                            Middle Name
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="MiddleName_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MiddleName_Mother === null
                                                ? ""
                                                : this.state.formData
                                                    .MiddleName_Mother
                                            }
                                            placeholder="Enter Middle Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Mother"
                                            className="col-form-label"
                                          >
                                            Last Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="SurName_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .SurName_Mother === null
                                                ? ""
                                                : this.state.formData
                                                    .SurName_Mother
                                            }
                                            placeholder="Enter Last Name"
                                            errorMessage="Enter Last Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="HomeTown_Mother"
                                            className="col-form-label"
                                          >
                                            Hometown
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="HomeTown_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .HomeTown_Mother === null
                                                ? ""
                                                : this.state.formData
                                                    .HomeTown_Mother
                                            }
                                            placeholder="Enter Hometown"
                                            errorMessage="Enter Hometown"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="CountryId_Mother"
                                            className="col-form-label"
                                          >
                                            Nationality
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="CountryId_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .CountryId_Mother === null
                                                ? "-1"
                                                : this.state.formData
                                                    .CountryId_Mother
                                            }
                                            errorMessage="Select Nationality"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.nationality
                                              ? this.state.nationality.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="IsLive_Mother"
                                            className="col-form-label"
                                          >
                                            Mother&apos;s Status
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="IsLive_Mother"
                                            label=""
                                            value={
                                              this.state.formData
                                                .IsLive_Mother === null
                                                ? "-1"
                                                : this.state.formData
                                                    .IsLive_Mother
                                            }
                                            errorMessage="Select Status"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            <option value={0} label={"Alive"} />
                                            <option value={1} label={"Dead"} />
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="4">
                                  <Card>
                                    <CardBody style={{ padding: 0 }}>
                                      <h4 className="card-title mb-4">
                                        Nominee&apos;s Information
                                      </h4>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="FirstName_Nominee"
                                            className="col-form-label"
                                          >
                                            First Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="FirstName_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .FirstName_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .FirstName_Nominee
                                            }
                                            placeholder="Enter First Name"
                                            errorMessage="Enter First Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="MiddleName_Nominee"
                                            className="col-form-label"
                                          >
                                            Middle Name
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="MiddleName_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .MiddleName_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .MiddleName_Nominee
                                            }
                                            placeholder="Enter Middle Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Nominee"
                                            className="col-form-label"
                                          >
                                            Last Name
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="SurName_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .SurName_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .SurName_Nominee
                                            }
                                            placeholder="Enter Last Name"
                                            errorMessage="Enter Last Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="DateOfBirth_Nominee"
                                            className="col-form-label"
                                          >
                                            Date of Birth
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="DateOfBirth_Nominee"
                                            onChange={this.NomineeDOBChange}
                                            label=""
                                            value={
                                              this.state.formData
                                                .DateOfBirth_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .DateOfBirth_Nominee
                                            }
                                            placeholder="Enter Date of Birth"
                                            errorMessage="Enter Date of Birth"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="date"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="Age_Nominee"
                                            className="col-form-label"
                                          >
                                            Age
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="Age"
                                            label=""
                                            value={
                                              this.state.fo2 === undefined
                                                ? this.state.formData
                                                    .Age_Nominee
                                                : this.state.fo2
                                            }
                                            placeholder=""
                                            errorMessage="Enter Age"
                                            type="text"
                                            disabled
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="GenderId_Nominee"
                                            className="col-form-label"
                                          >
                                            Gender
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="GenderId_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .GenderId_Nominee === null
                                                ? "-1"
                                                : this.state.formData
                                                    .GenderId_Nominee
                                            }
                                            errorMessage="Select Gender Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.gender
                                              ? this.state.gender.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="RelationshipId_Nominee"
                                            className="col-form-label"
                                          >
                                            RelationShip
                                          </label>
                                          <label style={{ color: "red" }}>
                                            *
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="RelationshipId_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .RelationshipId_Nominee === null
                                                ? "-1"
                                                : this.state.formData
                                                    .RelationshipId_Nominee
                                            }
                                            errorMessage="Select RelationShip"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.relationShip
                                              ? this.state.relationShip.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Nominee"
                                            className="col-form-label"
                                          >
                                            Phone1
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="PhoneNo1_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo1_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo1_Nominee
                                            }
                                            placeholder="Enter Phone1"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Nominee"
                                            className="col-form-label"
                                          >
                                            Phone2
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="PhoneNo2_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PhoneNo2_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .PhoneNo2_Nominee
                                            }
                                            placeholder="Enter Phone2"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Nominee"
                                            className="col-form-label"
                                          >
                                            Post Code
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="PostalCode_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PostalCode_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .PostalCode_Nominee
                                            }
                                            placeholder="Enter Postal Code"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="5" className="mb-3">
                                          <label
                                            htmlFor="SurName_Nominee"
                                            className="col-form-label"
                                          >
                                            Address
                                          </label>
                                        </Col>
                                        <Col sm="7">
                                          <AvField
                                            name="Address_Nominee"
                                            label=""
                                            value={
                                              this.state.formData
                                                .Address_Nominee === null
                                                ? ""
                                                : this.state.formData
                                                    .Address_Nominee
                                            }
                                            placeholder="Enter Address"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                {/* </Row>
                                  <Row> */}
                              </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                <Row>
                                  <Col lg="6">
                                    <Label for="basicpill-lastname-input2">
                                      Identity Confirmation
                                    </Label>
                                  </Col>
                                </Row>
                                <Row>
                                 
                                  <Col lg="4">
                                    {/* <label style={{ color: "red" }}>*</label> */}
                                    <AvField
                                      name="IDDocumentTypeId_1"
                                      label="Type of ID No. 1 *"
                                      value={
                                        this.state.formData
                                          .IDDocumentTypeId_1 === null
                                          ? "-1"
                                          : this.state.formData
                                              .IDDocumentTypeId_1
                                      }
                                      errorMessage="Enter Registration No"
                                      validate={{ required: { value: true } }}
                                      type="select"
                                      className="form-select"
                                    >
                                      <option
                                        value={-1}
                                        defaultValue
                                        label={"Select"}
                                      />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option
                                                key={option.Id}
                                                value={option.Id}
                                                label={option.Name}
                                              />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                  </Col>
                                  <Col lg="4">
                                    <AvField
                                      name="ID_DocNo_1"
                                      label="Registration No. *"
                                      value={
                                        this.state.formData.ID_DocNo_1 ===
                                        undefined
                                          ? ""
                                          : this.state.formData.ID_DocNo_1
                                      }
                                      placeholder="Enter Registration No."
                                      errorMessage="Enter Registration No"
                                      validate={{ required: { value: true } }}
                                      type="text"
                                      className="form-control"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <img
                                      height="150"
                                      className="rounded me-2"
                                      alt={"image"}
                                      width="200"
                                      src={
                                        this.state.formData
                                          .ID_ImageURL_1_Thumbnail
                                      }
                                    />
                                    <AvField
                                      name="BrowseImage1"
                                      label="Upload ID Copy No. 1 *"
                                      placeholder="Upload File"
                                      errorMessage="Upload File"
                                      onChange={e =>
                                        Fn_ChangeStateValue(
                                          this.obj,
                                          "BrowseImage1",
                                          e.target.files[0]
                                        )
                                      }
                                      validate={{ required: { value: true } }}
                                      accept=".gif,.jpg,.jpeg,.png"
                                      type="file"
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <AvField
                                      name="IDDocumentTypeId_2"
                                      label="Type of ID No. 2 *"
                                      value={
                                        this.state.formData
                                          .IDDocumentTypeId_2 === null
                                          ? "-1"
                                          : this.state.formData
                                              .IDDocumentTypeId_2
                                      }
                                      validate={{ required: { value: true } }}
                                      type="select"
                                      className="form-select"
                                    >
                                      <option
                                        value={-1}
                                        defaultValue
                                        label={"Select"}
                                      />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option
                                                key={option.Id}
                                                value={option.Id}
                                                label={option.Name}
                                              />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                  </Col>
                                  <Col lg="4">
                                    <AvField
                                      name="ID_DocNo_2"
                                      label="Registration No.*"
                                      value={
                                        this.state.formData.ID_DocNo_2 ===
                                        undefined
                                          ? ""
                                          : this.state.formData.ID_DocNo_2
                                      }
                                      validate={{ required: { value: true } }}
                                      placeholder="Enter Registration No."
                                      type="text"
                                      className="form-control"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <img
                                      height="150"
                                      className="rounded me-2"
                                      alt={"image"}
                                      width="200"
                                      src={
                                        this.state.formData
                                          .ID_ImageURL_2_Thumbnail
                                      }
                                    />
                                    <AvField
                                      name="BrowseImage2"
                                      label="Upload ID Copy No. 2 *"
                                      placeholder="Upload File"
                                      errorMessage="Upload File"
                                      onChange={e =>
                                        Fn_ChangeStateValue(
                                          this.obj,
                                          "BrowseImage2",
                                          e.target.files[0]
                                        )
                                      }
                                      validate={{ required: { value: true } }}
                                      accept=".gif,.jpg,.jpeg,.png"
                                      type="file"
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <img
                                      height="150"
                                      className="rounded me-2"
                                      alt={"image"}
                                      width="200"
                                      src={
                                        this.state.formData
                                          .ImageURL_Member_Thumbnail
                                      }
                                    />

                                    <AvField
                                      name="UserImage"
                                      label="Passport Photograph *"
                                      placeholder="Upload File"
                                      errorMessage="Upload File"
                                      onChange={e =>
                                        Fn_ChangeStateValue(
                                          this.obj,
                                          "UserImage",
                                          e.target.files[0]
                                        )
                                      }
                                      validate={{ required: { value: true } }}
                                      accept=".gif,.jpg,.jpeg,.png"
                                      type="file"
                                      className="form-control"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <img
                                      height="150"
                                      className="rounded me-2"
                                      alt={"image"}
                                      width="200"
                                      src={
                                        this.state.formData
                                          .ImageURL_Signature_Thumbnail
                                      }
                                    />

                                    <AvField
                                      name="Signature"
                                      label="Full Size Photograph *"
                                      placeholder="Upload File"
                                      errorMessage="Upload File"
                                      onChange={e =>
                                        Fn_ChangeStateValue(
                                          this.obj,
                                          "Signature",
                                          e.target.files[0]
                                        )
                                      }
                                      validate={{ required: { value: true } }}
                                      accept=".gif,.jpg,.jpeg,.png"
                                      type="file"
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>
                                        <u>DECLARATION</u>
                                      </h5>
                                    </div>

                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "justify",
                                        marginTop: 20,
                                      }}
                                    >
                                      <p style={{ fontSize: "16px" }}>
                                        <input type="checkbox" /> I declare that
                                        I will abide by the Articles and
                                        Memorandum of Association, the Bye-Laws,
                                        and all the terms and conditions
                                        relating to all the services of the
                                        Federated Co-Operative Multi-Purpose
                                        Society Ltd. I also declare that all the
                                        information I have provided is true.
                                      </p>
                                    </div>

                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      SUBMIT
                                    </Button>

                                    {this.state.duplicate ? (
                                      <SweetAlert
                                        title="DOUBLE REGISTRATION"
                                        warning
                                        confirmBtnBsStyle="success"
                                        onConfirm={this.syno}
                                      >
                                        SORRY. DOUBLE REGISTRATION IS NOT
                                        PERMITTED. SEND AN EMAIL TO
                                        INFO@FEDERATEDSOCIETIES.COM FOR HELP.
                                        when double registration is detected.
                                      </SweetAlert>
                                    ) : null}

                                    {/* <Button
                          type="button"
                          color="primary"
                          style={{"marginLeft" : "10px"}}
                          className="mr-1 waves-effect waves-light"
                         onClick={this.reset}
                        >
                          CANCEL
                        </Button> */}
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>

                          <div>
                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Application Submitted Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                We will send you login credentials by SMS on the
                                phone number you provided when your application
                                is approved.
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul
                          style={{
                            backgroundColor: "rgba(231, 225, 225, 0)",
                            height: "40px",
                          }}
                        >
                          {/* <li>
<Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.printInvoice}
                        >
                          Print
                        </Button>

</li> */}

                          <li
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                            style={{ marginTop: "8px" }}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>

                          <li
                            className={
                              this.state.activeTab === 4
                                ? "next disabled"
                                : "next"
                            }
                            style={{ marginTop: "8px" }}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              Next
                            </Link>
                          </li>

                          <li
                            className={
                              this.state.activeTab === 1
                                ? "save disabled"
                                : "save"
                            }
                          ></li>

                          <li>
                            <Link to="login">Cancel</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(Register);
