import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
  Fn_DisplayData,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageAddEdit_AMCMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      gridData: [],
      formData: { F_company: null },
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
      branchData: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Add product";

    //Event Binding

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handlePeriodicityChange = this.handlePeriodicityChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    console.log(id);
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, "Billing/GetBill");
    } else {
      this.setState({ id: 0 });
    }
    Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.formData.F_company !== this.state.formData.F_company) {
      Fn_FillListData(
        this.obj,
        "products",
        "Product/GetProductByCompany/" + this.state.formData.F_company
      );
    }
    if (prevState.products !== this.state.products) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          ["F_product"]: this.state.formData.F_product,
        },
      }));
    }

    if (prevState.formData !== this.state.formData) {
   
      if (this.state.formData.F_company && this.state.formData.F_product) {
        let vformData = new FormData();

        vformData.append("F_company", this.state.formData.F_company);
        vformData.append("F_product", this.state.formData.F_product);
        Fn_GetReport(
          this.obj,
          {
            arguList: {
              id: 0,
              formData: vformData,
            },
          },
          "Company/GetBranchAMC",
          "branchData",
          true
        );
      }
    }
  }
  handleCompanyChange(event) {
    const F_company = event.target.value;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        F_company: F_company !== "" ? parseInt(F_company) : null,
      },
    }));
    Fn_FillListData(
      this.obj,
      "products",
      "Product/GetProductByCompany/" + F_company
    );
  }
  handleProductChange(event) {
    const F_product = event.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        F_product: F_product !== "" ? parseInt(F_product) : null,
      },
    }));
    let vformData = new FormData();

    vformData.append("F_company", this.state.formData.F_company);
    vformData.append("F_product", F_product);
    Fn_GetReport(
      this.obj,
      {
        arguList: {
          id: 0,
          formData: vformData,
        },
      },
      "Company/GetBranchAMC",
      "branchData",
      true
    );
  }
  handlePeriodicityChange(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        periodicity: value,
      },
    }));
  }
  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleCheckboxChange = event => {
    const { formData } = this.state;
    const { name, checked } = event.target;
    console.log(checked);

    this.setState({
      formData: {
        ...formData,
        [name]: checked,
      },
    });
  };
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    console.log(this.state.formData);
    vformData.append("F_Company", this.state.formData.F_company);
    vformData.append("F_ProductId", this.state.formData.F_product);
    vformData.append("branch", this.state.branchData[0].Count);
    vformData.append("amount", this.state.formData.amount);
    vformData.append("periodicity", this.state.formData.periodicity);
    vformData.append("interval", this.state.formData.interval);
    vformData.append("IsEnd", this.state.formData.IsEnd ? this.state.formData.IsEnd : false);

    if (this.state.id === 0) {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, formData: vformData } },
        "Billing/InsertBill",
        "/amcmasterlist",
        true,
        "Id"
      );
    } else {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, formData: vformData } },
        "Billing/UpdateBill",
        "/amcmasterlist",
        true,
        "Id"
      );
    }
  }

  render() {
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <select
                                        onChange={this.handleCompanyChange}
                                        className="form-control"
                                      >
                                        <option value="">select Company</option>
                                        {this.state.companies.map(item => (
                                          <option
                                            key={item.Id}
                                            value={item.Id}
                                            disabled={this.state.id > 0}
                                            selected={
                                              item.Id ===
                                              this.state.formData.F_company
                                            }
                                          >
                                            {item.Name}
                                          </option>
                                        ))}
                                      </select>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Product
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <select
                                        onChange={this.handleProductChange}
                                        className="form-control"
                                        value={this.state.formData.F_product}
                                      >
                                        <option value="-1">
                                          Select Product
                                        </option>
                                        {this.state.products.map(item => (
                                          <option
                                            key={item.Id}
                                            value={item.Id}
                                            disabled={this.state.id > 0}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="amount"
                                        className="col-form-label"
                                      >
                                        Branch Count :
                                      </label>
                                    </Col>
                                    <Col sm="1" style={{ marginTop: "8px" }}>
                                      {this.state.branchData.length > 0 &&
                                        this.state.branchData[0].Count}{" "}
                                    </Col>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="amount"
                                        className="col-form-label"
                                      >
                                        Branch AMC :{" "}
                                      </label>
                                    </Col>
                                    <Col sm="3" style={{ marginTop: "8px" }}>
                                      {this.state.branchData.length > 0 &&
                                      this.state.branchData[0].Amount != null
                                        ? this.state.branchData[0].Amount
                                        : "no dues"}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="amount"
                                        className="col-form-label"
                                      >
                                        Amount
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="amount"
                                        label=""
                                        onChange={this.handleFormChange}
                                        value={
                                          this.state.formData.amount === null
                                            ? ""
                                            : this.state.formData.amount
                                        }
                                        placeholder="Enter  Amount"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="periodicity"
                                        className="col-form-label"
                                      >
                                        Periodicity
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <div>
                                        <div>
                                          <input
                                            type="radio"
                                            id="quarterly"
                                            name="periodicity"
                                            value="quarterly"
                                            checked={
                                              this.state.formData
                                                .periodicity === "quarterly"
                                            }
                                            onChange={
                                              this.handlePeriodicityChange
                                            }
                                          />
                                          <label htmlFor="quarterly">
                                            Quarterly
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            type="radio"
                                            id="monthly"
                                            name="periodicity"
                                            value="monthly"
                                            checked={
                                              this.state.formData
                                                .periodicity === "monthly"
                                            }
                                            onChange={
                                              this.handlePeriodicityChange
                                            }
                                          />
                                          <label htmlFor="monthly">
                                            Monthly
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            type="radio"
                                            id="yearly"
                                            name="periodicity"
                                            value="yearly"
                                            checked={
                                              this.state.formData
                                                .periodicity === "yearly"
                                            }
                                            onChange={
                                              this.handlePeriodicityChange
                                            }
                                          />
                                          <label htmlFor="yearly">Yearly</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="interval"
                                        className="col-form-label"
                                      >
                                        Interval
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="interval"
                                        label=""
                                        value={
                                          this.state.formData.interval === null
                                            ? ""
                                            : this.state.formData.interval
                                        }
                                        onChange={this.handleFormChange}
                                        placeholder="Enter Interval"
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  {this.state.id > 0 && (
                                    <Row>
                                      <Col sm="6">
                                        <input
                                          type="checkbox"
                                          id="IsEnd"
                                          name="isIsEndEnd"
                                          checked={this.state.formData.IsEnd}
                                          onChange={this.handleCheckboxChange}
                                        />
                                        <label htmlFor="isEnd">Is End</label>
                                      </Col>
                                    </Row>
                                  )}
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageAddEdit_AMCMaster);
