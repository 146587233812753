import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Masters


import pageAddEdit_CustomerMaster from "pages/Masters/pageAddEdit_CustomerMaster";

import pageAddEdit_CompanyMaster from "pages/Masters/pageAddEdit_CompanyMaster";

import pageList_DayWiseAtt from "pages/Masters/pageList_DayWiseAtt";
import pageAddEdit_EmployeeMaster from "pages/Masters/pageAddEdit_EmployeeMaster";
import pageList_AuditAtt from "pages/Masters/pageList_AuditAtt";
import pageAddEdit_GlobalOptions from "pages/Masters/pageAddEdit_GlobalOptions";
import pageList_Test from "pages/Masters/pageList_Test";

import pageAddEdit_ShiftMaster from "pages/Masters/pageAddEdit_ShiftMaster";
import pageAddEdit_EditShift from "pages/Masters/pageAddEdit_EditShift";
import pageList_EmployeeMaster from "pages/Masters/pageList_EmployeeMaster";
import pageAddEdit_CashLoan from "pages/Masters/pageAddEdit_CashLoan";
import pageAddEdit_MonthlyLoan from "pages/Masters/pageAddEdit_MonthlyLoan";
import pageAddEdit_Deposit from "pages/Masters/pageAddEdit_Deposit";
import pageReport_MemberStatement from "pages/Masters/pageReport_MemberStatement";
import pageAddEdit_JobApplication from "pages/Masters/pageAddEdit_JobApplication";
import pageAddEdit_TransferShares from "pages/Masters/pageAddEdit_TransferShares";
import MySynopsis from "pages/Masters/MySynopsis";
import OTP from "pages/Authentication/OTP";
import pageAddEdit_AccountToAccount from "pages/Masters/pageAddEdit_AccountToAccount";
import pageAddEdit_PersonToPerson from "pages/Masters/pageAddEdit_PersonToPerson";
import pageAddEdit_Support from "pages/Masters/pageAddEdit_Support";
import Test from "pages/Create/Test";
import CountryMaster from "pages/Create/CountryMaster";
import PageEdit_CountryForm from "pages/Create/PageEdit_CountryForm";
import ShowJobList from "pages/Create/ShowJobList";
import pageList_DMRList from "pages/Create/pageList_DMRList";
import selectJob from "pages/Create/selectJob";
import pageList_jobList from "pages/Create/pageList_jobList";
import pageAdd_jobAdd from "pages/Create/pageAdd_jobAdd";
// import Person2Person from "pages/Create/PersontoPerson";
import PersontoPerson from "pages/Create/PersontoPerson";
import pageList_MailList from "pages/Create/pageList_MailList";
import pageAddEdit_AMCMaster from "pages/Masters/pageAddEdit_AMCMaster";
import pageList_AMCMaster from "pages/Masters/pageList_AMCMaster";
import pageList_AMCReport from "pages/Masters/pageList_PaymentReport";
import pageList_PaymentReport from "pages/Masters/pageList_PaymentReport";
import pageAddEdit_AddProduct from "pages/Masters/pageAddEdit_AddProduct";
import pageAddEdit_BillPayment from "pages/Masters/pageAddEdit_BillPayment";
import pageList_DueReport from "pages/Masters/pageList_DueReport";
import pageAddEdit_UserCreation from "pages/Masters/pageAddEdit_UserCreation";
import pageList_EmployeeReport from "pages/Masters/pageList_EmployeeReport";
import EmployeeMonthlyReport from "pages/Masters/EmployeeMonthlyReport";
import BranchMaster from "pages/Masters/BranchMaster";
import pageAddEdit_CreateBill from "pages/Masters/pageAddEdit_CreateBill";
import FirebaseTest from "pages/Create/FirebaseTest";
import Chat from "Chat/Chat";



const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/changepassword", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Masters

  { path: "/add-shiftmaster", component: pageAddEdit_ShiftMaster },
  { path: "/editemp-shiftmaster", component: pageAddEdit_EditShift },
  { path: "/edit-shiftmaster", component: pageAddEdit_ShiftMaster },

  { path: "/add-customermaster", component: pageAddEdit_CustomerMaster },

  { path: "/audit-attendance", component: pageList_AuditAtt },

  { path: "/accountstatement", component: pageReport_MemberStatement },

  { path: "/support", component: pageAddEdit_Support },

  { path: "/accounttoaccount", component: pageAddEdit_AccountToAccount },

  { path: "/accounttoother", component: pageAddEdit_PersonToPerson },

  { path: "/cashloan", component: pageAddEdit_CashLoan },

  { path: "/deposit", component: pageAddEdit_Deposit },

  { path: "/monthlyloan", component: pageAddEdit_MonthlyLoan },

  { path: "/edit-customermaster/:id", component: pageAddEdit_CustomerMaster },

  { path: "/mysynopsis/:id", component: MySynopsis },

  { path: "/add-employeemaster", component: pageAddEdit_EmployeeMaster },

  { path: "/jobapplication", component: pageAddEdit_JobApplication },
  { path: "/edit-employeemaster/:id", component: pageAddEdit_EmployeeMaster },
  { path: "/masters-employeemaster", component: pageList_EmployeeMaster },

  //

  { path: "/listtest", component: pageList_Test },

  { path: "/transfershares/:id", component: pageAddEdit_TransferShares },

  { path: "/transfershares/:id", component: pageAddEdit_TransferShares },

  { path: "/edit-companymaster/:id", component: pageAddEdit_CompanyMaster },

  //Amc here
  { path: "/companymaster", component: pageAddEdit_CompanyMaster },
  { path: "/amcmaster", component: pageAddEdit_AMCMaster },
  { path: "/amcmaster/:id", component: pageAddEdit_AMCMaster },
  { path: "/amcmasterlist", component: pageList_AMCMaster },
  { path: "/paymentreport", component: pageList_PaymentReport },
  { path: "/duereport", component: pageList_DueReport },
  { path: "/addproduct", component: pageAddEdit_AddProduct },
  { path: "/billpayment", component: pageAddEdit_BillPayment },
  { path: "/branchmaster", component: BranchMaster },
  { path: "/createbill", component: pageAddEdit_CreateBill },
  //employee
  { path: "/usercreation", component: pageAddEdit_UserCreation },
  { path: "/employeereport", component: pageList_EmployeeReport },
  { path: "/monthlyreport", component: EmployeeMonthlyReport },
  { path: "/firebasetest", component: FirebaseTest },

  //Chat

  { path: "/chat", component: Chat },

  
  //Global Options
  { path: "/GlobalOptions", component: pageAddEdit_GlobalOptions },

  //Reports
  { path: "/daywiseatt", component: pageList_DayWiseAtt },
  { path: "/test", component: Test },
  { path: "/countryMaster", component: CountryMaster },
  { path: "/pageEdit_countryForm", component: PageEdit_CountryForm },
  { path: "/EditCountry/:id", component: PageEdit_CountryForm },
  { path: "/jobfilter", component: ShowJobList },
  { path: "/dmrlist", component: pageList_DMRList },
  { path: "/selectjob", component: selectJob },
  { path: "/pagejoblist", component: pageList_jobList },
  { path: "/addjob", component: pageAdd_jobAdd },
  { path: "/addjob/:id", component: pageAdd_jobAdd },
  { path: "/ptop", component: PersontoPerson },
  { path: "/maillist", component: pageList_MailList },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/otp", component: OTP },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
