import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
  Fn_GetReport,
  showToastWithCloseButton,
} from "../../store/functions";
import { useMountedLayoutEffect } from "react-table";
import { bind } from "lodash";
import RenderBranch from "./RenderBranch";

export const DateString = date => {
  date = new Date(date);
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

export const todaydate = () => {
  let today = new Date();
  let yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

class BranchMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: { receiptDate: null, tt: "cash" },
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      Photo: "",
      fee: [{ id: 1, name: "GeneralFees" }],
      branchData: [],
      selectedOption: "",
      aid: 0,
      fo3: false,
      success_msg: false,
      Name: "",
      IsAdmit: false,
      formDataArray: [],
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Admission Form";
    this.breadCrumbTitle = "Admission Form";
    this.breadCrumbItem = "Add";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/AdmissionFormEdit";
    this.API_URL_SAVE = API_WEB_URLS.AdmissionForm + "/0/token";
    this.pushFormName = "/masters-AdmissionForm";
    this.rtPage_AdmissionForm = "/masters-AdmissionForm";

    //Event Binding
    this.handleAddRow = this.handleAddRow.bind(this);

    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.syno = this.syno.bind(this);
    this.reset = this.reset.bind(this);

    //ref
    this.findId = React.createRef();
    this.student = React.createRef();
    this.duesMonth = React.createRef();
    this.computer = React.createRef();
    this.rn = React.createRef();
    this.rd = React.createRef();
    this.comment = React.createRef();
    this.tt = React.createRef();
    this.ttNumber = React.createRef();
    this.bankName = React.createRef();
    this.save = React.createRef();
    this.messagesEnd = React.createRef();
  }

  componentDidMount() {
     Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
    //focus
  
    // const selectedOptions = this.state.branchData.map(row => Number(row.feeType));

    // const availableForms = this.state.forms.filter(
    //   form => !selectedOptions.includes(form.id)
    // );
    // this.setState({
    //   availableForms: availableForms,
    // });
    // this.setState({
    //   name: obj.username,
    //   email: obj.email,
    //   aid: obj.uid,
    //   role: obj.role,
    // });
    //  var F_CompanyMaster  =  localStorage.getItem("F_CompanyMaster");

    // Filling DropDowns
    //   Fn_FillListData(this.obj, "registration_dd", API_WEB_URLS.MASTER + "/0/token/AdmissionForm/Id/0");

    const { id } = this.props.match.params;

    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  
  handleCompanyChange(option) {
    console.log(option.value);
    console.log(option.label);

    let companyId = option.value;
    this.setState({
      F_company :companyId
    })
    Fn_FillListData(
      this.obj,
      "products",
      "Product/GetProductByCompany/" + companyId
    );
  }
  handleProductChange(item) {
    console.log(item.value);
    this.setState({
      F_product :item.value
    })
     let vformData = new FormData();

     vformData.append("F_company", this.state.F_company);
     vformData.append("F_product", item.value);
     Fn_GetReport(
       this.obj,
       {
         arguList: {
           id: 0,
           formData: vformData,
         },
       },
       "Company/GetBranch",
       "branchData",
       true
     );
    
  }
  



  setTodayDate = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        receiptDate: today,
      },
    }));
  };
  reset() {
    this.props.history.push(`/`, {});
  }
  handleFormChange = event => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };
  handleOptionChange = value => {
    this.setState({
      selectedOption: value,
    });
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        selectedOption: value,
      },
    }));
  };
  handleAddRow(e) {
    e.preventDefault();
    console.log("idhar toh aaa gya");
    const maxId = Math.max(...this.state.branchData.map(obj => obj.Id));
    let newRow;

    newRow = {
      Id: maxId + 1,
      Name: "",
      Amount: 0,
      AMC: 0,
    
    };

    this.setState(prevState => ({
      branchData: [...prevState.branchData, newRow],
    }));
  }
  handleDeleteRow = id => {
    console.log('id',id)
    if(this.state.branchData.length === 1){
      showToastWithCloseButton('warning',"can not delete last branch")
      return;
    }
    const updatedData = this.state.branchData.filter(row => row.Id !== id);

    this.setState(prevState => ({
      branchData: updatedData,
    }));
  };

  //handleClick
 

  editRow(e, index) {
    console.log("i am here")
    const { name, value } = e.target;

    console.log(name, value, index);
    const rowsInput = [...this.state.branchData];

    rowsInput[index][name] = value;
    this.setState({
      branchData : rowsInput
    })
   
  }

 
  btnSave_onClick(event, formData) {
    event.preventDefault();
    console.log(this.state.formData);
    let vformData = new FormData();
   vformData.append("F_company", this.state.F_company);
   vformData.append("F_product", this.state.F_product);
      const branchDataString = JSON.stringify(this.state.branchData);
      vformData.append("Data", branchDataString);

       Fn_AddEditData(
         this.obj,
         { arguList: { id: 0, formData: vformData } },
         "Company/InsertBranch",
         "/dashboard",
         true,
         "Id"
       );
  }

  clearFields() {
    console.log("called clear ");
    this.setState({
      id: 0,
      formData: { findId: null, receiptDate: null, tt: "cash" },
      branchData: [],
    });
  }

  syno() {
    this.setState({
      success_msg: false,
    });

    this.props.history.push(`${this.rtPage_AdmissionForm}`, {});
  }

  btnCancel_onClick = event => {
    event.preventDefault();

    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {


   
  
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix"></div>
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="12">
                                  <Card>
                                    <CardBody>
                                     
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.companies &&
                                          this.state.companies.map(item => ({
                                            value: item.Id,
                                            label: item.Name,
                                          }))
                                        }
                                        onChange={this.handleCompanyChange}
                                        placeholder="Search or select Company"
                                        isSearchable
                                      />
                                    </Col>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Products
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.products &&
                                          this.state.products.map(item => ({
                                            value: item.Id,
                                            label: item.name,
                                          }))
                                        }
                                        onChange={this.handleProductChange}
                                        placeholder="Search or select Product"
                                        isSearchable
                                      />
                                    </Col>
                                  </Row>
                                       
                                      <Row style={{ marginTop: "30px" }}>
                                        <RenderBranch
                                          handleChange={this.editRow}
                                          dataArray={this.state.branchData}
                                          handleAddRow={this.handleAddRow}
                                          handleDeleteRow={this.handleDeleteRow}
                                      
                                        />
                                      </Row>
                                    
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>

                          <div>
                            <Button
                              id="clearButton"
                              onClick={() => this.clearFields()}
                            >
                              Clear
                            </Button>
                            <button
                              id="saveButton"
                              type="submit"
                              color="primary"
                              style={{ marginLeft: "10px" }}
                              className="btn btn-success mr-1 waves-effect waves-light"
                              ref={this.save}
                            >
                              Save
                            </button>

                            <Button
                              type="button"
                              color="primary"
                              style={{ marginLeft: "10px" }}
                              className="mr-1 waves-effect waves-light"
                              onClick={this.reset}
                            >
                              Cancel
                            </Button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(BranchMaster);
