import React, { Component } from "react";
import AuthCode from "react-auth-code-input";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangePassword,
  Fn_ChangeStateValue,
} from "../../store/functions";


const aAndOrB = (value, ctx) => {
  if (value !== ctx.newpassword) {
    return "Password doesn't match.";
  }
  return true;
};
function showToastWithCloseButton(toastType, message) {
  toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    progressBar: true,
    timeOut: 2000,
  };
  if (toastType == "success") toastr.success(message);
  else if (toastType == "warning") toastr.warning(message);
  else if (toastType == "error") toastr.error(message);
}

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [
        {
          Name: "",
        },
      ],
      RecipentDetail: [
        {
          Name: "",
        },
      ],
      prov: false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype: [],
      SAccountType: 0,
      fo: "",
      fo2: "",
    };

    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Change Password";
    this.breadCrumbTitle = "Change Password";
    this.breadCrumbItem = this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.ChangePassword + "/0/token";
    this.pushFormName = "/logout";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }

  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(
      this.obj,
      "transactiontype",
      API_WEB_URLS.MASTER + "/0/token/TransactionType/Id/0"
    );

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    
    if (this.state.newpassword.length >= 4 && this.state.newpassword == this.state.renewpassword ) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      this.setState({
        name: obj.username,
        email: obj.email,
        aid: obj.uid,
        role: obj.role,
      });
      let vformData = new FormData();

      vformData.append("F_UserMaster", this.state.aid);
      vformData.append("OldPassword", this.state.oldpassword);
      vformData.append("NewPassword", this.state.renewpassword);

      Fn_ChangePassword(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true
      );
    }else{
      showToastWithCloseButton('error', 'Password does not match required conditions!');
    }
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  pass = event => {
    let pass = event.target.value;

    this.setState({ fo: pass });
  };

  passmatch = event => {
    let pass = event.target.value;

    if (this.state.fo != pass) {
      this.form.validateInput("renewpassword");
    } else {
    }
  };
  

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Change Password</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          ref={c => (this.form = c)}
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="6">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Old Password
                                      </label>
                                    </Col>
                                    <Col lg="8">
                                      <AuthCode
                                        password={true}
                                        characters={4}
                                        onChange={e => {
                                          this.setState({ oldpassword: e });
                                        }}
                                        className="form-control form-control-lg text-center"
                                        allowedCharacters="^[0-9]"
                                        inputStyle={{
                                          width: "50px",
                                          height: "calc(1.5em + 1rem + 2px)",
                                          padding: ".5rem 1rem",
                                          borderRadius: "8px",
                                          fontSize: "1.01562rem",
                                          textAlign: "center",
                                          marginBottom: "20px",
                                          marginRight: "15px",
                                          border: "1px solid #ced4da",
                                          textTransform: "uppercase",
                                          borderRadius: ".4rem",
                                        }}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        New Password
                                      </label>
                                    </Col>
                                    <Col lg="8">
                                      <AuthCode
                                        password={true}
                                        characters={4}
                                        onChange={e => {
                                          Fn_ChangeStateValue(
                                            this.obj,
                                            "newpassword",
                                            e
                                          );
                                        }}
                                        className="form-control form-control-lg text-center"
                                        allowedCharacters="^[0-9]"
                                        inputStyle={{
                                          width: "50px",
                                          height: "calc(1.5em + 1rem + 2px)",
                                          padding: ".5rem 1rem",
                                          borderRadius: "8px",
                                          fontSize: "1.01562rem",
                                          textAlign: "center",
                                          marginBottom: "20px",
                                          marginRight: "15px",
                                          border: "1px solid #ced4da",
                                          textTransform: "uppercase",
                                          borderRadius: ".4rem",
                                        }}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Re-enter New Password
                                      </label>
                                    </Col>
                                    <Col lg="8">
                                      <AuthCode
                                        password={true}
                                        characters={4}
                                        onChange={e => {
                                          this.setState({ renewpassword: e });
                                        }}
                                        className="form-control form-control-lg text-center"
                                        allowedCharacters="^[0-9]"
                                        inputStyle={{
                                          width: "50px",
                                          height: "calc(1.5em + 1rem + 2px)",
                                          padding: ".5rem 1rem",
                                          borderRadius: "8px",
                                          fontSize: "1.01562rem",
                                          textAlign: "center",
                                          marginBottom: "20px",
                                          marginRight: "15px",
                                          border: "1px solid #ced4da",
                                          textTransform: "uppercase",
                                          borderRadius: ".4rem",
                                        }}
                                      />
                                    </Col>
                                  </Row>

                                  <div className="d-flex flex-wrap gap-2">
                                    <Button type="submit" color="primary">
                                      Save
                                    </Button>{" "}
                                    <Button type="reset" color="secondary">
                                      Cancel
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(ChangePassword);
