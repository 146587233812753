import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageList_PaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {
        companyId: 0,
        productId: 0,
        fromDate: getLastYearDate(),
        toDate: getCurrentDate(),
      },
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Member Balance Report";

    //Event Binding
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handlePeriodicityChange = this.handlePeriodicityChange.bind(this);
    this.calculateTotalAmount = this.calculateTotalAmount.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
    Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
    // Fn_FillListData(this.obj, "products", "Product/GetProducts/0");
    //********isko hta dena productData backend se ayega iski jrurt nhi hai******
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.productData !== this.state.productData) {
      this.calculateTotalAmount();
    }
  }
  //handle change function
  handlePeriodicityChange(e) {
    let obj = this.state.formData;
    if (e != null) {
      obj["periodicity"] = e.target.value;
    }
    this.setState({
      formData: obj,
    });
  }
  calculateTotalAmount() {
    const { productData } = this.state;
    const totalAmount = productData.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    this.setState({ totalAmount });
  }

  handleCompanyChange(option) {
    console.log(option.value);
    console.log(option.label);

    let obj = this.state.formData;
    if (option != null) {
      obj["companyId"] = option.value;
    }
    this.setState({
      formData: obj,
    });
     Fn_FillListData(
       this.obj,
       "products",
       "Product/GetProductByCompany/" + option.value
     );
  }
  handleProductChange(option) {
    console.log(option.value);
    console.log(option.label);

    let obj = this.state.formData;
    if (option != null) {
      obj["productId"] = option.value;
    }
    this.setState({
      formData: obj,
    });
  }
  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    vformData.append("F_company", this.state.formData.companyId);
    vformData.append("F_ProductId", this.state.formData.productId);
    vformData.append("Fromdate", this.state.formData.fromDate);
    vformData.append("todate", this.state.formData.toDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "BillPayment/GetAllPayments",
      "productData",
      true
    );
    console.log(this.state.productData);
  }

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const sumAmount = this.state.productData.reduce(
      (total, row) => total + row.Balance,
      0
    );

    const columns = [
      {
        dataField: "Company_Name",
        text: "Company",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Product_name",
        text: "Product",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
        footer: columnData => <div>{this.state.totalAmount}</div>,
      },
      {
        dataField: "date_of_payment",
        text: "Date Of Payment",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "BillPrint",
        text: "Bill print",
        sort: true,
        formatter: (cell, row) => {
          return (
            <Button color="primary" onClick={() => this.handlePrintBill(row)}>
              Print Bill
            </Button>
          );
        },
        footer: columnData => <div></div>,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.companies.length > 0 &&
                                          this.state.companies.map(item => ({
                                            value: item.Id,
                                            label: item.Name,
                                          }))
                                        }
                                        onChange={this.handleCompanyChange}
                                        placeholder="Search or select Company"
                                        isSearchable
                                      />
                                    </Col>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Products
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <Select
                                        options={
                                          this.state.products.length > 0 &&
                                          this.state.products.map(item => ({
                                            value: item.Id,
                                            label: item.name,
                                          }))
                                        }
                                        onChange={this.handleProductChange}
                                        placeholder="Search or select Company"
                                        isSearchable
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="1"></Col>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="fromDate"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        From Date
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="fromDate"
                                        label=""
                                        value={this.state.formData.fromDate}
                                        onChange={this.handleFormChange}
                                        placeholder="From Date"
                                        type="date"
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="toDate"
                                        className="col-form-label"
                                      >
                                        To Date
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="toDate"
                                        label=""
                                        value={this.state.formData.toDate}
                                        placeholder="To Date"
                                        onChange={this.handleFormChange}
                                        type="date"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: sizePerPageList,
                        })}
                        keyField="id"
                        columns={columns}
                        data={this.state.productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={this.state.productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {this.state.productData.length > 0 ? (
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : null}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageList_PaymentReport);
