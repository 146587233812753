import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
} from "../../store/functions";


export const DateString = date => {
  date = new Date(date);
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

class pageAdd_jobAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      IsFileUpload: true,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      UserImage: "",
      Signature: "",
      //dropdowns
      gender: [],
      state_dd: [],
      Name: "",
      aid: 0,
      fo3: false,
      success_msg: false,
      Amount: 0,
      TimePeriod: 0,
      NoOfMembers: 0,
      InstallmentAmount: 0,
      StartDate: new Date().toString(),
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Job";
    this.breadCrumbTitle = "Job";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GetJobList";
    this.API_URL_SAVE = "AddJobTitleByEntity/0/token";
    // this.pushFormName = "/countryMaster";
    // this.rtPage_City = "/countryMaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.reset = this.reset.bind(this);
    // this.onStateChange= this.onStateChange.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;

      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  reset() {
    this.props.history.push(`/pagejoblist`, {});
  }
  // onStateChange(event){
  //   var F_StateMaster = event.target.value;
  //   Fn_FillListData(this.obj, "state_dd", API_WEB_URLS.MASTER + "/0/token/StateMaster/Id/"+F_StateMaster);
  // }
  async btnSave_onClick(event, formData) {
    let vformData = new FormData();
    //Information
    const obj = JSON.parse(localStorage.getItem("authUser"));

    vformData.append("F_MemberMaster", obj.uid);
    vformData.append("Name", formData.jobtitle);
    vformData.append("Grade", formData.grade);
    vformData.append("JobDescription", formData.JobDescription);
    vformData.append("ClosingDate", formData.closingdate);

    vformData.append(
      "JobDescriptionFile.ImageFileName",
      this.state.JobDescriptionFile == undefined ? "ss" : "File"
    );
    vformData.append(
      "JobDescriptionFile.ImageFile",
      this.state.JobDescriptionFile
    );
    vformData.append(
      "IsFileUpload",
      this.state.formData.IsFileUpload == undefined
        ? this.state.IsFileUpload
        : this.state.formData.IsFileUpload
    );
    
    // vformData.append("F_StateMaster", 1);
    //create a post request for sending name and code using fetch method
    console.log(this.state.JobDescriptionFile);
    if (!this.state.id) {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        "/pagejoblist",
        true,
        "Id"
      );
    } else {
      // vformData.append("Id", this.state.id);
      Fn_AddEditData(
        this.obj,
        {
          arguList: {
            id: this.state.id,
            formData: vformData,
          },
        },
        this.API_URL_SAVE,
        "/pagejoblist",
        true,
        "Id"
      );
    }
  }

  syno() {
    this.setState({
      success_msg: false,
    });
    //event.preventDefault();
    this.props.history.push(`${this.rtPage_City}`, {});
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  handleToggle() {
    //set state of isfileupload

    this.setState({
      IsFileUpload: !this.state.IsFileUpload,
    });

    console.log(this.IsFileUpload);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2,
          }}
        >
          {" "}
          No
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix"></div>
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Job Title
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="jobtitle"
                                            label=""
                                            value={
                                              this.state.formData.JobTitle ===
                                              null
                                                ? ""
                                                : this.state.formData.JobTitle
                                            }
                                            placeholder="Enter  Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="grade"
                                            className="col-form-label"
                                          >
                                            Grade
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="grade"
                                            label=""
                                            value={
                                              this.state.formData.Grade === null
                                                ? ""
                                                : this.state.formData.Grade
                                            }
                                            placeholder="Enter  Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <div className="form-check form-switch form-switch-lg mb-3">
                                          {this.state.IsFileUpload ? (
                                            <label
                                              className="form-check-label"
                                              htmlFor="customSwitchsizelg"
                                            >
                                              Upload Description File
                                            </label>
                                          ) : (
                                            <label
                                              className="form-check-label"
                                              htmlFor="customSwitchsizelg"
                                            >
                                              Write Description
                                            </label>
                                          )}
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={
                                              this.state.formData
                                                .IsFileUpload == undefined ||
                                              this.state.formData
                                                .IsFileUpload == null
                                                ? this.state.IsFileUpload
                                                : this.state.formData
                                                    .IsFileUpload
                                            }
                                            id="customSwitchsizelg"
                                            // defaultChecked
                                            onClick={() => {
                                              this.setState({
                                                IsFileUpload:
                                                  !this.state.IsFileUpload,
                                              });
                                              this.setState(prevState => ({
                                                formData: {
                                                  ...prevState.formData,
                                                  IsFileUpload:
                                                    !this.state.formData
                                                      .IsFileUpload,
                                                },
                                              }));

                                              console.log(
                                                this.state.formData.IsFileUpload
                                              );
                                            }}
                                          />
                                        </div>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Job Description
                                          </label>
                                        </Col>
                                        {this.state.formData.IsFileUpload ==
                                          undefined ||
                                        this.state.formData.IsFileUpload ==
                                          null ? (
                                          this.state.IsFileUpload
                                        ) : this.state.formData.IsFileUpload ? (
                                          <Col sm="6">
                                            <AvField
                                              name="JobDescriptionFile"
                                              label="CV"
                                              placeholder="Upload PDF"
                                              errorMessage="Upload PDF"
                                              onChange={e =>
                                                Fn_ChangeStateValue(
                                                  this.obj,
                                                  "JobDescriptionFile",
                                                  e.target.files[0]
                                                )
                                              }
                                              accept=".pdf"
                                              type="file"
                                              className="form-control"
                                            />
                                          </Col>
                                        ) : (
                                          <Col sm="6">
                                            <AvField
                                              name="JobDescription"
                                              label=""
                                              value={
                                                this.state.formData
                                                  .JobDescription === null
                                                  ? ""
                                                  : this.state.formData
                                                      .JobDescription
                                              }
                                              placeholder="Enter Job Description"
                                              type="textarea"
                                              className="form-control"
                                            />
                                          </Col>
                                        )}
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Closing Date
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="closingdate"
                                            label=""
                                            value={
                                              this.state.formData
                                                .ClosingDate === null
                                                ? ""
                                                : this.state.formData
                                                    .ClosingDate
                                            }
                                            placeholder="Enter  Name"
                                            type="date"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row></Row>
                            </TabPane>
                          </TabContent>

                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>

                            <Button
                              type="button"
                              color="primary"
                              style={{ marginLeft: "10px" }}
                              className="mr-1 waves-effect waves-light"
                              onClick={this.reset}
                            >
                              Cancel
                            </Button>

                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Country Saved Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                You clicked the button!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAdd_jobAdd);
