import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageList_EmployeeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {
        f_usermaster : null,
        date: getCurrentDate(),
      },
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
      members: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Employee Report";

    //Event Binding

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handlePeriodicityChange = this.handlePeriodicityChange.bind(this);
    this.calculateTotalAmount = this.calculateTotalAmount.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
  }

  componentDidMount() {
     const obj = JSON.parse(localStorage.getItem("authUser"));
    console.log(obj.Usertype);

      this.setState({
        uid: obj.uid,
        Usertype: obj.Usertype,
      });
    Fn_FillListData(this.obj, "members", "Member/GetMembers/0");
      console.log(obj.Usertype == "admin" ? 0 : obj.uid);
      let vformData = new FormData();
      vformData.append('date' ,this.state.formData.date)
      let id = obj.Usertype === "admin" ? 0 : obj.uid 
     Fn_GetReport(
       this.obj,
       {
         arguList: {
           id: 0,
           formData: vformData,
         },
       },
       "Task/GetEmployeeReport/" + id,
       "productData",
       true
     );
   

 
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.productData !== this.state.productData) {
      this.calculateTotalAmount();
    }
  }

  //handle change function
  handlePeriodicityChange(e) {
    let obj = this.state.formData;
    if (e != null) {
      obj["periodicity"] = e.target.value;
    }
    this.setState({
      formData: obj,
    });
  }
  calculateTotalAmount() {
    const { productData } = this.state;
    const totalAmount = productData.reduce(
      (total, item) => total + parseFloat(item.DueAmount),
      0
    );
    this.setState({ totalAmount });
  }


  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleMemberChange(event) {
    const memberId = event.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        f_usermaster: memberId !== "" ? parseInt(memberId) : null,
      },
    }));
   
  }
  btnSave_onClick(event, formData) {
     let vformData = new FormData();
     vformData.append("date", this.state.formData.date);

if (this.state.Usertype === "admin") {
  Fn_GetReport(
    this.obj,
    {
      arguList: {
        id: 0,
        formData: vformData,
      },
    },
    "Task/GetEmployeeReport/" + this.state.formData.f_usermaster,
    "productData",
    true
  );
} else {
  Fn_GetReport(
    this.obj,
    {
      arguList: {
        id: 0,
        formData: vformData,
      },
    },
    "Task/GetEmployeeReport/" + this.state.uid,
    "productData",
    true
  );
}

  }

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const sumAmount = this.state.productData.reduce(
      (total, row) => total + row.Balance,
      0
    );

    const columns = [
      {
        dataField: "Name",
        text: "Task name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Username",
        text: "Assigned to",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Provider",
        text: "Assigned by",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "time",
        text: "Time given (min)",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Time_taken",
        text: "Time taken(min)",
        sort: true,
        footer: columnData => <div></div>,
        style: (cell, row, rowIndex, colIndex) => {
          return cell > row.time ? { color: "red" } : { color: "green" };
        },
      },
      {
        dataField: "Company_Name",
        text: "Company",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Product_name",
        text: "Product",
        sort: true,
        footer: columnData => <div></div>,
      },

      // {
      //   dataField: "Report",
      //   text: "Report",
      //   sort: true,
      //   formatter: (cell, row) => {
      //     return (
      //       <Button color="primary" onClick={() => this.handleReport(row)}>
      //         Report
      //       </Button>
      //     );
      //   },
      //   footer: columnData => <div></div>,
      // },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  {this.state.Usertype === "admin" && (
                                    <Row>
                                      <Col sm="1" className="mb-3">
                                        <label
                                          htmlFor="branch"
                                          className="col-form-label"
                                        >
                                          Member
                                        </label>
                                      </Col>
                                      <Col sm="4">
                                        <select
                                          style={{ marginBottom: "8px" }}
                                          onChange={this.handleMemberChange}
                                          className="form-control"
                                        >
                                          <option value="-1">
                                            select Member
                                          </option>
                                          {this.state.members.map(item => (
                                            <option
                                              key={item.Id}
                                              value={item.Id}
                                              selected={
                                                item.Id ===
                                                this.state.formData.f_usermaster
                                              }
                                            >
                                              {item.Name}
                                            </option>
                                          ))}
                                        </select>
                                      </Col>
                                    </Row>
                                  )}

                                  <Row>
                                    <Col sm="1" className="mb-3">
                                      <label
                                        htmlFor="date"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Date
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        name="date"
                                        label=""
                                        value={this.state.formData.date}
                                        onChange={this.handleFormChange}
                                        placeholder="From Date"
                                        type="date"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: sizePerPageList,
                        })}
                        keyField="Id"
                        columns={columns}
                        data={this.state.productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="Id"
                            columns={columns}
                            data={this.state.productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {this.state.productData.length > 0 ? (
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : null}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageList_EmployeeReport);
