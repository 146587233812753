export const API_WEB_URLS = {
  BASE: "https://apitaskmanager.shinewellsofttech.co.in/api/",
  // BASE: "https://localhost:7037/api/",
  // BASE: "https://localhost:44362/api/V1/",
  // BASE: "https://api.societiesbank.com/api/V1/",
  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",
  MASTER_COUNTRY: "CountryMaster",

  SEARCHMEMBERSHIPNO: "SearchMembership",
  MemberMasterEntity: "MemberMasterEntity",
  //

  MEMBER: "MemberMaster",
  PersonToPersonRequest: "PersonToPersonRequest",
  InsertSupportMail: "InsertSupportMail",
  InsertSupportMailL: "InsertSupportMailL",
  AccountToAccountRequest: "AccountToAccountRequest",
  TransactionWithdraw: "TransactionWithdraw",
  TransactionDeposit: "TransactionDeposit",
  ChangePassword: "ChangePasswordMember",
  AdvisorMaster: "AgentMaster",

  //
  IMG_URL: "https://api.societiesbank.com/MemberImages/PDF/",
};
