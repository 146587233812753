import React, { useState, useEffect } from "react";

function ReportModal({ tasks, subTasks }) {
  const [taskArray, setTaskArray] = useState([]);
  const [subtaskArray, setSubTaskArray] = useState([]);

  useEffect(() => {
    console.log("sub",subTasks);
    if (typeof tasks === "object" && tasks !== null && !Array.isArray(tasks)) {
      setTaskArray(prevDataArray => [...prevDataArray, tasks]);
    } else if (Array.isArray(tasks)) {
      setTaskArray(tasks);
    }

    if (
      typeof subTasks === "object" &&
      subTasks !== null &&
      !Array.isArray(subTasks)
    ) {
      setSubTaskArray(prevDataArray => [...prevDataArray, subTasks]);
    } else if (Array.isArray(subTasks)) {
      setSubTaskArray(subTasks);
    }
  }, [tasks, subTasks]);

  return (
    <div className="page-content">
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr style={{ backgroundColor: "#4189ec", color: "white" }}>
            <th style={tableCellStyle}>Task Name</th>
            <th style={tableCellStyle}>Task Description</th>
            <th style={tableCellStyle}>Time Given</th>
            <th style={tableCellStyle}>Time Taken</th>
          </tr>
        </thead>
        <tbody>
          {taskArray.map(task => (
            <React.Fragment key={task.Id}>
              <tr style={{ backgroundColor: "#4189ec", color: "white" }}>
                <td style={tableCellStyle}>{task.Name}</td>
                <td style={tableCellStyle}>{task.Description}</td>
                <td style={tableCellStyle}>{task.time}</td>
                <td style={tableCellStyle}>
                  {task.Time_taken}
                  {"(min)"}
                </td>
              </tr>
              {subtaskArray
                .filter(subtask => subtask.F_taskmaster === task.Id)
                .map(subtask => (
                  <tr key={subtask.Id} style={{ backgroundColor: "#eaf7fd" }}>
                    <td colSpan="3" style={{ padding: "10px 0" }}>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th style={tableCellStyle}>Subtask Name</th>
                            <th style={tableCellStyle}>Subtask Description</th>
                            <th style={tableCellStyle}>Subtask Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={tableCellStyle}>{subtask.Name}</td>
                            <td style={tableCellStyle}>
                              {subtask.Description}
                            </td>
                            <td style={tableCellStyle}>
                              {subtask.time} {"(min)"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const tableCellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

export default ReportModal;
