import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// availity-reactstrap-validation
import {
  AvForm,
  input,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, Link } from "react-router-dom";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, showToastWithCloseButton } from "../../store/functions";

class pageAddEdit_CompanyMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: { IsEnd: false },
      companies: [],
    };
    this.obj = this;
    this.formTitle = "Company Master";
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL_SAVE = API_WEB_URLS.COMPANY + "/0/token";
    this.pushFormName = "/dashboard";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/CompanyMaster";
  }
  componentDidMount() {
    Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
    
  }

  getData = async () => {
    
    try {
     
      const vformData = new FormData();
       const { Name, address, GST_no, PO_no, Email, Phone, IsEnd ,City,state} =
         this.state.formData;
         vformData.append("Name", Name ? Name : '');
         vformData.append("address", address ? address : '');
         vformData.append("GST_no", GST_no ? GST_no : '');
         vformData.append("PO_no", PO_no ? PO_no : '');
         vformData.append("Email", Email ? Email : '');
         vformData.append("Phone", Phone ? Phone : 0);
         vformData.append("City", City ? City : '');
         vformData.append("State", state ? state : '');
         
      vformData.append("IsEnd", IsEnd || false);
   
     
      let url;
      let method;

      if (this.state.id > 0) {
        url = `${API_WEB_URLS.BASE}Company/UpdateCompany/${this.state.id}`;
        method = "PUT";
      } else {
        url = `${API_WEB_URLS.BASE}Company/InsertCompany`;
        method = "POST";
      }

      const response = await fetch(url, {
        method: method,
        body: vformData,
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      const responseData = await response.json();
       console.log(responseData.data[0].Id);
       responseData.data[0].Id > 0 &&
         method == "PUT" &&
         showToastWithCloseButton("success", "Data Updated successfully") &&
         this.setState({
           id: 0,
           formData: {
             Name: "",
             address: "",
             GST_no: "",
             PO_no: "",
             Email: "",
             Phone: "",
             City: "",
             state: "",
             IsEnd: false,
           },
         });
         if (responseData.data[0].Id > 0 && method == "POST"){
           showToastWithCloseButton("success", "Data Added successfully") ;
             this.setState({
               id: 0,
               formData: {
                 Name: "",
                 address: "",
                 GST_no: "",
                 PO_no: "",
                 Email: "",
                 Phone: "",
                 City: "",
                 state: "",
                 IsEnd: false,
               },
             },()=>{
              Fn_FillListData(this.obj, "companies", "Company/GetAll/0");
             }); 
         }
          
         
    } catch (error) {
      showToastWithCloseButton("error", error);
    }
  };
  handleFormChange(event) {
    const { name, value } = event.target;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleCompanyChange(event) {
    const companyId = event.target.value;
    if(companyId=== "-1" ){
 
      this.setState({
        id: 0,
        formData: {
          Name: "",
          address: "",
          GST_no: "",
          PO_no: "",
          Email: "",
          Phone: "",
          City: "",
          state: "",
          IsEnd: false,
        },
      });
      console.log('-1')
      return;
    }
    this.setState({
      id: companyId,
    });
     this.setState({
       companyId: companyId !== "" ? parseInt(companyId) : null,
     });
    Fn_DisplayData(this.obj, companyId, "Company/GetAll");
  }
  handleCheckboxChange = event => {
    const { formData } = this.state;
    const { name, checked } = event.target;
    console.log(checked);

    this.setState({
      formData: {
        ...formData,
        [name]: checked,
      },
    });
  };
  btnSave_onClick(event, values) {
    
      this.getData();
    
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm className="needs-validation">
                          <Row>
                            <Col lg="6">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="branch"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                    </Col>

                                    <Col sm="6">
                                      <select
                                        onChange={this.handleCompanyChange}
                                        className="form-control"
                                      >
                                        <option value="-1">select Company</option>
                                        {this.state.companies.map(item => (
                                          <option
                                            key={item.Id}
                                            value={item.Id}
                                            selected={
                                              this.state.companyId &&
                                              item.Id === this.state.companyId
                                            }
                                          >
                                            {item.Name}
                                          </option>
                                        ))}
                                      </select>
                                    </Col>
                                  </Row>
                                  <hr
                                    style={{ width: "80vw", height: "2px" }}
                                  />
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Company Name
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="Name"
                                        label=""
                                        value={
                                          this.state.formData.Name === null
                                            ? ""
                                            : this.state.formData.Name
                                        }
                                        placeholder="Enter Company Name"
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="address"
                                        className="col-form-label"
                                      >
                                        Company Address
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="address"
                                        label=""
                                        value={
                                          this.state.formData.address === null
                                            ? ""
                                            : this.state.formData.address
                                        }
                                        placeholder="Enter Company Address"
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="state"
                                        className="col-form-label"
                                      >
                                        State
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="state"
                                        label=""
                                        value={
                                          this.state.formData.state === null
                                            ? ""
                                            : this.state.formData.state
                                        }
                                        placeholder="Enter state"
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="City"
                                        className="col-form-label"
                                      >
                                        City
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="City"
                                        label=""
                                        value={
                                          this.state.formData.City === null
                                            ? ""
                                            : this.state.formData.City
                                        }
                                        placeholder="Enter City"
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="GST_no"
                                        className="col-form-label"
                                      >
                                        GST No.
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="GST_no"
                                        label=""
                                        value={
                                          this.state.formData.GST_no === null
                                            ? ""
                                            : this.state.formData.GST_no
                                        }
                                        placeholder="Enter Company GSTNo."
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="PO_no"
                                        className="col-form-label"
                                      >
                                        PO No.
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="PO_no"
                                        label=""
                                        value={
                                          this.state.formData.PO_no === null
                                            ? ""
                                            : this.state.formData.PO_no
                                        }
                                        placeholder="Enter Company PO No."
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="Email"
                                        className="col-form-label"
                                      >
                                        Email
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="Email"
                                        label=""
                                        value={
                                          this.state.formData.Email === null
                                            ? ""
                                            : this.state.formData.Email
                                        }
                                        placeholder="Enter email"
                                        onChange={this.handleFormChange}
                                        type="email"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="Phone"
                                        className="col-form-label"
                                      >
                                        Phone No.
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <input
                                        name="Phone"
                                        label=""
                                        value={
                                          this.state.formData.Phone === null
                                            ? ""
                                            : this.state.formData.Phone
                                        }
                                        placeholder="Enter Phone No."
                                        onChange={this.handleFormChange}
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  {this.state.id > 0 && (
                                    <Row>
                                      <Col sm="6">
                                        <input
                                          type="checkbox"
                                          id="isEnd"
                                          name="IsEnd"
                                          checked={this.state.formData.IsEnd}
                                          onChange={this.handleCheckboxChange}
                                        />
                                        <label htmlFor="isEnd">Is End</label>
                                      </Col>
                                    </Row>
                                  )}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row></Row>

                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnSave_onClick}
                            >
                              Save
                            </Button>

                            <Button
                              type="button"
                              color="secondary"
                              className="waves-effect"
                              onClick={this.btnCancel_onClick}
                            >
                              Cancel
                            </Button>

                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Customer Saved Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                You clicked the button!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_CompanyMaster);
