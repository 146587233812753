import React, { Component, createRef } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import moment from "moment";
import { compose } from "recompose";

import {
  Button,
  Card,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
import * as serviceWorker from "../serviceWorker";
//Import Breadcrumb

import { API_WEB_URLS } from "constants/constAPI";
import { Fn_FillListData, Fn_AddEditData, Fn_GetReport } from "store/functions";
import { applyMiddleware } from "redux";
import { container } from "store/Containers/cntCommon";
import Breadcrumbs from "components/Common/Breadcrumb";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "Firebase";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoomId: 1,

      curMessage: "",
      test: [],
      ticketdetails: [
        {
          Id: 0,
          UserName: "",
          F_Status: 0,
        },
      ],
    };
    this.messageBox = null;
    this.obj = this;
    this.addMessage = this.addMessage.bind(this);
    this.refresh = this.refresh.bind(this);
    this.closeticket = this.closeticket.bind(this);
    this.userChatOpen = this.userChatOpen.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.isValidImageType = this.isValidImageType.bind(this);

    //ref
    this.fileInputRef = createRef();

    this.messageRef = createRef();
  }
  async requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging, {
        vapidKey:
          "BAh06ycBkg9wpBiIoUy2Q7tbqSFP07BAlT62YCefaKs5Q3Bj3NbLjtiHbTIwltpTV5YRNrCNCXNrqZR_4dIB-mI",
      });
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }

  componentWillUnmount() {
    // Cleanup event listener on unmount
    window.removeEventListener(
      "clearLocalStorage",
      this.handleClearLocalStorage
    );
  }
  componentDidUpdate() {
    if (prevState.test != this.state.test) {
      console.log("this is called");
      this.messageRef.current?.scrollIntoView();
    }
  }
  isValidImageType = fileName => {
    const validExtensions = ["jpeg", "png", "jpg"];
    const extension = fileName.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };
  componentDidMount() {
    this.requestPermission();
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    Fn_FillListData(this.obj, "Members", "Chat/ChatMembers/" + obj.uid);

    onMessage(messaging, payload => {
      if (payload.from != null) {
        alert("Got a message");
        Fn_FillListData(
          this.obj,
          "test",
          "Chat/Master/GetMessages/" +
            this.state.chatOpen +
            "/" +
            this.state.aid
        );
      }
    });
    serviceWorker.register();
    this.handleClearLocalStorage = async () => {
      alert("Got a message");
      try {
        //idhar api call
        Fn_FillListData(this.obj, "Members", "Chat/ChatMembers/" + obj.uid);
        Fn_FillListData(
          this.obj,
          "test",
          "Chat/Master/GetMessages/" +
            this.state.chatOpen +
            "/" +
            this.state.aid
        );
      } catch (error) {
        console.error("Error calling API or clearing local storage:", error);
      }
    };
    console.log("in dashboard : before");
    window.addEventListener("clearLocalStorage", this.handleClearLocalStorage);
    console.log("in dashboard : After");
  }

  userChatOpen(Id, Name) {
    this.setState(
      {
        chatOpen: Id,
        ticketdetails: [],
        test: [],
      },
      () => {
        Fn_FillListData(
          this.obj,
          "ticketdetails",
          "Chat/Master/UserChat/Id/" + Id
        );
        Fn_FillListData(
          this.obj,
          "test",
          "Chat/Master/GetMessages/" + Id + "/" + this.state.aid
        );
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {}

  refresh() {
    Fn_FillListData(
      this.obj,
      "test",
      API_WEB_URLS.MASTER + "/0/token/GetMessages/Id/" + this.state.id
    );
  }

  addMessage() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let vformData = new FormData();
    //Information
    if (this.state.test[0].id == 0) {
      vformData.append("F_Receiver", this.state.ticketdetails[0].Id);
      vformData.append("F_Sender", this.state.aid);

      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        "Chat/InsertChat",
        "#",
        true,
        "chat"
      );
    } else {
      vformData.append("F_ChatList", this.state.test[0].id);
      vformData.append("F_Sender", this.state.aid);
      vformData.append("F_Receiver", this.state.chatOpen);
      vformData.append("Message", this.state.curMessage);
      vformData.append("ImageFile", this.state.file);

      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        "Chat/InsertChatMessage",
        "#",
        true,
        "msg"
      );
    }
  }

  handleFileChange = event => {
    const files = event.target.files[0];
    this.setState({
      file: files,
    });
  };
  closeticket() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let vformData = new FormData();

    //Information
    vformData.append("F_TicketMaster", this.state.ticketdetails[0].Id);
    vformData.append("F_UserMaster", obj.uid);

    Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "CloseTicket/0/token",
      "/tickets",
      true,
      "",
      this.state.ticketdetails[0].Id
    );
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
      this.setState({
        curMessage: "",
      });
    }
  };

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Skote" breadcrumbItem="Chat" />

            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  <div
                    className="chat-leftsidebar me-lg-4"
                    style={{ borderRight: "1px solid" }}
                  >
                    <div className="">
                      <div className="py-4 border-bottom">
                        <div className="d-flex">
                          <div>
                            <h5 className="font-size-14 mb-3">Recent</h5>
                            <ul
                              className="list-unstyled chat-list"
                              id="recent-list"
                            >
                              <PerfectScrollbar style={{ height: "410px" }}>
                                {this.state.Members &&
                                  this.state.Members.map(chat => (
                                    <li
                                      key={chat.id + chat.status}
                                      //   className={
                                      //     currentRoomId === chat.roomId
                                      //       ? "active"
                                      //       : ""
                                      //   }
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          this.userChatOpen(
                                            chat.Id,
                                            chat.Name
                                            // chat.roomId
                                          );
                                        }}
                                      >
                                        <div className="d-flex">
                                          <div className="align-self-center me-3">
                                            {/* <i
                                              className={
                                                chat.status === "online"
                                                  ? "mdi mdi-circle text-success font-size-10"
                                                  : chat.status ===
                                                    "intermediate"
                                                    ? "mdi mdi-circle text-warning font-size-10"
                                                    : "mdi mdi-circle font-size-10"
                                              }
                                            /> */}
                                          </div>

                                          <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              {chat.Name}
                                            </h5>

                                            <p
                                              className="text-truncate mb-0"
                                              style={{
                                                fontWeight:
                                                  chat.IsSeen == 2
                                                    ? "normal"
                                                    : "bold",
                                              }}
                                            >
                                              {chat.IsSeen != 2 && chat.Message}
                                            </p>
                                          </div>
                                          {/* <div className="font-size-11">
                                            {chat.time}
                                          </div> */}
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 user-chat">
                    <Card>
                      <div className="p-4 border-bottom ">
                        <Row>
                          <Col md="4" xs="9">
                            <h5 className="font-size-15 mb-1">
                              {this.state.ticketdetails.length > 0 &&
                                this.state.ticketdetails[0].Name}
                            </h5>

                            <p className="text-muted mb-0">
                              <i
                                className={
                                  // this.state.Chat_Box_User_Status === "online"
                                  //?
                                  "mdi mdi-circle text-success align-middle me-1"
                                  // : this.state.Chat_Box_User_Status ===
                                  //   "intermediate"
                                  //   ? "mdi mdi-circle text-warning align-middle me-1"
                                  //   : "mdi mdi-circle align-middle me-1"
                                }
                              />
                              online
                            </p>
                          </Col>
                          {/* <Col md="8" xs="3">
                            <ul className="list-inline user-chat-nav text-end mb-0">
                            {this.state.ticketdetails[0].F_Status == 1 || this.state.ticketdetails[0].F_Status == 2 ?
                            <>
                              <li className="list-inline-item  d-none d-sm-inline-block">
                              <Button
                          type="button"
                          color="primary"
                          onClick={this.closeticket}
                          className="mr-1 waves-effect waves-light"
                        >
                          Close Issue
                        </Button>
                              </li>{" "}
                              <li className="list-inline-item  d-none d-sm-inline-block">
                              <Button
                          type="button"
                          color="primary"
                          onClick={this.refresh}
                          className="mr-1 waves-effect waves-light"
                        >
                          Refresh
                        </Button>
                              </li>{" "}
                              </>

                              : null}
                              
                            </ul>
                          </Col> */}
                        </Row>
                      </div>

                      <div>
                        <div className="chat-conversation p-3">
                          <ul className="list-unstyled mb-0">
                            <div
                              style={{
                                height: "486px",
                                minHeight: "486px",
                                overflowY: "auto",
                                flexDirection: "column",
                              }}
                            >
                              <li>
                                <div className="chat-day-title">
                                  <span className="title">Today</span>
                                </div>
                              </li>
                              {this.state.test.length > 0 &&
                                this.state.test[0].id != 0 &&
                                this.state.test.map(message => (
                                  <li
                                    key={"test_k2" + message.id}
                                    className={
                                      message.sender == this.state.aid
                                        ? "right"
                                        : ""
                                    }
                                  >
                                    <div className="conversation-list">
                                      <div className="ctext-wrap">
                                        {/* <div className="conversation-name">
    {message.sender}
  </div> */}

                                        {message.message ? (
                                          <p>{message.message}</p>
                                        ) : (
                                          message.IMG && (
                                            <a
                                              href={`https://apitaskmanager.shinewellsofttech.co.in/SaveImageF/${message.IMG}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{
                                                textDecoration: "none",
                                                color: "blue",
                                              }}
                                            >
                                              <img
                                                style={{ width: "40vw" }}
                                                src={`https://apitaskmanager.shinewellsofttech.co.in/SaveImageF/${message.IMG}`}
                                                alt="File"
                                              />
                                            </a>
                                          )
                                        )}
                                        <p className="chat-time mb-0">
                                          <i className="bx bx-time-five align-middle me-1" />{" "}
                                          {moment(message.createdAt).format(
                                            "hh:mm"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              <div ref={this.messageRef}></div>
                            </div>
                          </ul>
                        </div>

                        {this.state.ticketdetails.length > 0 &&
                        (this.state.ticketdetails[0].F_Status == 1 ||
                          this.state.ticketdetails[0].F_Status == 2) ? (
                          <div className="p-3 chat-input-section">
                            <Row>
                              <Col>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    value={this.state.curMessage}
                                    //  onKeyPress={this.onKeyPress}
                                    onChange={e => {
                                      this.setState({
                                        curMessage: e.target.value,
                                      });
                                    }}
                                    className="form-control chat-input"
                                    placeholder="Enter Message..."
                                  />
                                  <div className="chat-input-links">
                                    <ul className="list-inline mb-0">
                                      {/* <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-emoticon-happy-outline"
                                          id="Emojitooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Emojitooltip"
                                        >
                                          Emojis
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-file-image-outline"
                                          id="Imagetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Imagetooltip"
                                        >
                                          Images
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "} */}
                                      <li className="list-inline-item">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            id="file-input"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={this.handleFileChange}
                                          />
                                          <label
                                            htmlFor="file-input"
                                            style={{
                                              display: "inline-block",
                                              cursor: "pointer",
                                              padding: "10px 20px",
                                              color: "white",
                                              border: "none",
                                              borderRadius: "5px",
                                              fontSize: "16px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <span
                                              role="img"
                                              aria-label="upload-icon"
                                              style={{ marginRight: "8px" }}
                                            >
                                              📁
                                              {this.state.file && (
                                                <span
                                                  style={{
                                                    fontSize: "10px",
                                                    color: "black",
                                                  }}
                                                >
                                                  {this.state.file.name}
                                                </span>
                                              )}
                                            </span>{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={this.addMessage}
                                  className="btn-rounded chat-send w-md"
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    Send
                                  </span>{" "}
                                  <i className="mdi mdi-send"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(Chat);
