import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
} from "../../store/functions";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class pageAddEdit_Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {
        subject: null,
      },
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      SenderName: [
        {
          Name: "",
        },
      ],
      ReceiverName: [
        {
          Name: "",
        },
      ],
      prov: false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
    };

    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Request For Support";
    this.breadCrumbTitle = "Request For Support";
    this.breadCrumbItem = this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.InsertSupportMail + "/0/token";
    this.pushFormName = "/dashboard";

    //Event Binding
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem);
    //Filling DropDowns
    Fn_FillListData(
      this.obj,
      "subjects",
      API_WEB_URLS.MASTER + "/0/token/subjectmaster/Id/0"
    );

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ MembershipNo: obj.name, Name: obj.username, uid: obj.uid });

    this.GetSenderName(obj.name);

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    let vformData = new FormData();

    vformData.append("F_MemberMaster", this.state.uid);
    vformData.append("F_Subject", formData.subject);
    vformData.append("Message", this.state.message);

    console.log(formData.subject);
    console.log(this.state.message);
   
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true
      );
   
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  handleEditorChange = (editorState) => {
   
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();
  
    this.setState({ message: text });
    
    
  };
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  GetSenderName = event => {
    var value = event;

    Fn_FillListData(
      this.obj,
      "SenderName",
      API_WEB_URLS.MASTER +
        "/0/token/GetBalance/1/Mem/Balance?ListFor=" +
        value +
        "&F_AccountType=11"
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Request For Support</h4> */}
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="12">
                              <Card>
                                <CardBody>
                                  <h4 className="card-title mb-4">
                                    Information
                                  </h4>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        GIN:
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="GIN"
                                        onChange={this.GetSenderName}
                                        value={this.state.MembershipNo}
                                        disabled
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Name
                                      </label>
                                    </Col>
                                    <Col lg="6">
                                      <AvField
                                        name="Name"
                                        value={this.state.SenderName[0].Name}
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        subject
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="subject"
                                        label=""
                                        value={this.state.subjects}
                                        errorMessage="Select  CourseType"
                                        validate={{ required: { value: true } }}
                                        type="select"
                                        className="form-select"
                                      >
                                        <option
                                          value={-1}
                                          defaultValue
                                          label={"Select"}
                                        />
                                        {this.state.subjects
                                          ? this.state.subjects.map(
                                              (option, key) => (
                                                <option
                                                  key={key}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              )
                                            )
                                          : null}
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="4">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Message
                                      </label>
                                    </Col>
                                    <Col lg="10">
                                      <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        placeholder="Place Your Content Here..."
                                        onEditorStateChange={
                                          this.handleEditorChange
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <div className="d-flex flex-wrap gap-2">
                                    <Button type="submit" color="primary">
                                      Send
                                    </Button>{" "}
                                    <Button type="reset" color="secondary">
                                      Cancel
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Support);
