import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTest2 : false,
    }
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    return (
      <React.Fragment>
        <div className="topnav ">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light  navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ isDashboard: !this.state.isDashboard });
                      }}
                      to="/dashboard"
                    >
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                    </div>
                  </li>
                  <Link to="/Chat" className="nav-link">
                          {this.props.t("Chat")}
                        </Link>
                  {/* testing purpose */}
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ isTest: !this.state.isTest });
                      }}
                      to="/#"
                    >
                      {this.props.t("User Master")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isTest,
                      })}
                    >
                      
                    </div>
                  </li> */}
                  {obj.Usertype === "admin" && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ isTest2: !this.state.isTest2 });
                        }}
                        to="/#"
                      >
                        {this.props.t("New AMC")} {this.props.menuOpen}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.isTest2,
                        })}
                      >
                        <Link to="/companymaster" className="dropdown-item">
                          {this.props.t("Company Master")}
                        </Link>
                        <Link to="/amcmaster" className="dropdown-item">
                          {this.props.t("AMC Master")}
                        </Link>
                        <Link to="/createbill" className="dropdown-item">
                          {this.props.t("Create Bill")}
                        </Link>
                        <Link to="/branchmaster" className="dropdown-item">
                          {this.props.t("Branch Master")}
                        </Link>
                        <Link to="/amcmasterlist" className="dropdown-item">
                          {this.props.t("AMC Master List")}
                        </Link>
                      
                        <Link to="/paymentreport" className="dropdown-item">
                          {this.props.t("Bill Payments Report")}
                        </Link>
                        <Link to="/duereport" className="dropdown-item">
                          {this.props.t("Due Amount Report")}
                        </Link>
                        <Link to="/addproduct" className="dropdown-item">
                          {this.props.t("Add product")}
                        </Link>
                        <Link to="/billpayment" className="dropdown-item">
                          {this.props.t("Bill payment")}
                        </Link>
                        <Link to="/usercreation" className="dropdown-item">
                        {this.props.t("User Registration")}
                      </Link>
                      </div>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {this.props.t("Reports")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/employeereport" className="dropdown-item">
                        {this.props.t("Employee Report")}
                      </Link>
                      <Link to="/monthlyreport" className="dropdown-item">
                        {this.props.t("Monthly Report")}
                      </Link>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
