import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "Firebase";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";

export const DateString = (date) =>{
  date  =  new Date(date);
  let yyyy   =  date.getFullYear();
  let mm =  date.getMonth() + 1;
  let dd  =  date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  let formattedToday = dd + '/' + mm + '/' + yyyy;
  return formattedToday;
}

class FirebaseTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1 : '',
      BrowseImage2 : '',
      UserImage : '',
      Signature : '',
      //dropdowns
      gender: [],
      state_dd: [],
      Name : "",
      aid : 0,
      fo3 : false,
      success_msg : false,
      Amount : 0,
      TimePeriod : 0,
      NoOfMembers : 0,
      InstallmentAmount : 0,
      StartDate : new Date().toString()
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "City";
    this.breadCrumbTitle = "City";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/CityEdit";
    this.API_URL_SAVE = API_WEB_URLS.CityMaster + "/0/token";
    this.pushFormName = "/masters-citymaster";
    this.rtPage_City = "/masters-citymaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno  =  this.syno.bind(this);
    this.reset  =  this.reset.bind(this);
   // this.onStateChange= this.onStateChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () =>
      console.log({ name, value, state: this.state })
    );
  }
  async  requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging, {
        vapidKey:
          "BAh06ycBkg9wpBiIoUy2Q7tbqSFP07BAlT62YCefaKs5Q3Bj3NbLjtiHbTIwltpTV5YRNrCNCXNrqZR_4dIB-mI",
      });
      console.log("Token Gen", token);
     
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
    
    
  }
  componentDidMount() {
    
    this.requestPermission();
    
    onMessage(messaging,(payload)=>{
      console.log('payload',payload);
      
      if(payload.from!=null){
        
        alert('mission success')
      }
    })
    


//     const obj = JSON.parse(localStorage.getItem("authUser"));
//     this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
//   //  var F_CompanyMaster  =  localStorage.getItem("F_CompanyMaster");
//   Fn_FillListData(this.obj, "state_dd", API_WEB_URLS.MASTER + "/0/token/StateMaster/Id/0");

//     const { id } = this.props.match.params;

//     if (id) {
//       this.setState({ id: id });
//       this.breadCrumbItem = "Edit " + this.formTitle;
//       Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
//     } else {
//       this.setState({ id: 0 });
//     }
    
  }

reset () {
  this.props.history.push(`/masters-citymaster`, {});
}
// onStateChange(event){
//   var F_StateMaster = event.target.value;
//   Fn_FillListData(this.obj, "state_dd", API_WEB_URLS.MASTER + "/0/token/StateMaster/Id/"+F_StateMaster);
// }
  btnSave_onClick(event, formData) {

     let vformData = new FormData();
     //Information
     vformData.append("Name", formData.Name);
     vformData.append("F_StateMaster", 1);

     if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true , "Id");
    }
    else {
     vformData.append("Id", this.state.id);
     Fn_AddEditData(this.obj, { arguList: { id: this.state.id, formData: vformData } }, this.API_URL_SAVE, "#", true , "Id");
  }
}
  
  syno () {
        this.setState({
          success_msg : false
        });
        //event.preventDefault();
        this.props.history.push(`${this.rtPage_City}`, {});
      };

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps
        });
      }
    }
  }

  render() {
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          No
        </div>
      )
    }


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                      </div>
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>
                                      {/* <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">State</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="F_StateMaster"  label="" value={this.state.formData.F_StateMaster === null ? '-1'   : this.state.formData.F_StateMaster}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.state_dd 
                                              ? this.state.state_dd .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Name" className="col-form-label">Name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Name" label="" value={this.state.formData.Name === null ? ''   : this.state.formData.Name} placeholder="Enter  Name"  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                  </Col>
                                </Row>
                                <Row>
                                </Row>

                            </TabPane>
                          </TabContent>

                          <div>
                          <Button type="submit" color="primary" className="mr-1 waves-effect waves-light">
                          Save
                        </Button>

                        <Button type="button" color="primary" style={{"marginLeft" : "10px"}} 
                            className="mr-1 waves-effect waves-light" onClick={this.reset}>
                          Cancel
                        </Button>

                        {this.state.success_msg ? (
                        <SweetAlert title="City Saved Successfully!" success confirmBtnBsStyle="success"
                        onConfirm={this.syno}>
                        You clicked the button!
                        </SweetAlert>
                        ) : null}
                          </div>

                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(FirebaseTest);
