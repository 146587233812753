import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageAddEdit_AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {},
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Add product";

    //Event Binding
   
    this.handleFormChange = this.handleFormChange.bind(this);
    
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
    
  }


  handleFormChange(event) {
    const { Name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [Name]: value,
      },
    }));
  }

  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    vformData.append("Name", formData.Name);
     Fn_AddEditData(
       this.obj,
       { arguList: { id: 0, formData: vformData } },
       "Product/InsertProduct",
       "#",
       true,
       "Id"
     );

 
  }

  render() {
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Name
                                      </label>
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                      <AvField
                                        name="Name"
                                        label=""
                                        value={
                                          this.state.formData.Name == undefined
                                            ? " "
                                            : this.state.formData.Name
                                        }
                                        placeholder="Write product Name...ex(Sahakkar)"
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageAddEdit_AddProduct);
