import { AvForm, AvInput } from "availity-reactstrap-validation";
import React from "react";
import { Row, Col, Button } from "reactstrap";

function FileTable({
  dataArray,
  handleFileChange,
  handleAddRow,
  handleDeleteRow,
  handleDescriptionChange,
}) {
  return (
    <table style={{ marginTop: "20px" }}>
      <thead>
        <tr>
          <th>File</th>
          <th>Description</th>
          <th>Action</th>
          <th>
            <Button color="danger" onClick={() => handleDeleteRow()}>
              {"-"}
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataArray.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {row[`File${rowIndex + 1}`] && (
                  <>
                    {typeof row[`File${rowIndex + 1}`] === "string" ? (
                      row[`File${rowIndex + 1}`].substring(0, 7)
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <input
                  id={`file-input-${rowIndex + 1}`}
                  name={`File${rowIndex + 1}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={e => handleFileChange(e, rowIndex)}
                  className="form-control"
                />
                <label
                  htmlFor={`file-input-${rowIndex + 1}`}
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    padding: "10px 20px",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    textAlign: "center",
                    backgroundColor: "blue", // Add your desired background color here
                  }}
                >
                  <span
                    role="img"
                    aria-label="upload-icon"
                    style={{ marginRight: "8px" }}
                  >
                    📁{" "}
                    {row[`File${rowIndex + 1}`] && (
                      <span style={{ fontSize: "10px", color: "black" }}>
                        {typeof row[`File${rowIndex + 1}`] === "object" &&
                          row[`File${rowIndex + 1}`].name}
                      </span>
                    )}
                  </span>{" "}
                </label>
              </div>
            </td>
            <td>
              <textarea
                style={{ width: "230px" }}
                type="text"
                name={`Description${rowIndex + 1}`}
                value={row[`Description${rowIndex + 1}`]}
                onChange={e => handleDescriptionChange(e, rowIndex)}
                className="form-control"
              />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-success ml-2"
                onClick={() => handleAddRow(rowIndex)}
              >
                +
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default FileTable;
